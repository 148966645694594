<template>
	<div>		
		<div>
			<div
				v-for="(item, index) in tabs"
				:key="'dyn-tab-' + index"				
				@click="selectTab(item.key)"
				:active="item.key == tabSelected"
			>														
				<SearchBooking	
					:indiceTab="index"		
					:clave="item.key"
					:searchBookingData="dataSearchProp(item.key)"
					:dataDingusBookings="dataBookingsProp(item.key)"
					@search-bookings="searchBookings"
				/>	
				<Rooms
					:currentPage="filters.currentPage"
					:clave="item.key"
					:dataDingusBookings="dataBookingsProp(item.key)"
					@set-page-to-number="setCurrentPageNumber"
					
				/>				
			</div>						
		</div>
	</div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import Rooms from '@/components/shop/booking/Rooms'
import SearchBooking from '@/components/shop/booking/SearchBooking'
import { searchBookingData,  } from '@/data/data'
import { stringAleatorio } from '@/helpers/helpers'
import Ripple from 'vue-ripple-directive'

export default {
	components: {		
		SearchBooking,
		Rooms,		
	},
	async mounted(){		
		await this.fetchHotels()
		await this.fetchCurrencies(this.cart.cliente)
		await this.fetchPromoCodes()
		// await this.fetchTerms(4)   
	},
	directives: {
		Ripple,
	},
	data(){
		return {		
			filters:{
				currentPage: 1,
				perPage: 3,
			},
		}
	},
	computed:{
		...mapState('bookings',['tabs','tabSelected']),
		...mapState('start',['hotels','promoCodes']),
		...mapState('shop',['cart']),               
		...mapState('auth',['user']),               

	},
	methods: {
		...mapActions('start',['fetchHotels','fetchTerms','fetchPromoCodes','fetchCurrencies']), 
		...mapActions('bookings',['fetchBookings','sendBookingDingus']),
		...mapMutations('bookings',['addTab','deleteTab','setSelectedTab','setDataSearchAuthorization']),	
		async searchBookings(data){
			let dataInfo = JSON.parse(JSON.stringify(data))		
			const hotelData = this.hotels.find( hotel => hotel.id === dataInfo.hotel)	

			const promoCodeSelect = dataInfo.promoCode != null ? dataInfo.promoCode.name : ''
			const promoCodeInput = dataInfo.promoCodeManual != "" ? dataInfo.promoCodeManual : ''
			const promoCodeToUse = promoCodeSelect == "" ? promoCodeInput : promoCodeSelect			
			const dingusVendorCode = this.user?.canalventa?.dingusvendorcode || 'CONTACTCENTER'
			
			const payload = {
				key: dataInfo.key,
				tarifa: '',
				rateplanid: "",
				isManualRate: false,
				manualRate: "",
				rate: "",
				vendor: dingusVendorCode,			
				idioma: (dataInfo.language ==  null) ? 'en' : dataInfo.language,
				roomCode: (dataInfo.room == null) ? '' : dataInfo.room.Code,
				hotel: (dataInfo.hotel == "") ? '' : hotelData.dingusCode,
				dateIn: dataInfo.dateIn,
				dateOut: dataInfo.dateOut,
				adults: dataInfo.adults,
				children: dataInfo.numberChildren,
				ninos: dataInfo.ninos,
				promotionCode: promoCodeToUse || '',				
			}
			// console.log(payload)
			await this.fetchBookings(payload)
			this.setCurrentPageInit()
		},
		selectTab(key){
			this.setSelectedTab(key)		
		},
		deleteItemTab(x) {				
			// borro tab y objeto search del array dataSearch
			this.deleteTab(x) 
			//selecciona ultimo tab del array de tabs
			this.setSelectedTab(this.tabs[this.tabs.length - 1].key)		
		},
		newTab() {
			const key = stringAleatorio()																
			const payload = { key, dataSearch: searchBookingData, rooms:[], dingusBookings:[]}
			this.addTab( payload )			
		},
		dataSearchProp( key ){
			let item = this.tabs.find( item => item.key === key )
			return item.dataSearch || {}
		},
		dataBookingsProp( key ){
			let item = this.tabs.find( item => item.key === key )
			return item.dingusBookings || []
		},	
		setCurrentPageInit(){
			this.filters.currentPage = 1
		},
		setCurrentPageNumber( pageNumber ){
			this.filters.currentPage = pageNumber
		}
	},
}

</script>