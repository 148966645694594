import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import router from './router'
import store from './store'
import App from './App.vue'
// 3rd party plugins
require('./plugins')
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')
Vue.config.productionTip = false


new Vue({
    router,
    store,  
    render: h => h(App),
}).$mount('#app')


