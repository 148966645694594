import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import main from './routes/main'
import store from '../store/index' //para acceder a state para isLogged
import { validUserLogin } from '../helpers/helpers'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'sales' } },        
    ...main,    
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


router.beforeEach((to, from, next)=> {
  const requiresAuth = to.matched.some( record => record.meta.requiresAuth)
  const isAffiliate = to.matched.some( record => record.meta.isAffiliate)  
  const userValidLogin = validUserLogin() // usuario conectado, con canal de venta y un punto de venta
 
  if( !requiresAuth && userValidLogin && to.path === '/login'){ //if user isLogged, se manda en sales
    next({name: 'sales'})
  } 
   
  //para ver si el usuario conectado verifico si este es afiliado
  if( isAffiliate && userValidLogin && to.path === '/operation'){
    const group = store.state.auth.user.group || ''
    if(group === 'Affiliate'){
      next({name: 'sales'})
    }    
  }
  
  if( requiresAuth && !userValidLogin) {
    next({name: 'start'})
  }else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
