<template>

  <section class="misc-wrapper"> 
 <HeaderPayment/>

    <!-- tabs navegacion -->
 
       
   <b-container class="mt-2 mb-5 flex-fill container  col-12 col-md-8">


      <b-card v-if="productDetail!=null">
  
        <b-card-body >          
          <b-row class="my-2">
            <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img v-if="payloadPeticion.endPoint!='termsWebsites'"
                  :src="imgUrl + productDetail.coverImage"                
                  :alt="`Photo ${productDetail.name}`"
                  class="product-img"
                  fluid
                />
                <b-img  v-if="payloadPeticion.endPoint=='termsWebsites'"
                  :src="productDetail.coverImage"                
                  :alt="`Photo ${productDetail.name}`"
                  class="product-img"
                  fluid
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
      
           
              <h3>{{ productDetail.name }}</h3>

              <!-- Product Brand -->
              <b-card-text class="item-company mb-0">
                <span>Categoría:</span>
                <b-link class="company-name">
                  {{ productDetail.categoryName }}
                </b-link>
              </b-card-text>

  
    

              <!-- Avability -->
              <!-- <b-card-text>Available - <span class="text-success">In stock</span></b-card-text> -->
              <!-- Product list details -->
       
<br>
              <!-- Product Description -->
              <b-card-text class="text-justify" v-html="productDetail.descriptionTerms"></b-card-text>

         
       
       
            </b-col>

<b-col cols="12"
              md="12"
              class="d-flex align-items-left justify-content-left mb-2 mb-md-0">

           <div class="ecommerce-details-terms d-flex flex-wrap mt-1">
                <div  v-html=" productDetail.terms" class="mr-1">                
                </div> 
              </div>
              </b-col>            
          </b-row>
          <!-- <pre>{{productDetail}}</pre> -->
          
        </b-card-body>
      </b-card>
        <div v-else>


          <b-card >
  
        <b-card-body >          
          <b-row class="my-2">
      
            <b-col
              cols="12"
              
            >
      
           
              <h3>Términos y condiciones</h3>

              <!-- Product Brand -->
              <b-card-text class="item-company mb-0">
<p>Tel Mar Holidays: <a class="w400 color-dark" href="tel:+529848772755"> 984 877 27 55 EXT: 62107</a></p>
<p class="size-12 color-red line-height">Si usted adquiri&oacute; alg&uacute;n Tour o Experiencia Vacacional con The Fives Hotels &amp; Residences, favor de intercambiar esta confirmaci&oacute;n por el ticket valido de la actividad con nuestra agencia autorizada Mar Holidays</p>
<p>Agradecemos su compra, para poder disfrutar al m&aacute;ximo de su actividad, por favor contacte a su representante de Marholidays al arribo de 9 am a 17:00 pm de lunes a domingo, as&iacute; podremos entregarle sus boletos y brindarle toda la informaci&oacute;n que necesite para su actividad.</p>
<br>
 <h3>Terms and conditions</h3>
<p class="color-red line-height">If you purchased a Tour or Vacation Experience at The Fives Hotels &amp; Residences, please exchange this confirmation for the valid ticket with our authorized agency Mar Holidays</p>
<p>We appreciate your purchase, in order to enjoy your activity at maximun, please contact your Marholidays representative from 9:00 to 17:00 from Monday to Sunday, so we will be able to provide your tickets and give you all the information you need for your activity.</p>
<p class=" color-red line-height">Estimado Cliente: Le recordamos que es indispensable contar y presentar f&iacute;sicamente la tarjeta de cr&eacute;dito utilizada para cualquier pago o garant&iacute;a relacionada a reservaciones al momento de su registro en el resort. En caso de no contar con presencia f&iacute;sica de la misma tarjeta, ser&aacute; necesario presentar una nueva forma de pago y tramitar reembolso a la tarjeta utilizada.</p>
<p class=" color-red line-height">Dear Guest: Please be advised that you must physically bring any credit card used to make payments or guarantees for this reservation and present it to the Front Desk at the time of check in. In the event the same card is not presented at check in, you will need to provide an alternate form of payment in order to check in and request a refund of the charge on the previous card.</p>
              </b-card-text>

       
            </b-col>

            
          </b-row>
     
          
        </b-card-body>
      </b-card>

                     

       </div>
      
   </b-container  >
      <FooterPayment> </FooterPayment>
  </section>  
</template>


<script>

import { mapState, mapActions, mapMutations } from 'vuex'
import FooterPayment from '@/components/shared/footer/FooterPayment'
import HeaderPayment from '@/components/shared/navigations/HeaderPayment'
require("../../assets/css/success.css")
export default {
  
  async mounted(){
    if(this.product){
     await this.makePayloadFromRouteParams( this.product,this.type ,this.lang  )  
    } 
    if( this.payloadPeticion!=null){
      await this.fetchproductswebsites( this.payloadPeticion )  
    }
  },
  components: {


    HeaderPayment,
    FooterPayment
  },
  data(){
    return {
      product: this.$route.query.product,
      type: this.$route.query.type,
      lang: this.$route.query.lang,
      payloadPeticion: null,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
    }

  },
  computed:{
    ...mapState('products',['isloadingProducts','productDetail']),               
            
  },
  methods:{
...mapActions('products',['fetchproductswebsites']),
...mapMutations('products',['unsetDetailProduct']), 
   makePayloadFromRouteParams( product ,type,lang){
     

      var endPoint = type
       if (type=="product"){
         endPoint="productswebsites"

       }
         if (type=="event"){
         endPoint="eventswebsites"

       }
        if (type=="booking"){
         endPoint="termsWebsites"
  

       }

      const idProducto=product
      const language=lang
  if( product!=""&& type!="" && lang!=""){
      this.payloadPeticion = {endPoint, idProducto,language}
  }
      return this.payloadPeticion 
    }
  }
}
</script>

<style>

iframe {
  overflow: hidden;
}

.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
      padding-top: 619px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.alert.alert-danger {
    padding: 9px 0 7px 13px;
}


.container {
  
    padding-bottom: 110px;
}

.misc-wrapper {
    position: relative;
    height: 100%;
    display: contents;
}

</style>

