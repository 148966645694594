<template>
  <div>  
      <b-row>
        <b-col md="6"> 
          <Products :products="cart.items"/>
          <!-- <b-tabs v-model="tabIndex" v-if="cart.items.length" >
            <b-tab title="Carrito" active >
            </b-tab>
            <b-tab title="Cotizar">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugit, alias temporibus doloribus expedita nesciunt cumque id laudantium ad corporis blanditiis consectetur soluta quidem earum vel molestias quisquam sunt dicta est!
            </b-tab>
          </b-tabs> -->
          <!-- <div class="text-center" v-if="cart.items.length">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" @click="setTab(0)"
                :disabled="tabIndex==0"
              > 🛒Carrito
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" @click="setTab(1)"
                :disabled="tabIndex==1" 
              > Cotizador 💸
              </b-button>
            </b-button-group>                
          </div>  -->
          <b-alert show variant="danger" v-if="cart.items.length == 0 && !isloadingProducts" class="mt-2">
            <div class="alert-body text-center"> 😣 <span>There are no products added to the shopping cart</span></div>
          </b-alert> 

          
        </b-col>
        <b-col md="6">
          <CheckoutOptions :products="cart.items" :tabIndex="tabIndex" @set-tab="setTab"/>
        </b-col>
      </b-row>         
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import NabTabs from '@/components/NabTabs'
import Products from '@/components/shop/checkout/Products'
import CheckoutOptions from '@/components/shop/checkout/CheckoutOptions'
import Ripple from 'vue-ripple-directive'

export default {
  async mounted(){  
    if( !this.currencies.length ){
      await this.fetchCurrencies( this.cart.cliente )      
    }
    await this.fetchDestinations()      
    // await this.fetchDepartaments()  
    await this.fetchAirlines()  
    // await this.getTerms() 
    
  },
  directives: {
    Ripple,
  },
  components: {     
    // SFC
    // NabTabs,
    Products,
    CheckoutOptions    
  },  
  data(){
    return {
      tabIndex: 1,
    }
  },
  computed:{
    ...mapState('start',['currencies']),
    ...mapState('products',['isloadingProducts']),    
    ...mapState('shop',['cart']),                              
  }, 
  methods: {            
    ...mapActions('start',['fetchCurrencies','fetchDestinations','fetchDepartaments','fetchAirlines','fetchTerms']), 
    async getTerms(){
			const productsBookings = this.cart.items.filter( prod => prod.modelType === 'booking').length
      if( productsBookings > 0 ){
        await this.fetchTerms( {typeTerms: 1, idLanguage: 2} )
      }
    },
    setTab(valor){
      this.tabIndex = valor     
    },
  }
}
</script>