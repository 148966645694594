<template>
  <b-modal
		:id="'modal-edit-rate'+indice+clave" ok-only no-close-on-backdrop size="md" centered hide-footer
		:title="'Autorizar la tarifa para: '+room.Name"
	>
    <ValidationObserver ref="observerRateModal" v-slot="{ invalid }" >
      <b-form @submit.prevent="sendDataUserAuthorizeRate(userAuthorizeRate, room, room.multimedias[0].multimedia, indice)">
        <div class="col-md-12">
          <b-row>
            <b-col md="12">
              <ValidationProvider rules="required" name="Tarifa">
                <b-form-group label="Usuario" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="text"
                    v-model="userAuthorizeRate.username"
                    :state="errors[0] ? false : (valid ? true : null)"
                    @change="isValidUserAuthorizeRate(userAuthorizeRate)"
                  ></b-form-input>											
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe indicar el usuario' : '' }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12">
              <ValidationProvider rules="required" name="Clave de autorización">
                <b-form-group label="Clave de autorización" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="password"
                    v-model="userAuthorizeRate.code"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input> 
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe indicar la clave de autorización del usuario' : '' }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" v-if="room.detailSelected.allowPriceMatch">
              <ValidationProvider rules="required" name="Comprobante para autorización">
                <b-form-group label="Comprobante para autorización" slot-scope="{ valid, errors }">
                  <b-form-file
                    v-model="file"
                    @input="setImgFile(file)"
                    placeholder="Adjunte una imagen como evidencia..."
                    drop-placeholder="Drop file here..."
                    :state="errors[0] ? false : (valid ? true : null)"
                    browse-text="Buscar imagen"
                />
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe adjuntar la evidencia para la autorización' : '' }} </b-form-invalid-feedback>
                </b-form-group>                
              </ValidationProvider>
              <b-card-text class="my-1">
                  Imagen seleccionada: <strong>{{ file ? file.name : '' }}</strong>
                </b-card-text>
            </b-col>
            <b-col md="12">
              <div class="float-right">
                <b-button 
                  variant="primary" 
                  type="submit"										
                  :ref="'btnShowRate'+indice"
                  :disabled="invalid || userAuthorizeRate.isInvalid">
                  <span> Autorizar y añadir a carrito 🛒</span>
                </b-button> 
                <!-- {{ invalid || userAuthorizeRate.isInvalid}} -->
                <!-- invalid debe ser falso y user isInvalid deber ser falso -->
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>					
    </ValidationObserver>				
	</b-modal>	
</template>

<script>
import { showAlertMessage } from '@/helpers/helpers'

export default {
  props:{
    room: {
      type: Object,
      required: true
    },
    clave:{
			type:String,
			required:true
		},   
    indice:{
			type:Number,
			required:true
		},
    userAuthorizeRate:{
      type:Object,
			required:true      
    },    
  }, 
  data(){
    return {
      file: null
    }
  },
  methods:{        
    isValidUserAuthorizeRate(user){
      this.$emit('is-valid-user-authorize-rate', user)
    },
    setImgFile(file){
      if(file){
        this.file = file
      } else {      
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`
				showAlertMessage( 'No hay imagen', 'InfoIcon', text, 'danger', 4000, 'bottom-right')
      }
    },
    sendDataUserAuthorizeRate(user, room, img, indice){
      // console.log(user)
      const payload = { user, room, img, indice, fileImg: this.file }
      this.$emit('send-data-user-authorize-rate', payload)
    }
  }
}
</script>
