import Vue from 'vue'
import { handlerErrors, toDecimal, toJson, showAlertMessage, consoleMsgFinally } from './../../helpers/helpers'

// const appLoading = document.getElementById('loading-bg')

export async function checkIfClientExists({ commit }, cliente) {
    const client = toJson(cliente)

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/customers/?email=${client.email}`,
        })
        const clientData = response && response.data
        const id = clientData.id || false
        if (id) {
            Vue.swal({
                title: `<p style="font-size:.7em;">This email already exists for: <br> ${clientData.name} ${clientData.lastname} </p>`,
                text: `Do you want to use this information?`,
                icon: 'warning',
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                position: 'top'
            }).then((result) => {
                if (result.value) {
                    commit('shop/setInfoClient', clientData, { root: true })
                    showAlertMessage('Notificación', 'BellIcon', '📧 The data with this email has been updated.', 'success', 6000, 'bottom-right')
                } else {
                    commit('shop/unsetEmailCliente', '', { root: true })
                }
            })
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorCheckEmail', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/checkIfClientExists', 'La petición para verificar si el cliente se ha terminado')
    }
}
export async function fetchDataRoom({ commit }, datos) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: `/sihot/getguest/`,
            data: datos
        })
        const respuesta = response && response.data

        const { status, message } = respuesta
        if (status) {
            const data = toJson(respuesta.data)
                //orden alfabetico por guestname
            data.sort((a, b) => a.guestname > b.guestname ? 1 : -1)
            commit('shop/setDataRoom', data, { root: true })
            commit('shop/unsetLastNameSearch', '', { root: true })
            commit('shop/unsetErrorRoomCharge', '', { root: true })
        } else {
            commit('shop/setDataRoom', [], { root: true })
            showAlertMessage('Respuesta búsqueda', 'BellIcon', `😪 ${message}.`, 'danger', 3000, 'bottom-right')
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorRoomCharge', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/fetchDataRoom', 'La petición para obtener los datos del guest ha terminado')
    }
}

export async function fetchDataOrder({ commit }, order) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/orders/?order=${order}`,
        })
        const respuesta = response && response.data
        const { message } = respuesta
        const data = toJson(respuesta)
        if (data) {
            commit('shop/setDataOrder', data, { root: true })
        } else {
            commit('shop/setDataOrder', [], { root: true })
            showAlertMessage('Respuesta búsqueda', 'BellIcon', `😪 ${message}.`, 'danger', 4000, 'bottom-right')
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorRoomCharge', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/fetchDataOrder', 'La petición para obtener los datos del guest ha terminado')
    }
}

export async function sendInfoClient({ commit, state, getters }, cliente) {

    const client = toJson(cliente)
        // const currency = 1 
    const currency = state.cart.cliente.currency.currencyid
    const canalventa = state.canalventa // canal de venta
    const puntoventa = state.puntoventa // punto de venta
    const room = state.cart.room // habitacion
    const orderCreated = state.orderCreated || false

    //método de pago, referencia, paymentlink del cliente con desestructuración, para usar despues
    const { paymentmethod, paymentreference, paymentlink, idUser, hotel, customertype, customeragent, applyManualRoomCharge, hotelsihotcode, language } = client
    try {
        commit('shop/setLoadingProcess', true, { root: true })
            // appLoading.removeAttribute("style")               
        const response = await Vue.axios({
            method: 'POST',
            url: '/customers/',
            data: client
        })
        const clientData = response && response.data
            //id del cliente(desestructuracion), respuesta desde la api             
        const { id } = clientData
        if (id) commit('shop/unsetErrorInfoOrder', '', { root: true })
            //uso los getters para calculo de los totales
        const grossSale = toDecimal(parseFloat(getters.totalDiscountOnAllProducts) + parseFloat(getters.totalBreakdown))
        const discount = getters.totalDiscountCuenta
        const totalSale = getters.totalBreakdown
            //armo el array details con los datos solicitados
        const detail = []

        state.cart.items.forEach((prod) => {
                const precio = (parseInt(prod.discountApplied) > 0) ? prod.salepriceWithDiscount : prod.originalSalePrice
                const unitPrice = toDecimal(prod.originalSalePrice)
                const qty = prod.qty
                const discount = toDecimal((prod.originalSalePrice - prod.salepriceWithDiscount) * prod.qty)
                const discounttype = prod.typeDiscount || 'noDiscount'
                const total = toDecimal(precio * prod.qty)
                const note = prod.note.substring(0, 254)
                const clientnote = prod.clientnote.substring(0, 254)
                const orderextraFields = prod.orderextraFields ? toJson(prod.orderextraFields) : []
                const modelType = typeModel(prod.id, prod.modelType, prod.detailId)
                const product = modelType.product
                const pack = modelType.package
                const event = modelType.event
                const booking = modelType.booking
                    // console.log(prod)
                    //details id
                const productDetail = modelType.productDetail
                const packageDetail = modelType.packageDetail
                const eventDetail = modelType.eventDetail
                const detailitinerary = setItinerary(prod)
                const orderdetailbeo = toJson(prod.orderdetailbeo)
                    //operationTime
                const operationtime = prod.operationtime
                const serviciosihot = prod.serviciosihot
                const partial = prod.partial
                const firstpay = prod.firstPay
                    //si fecha operacion viene nulo no se pone en el detalle        
                let objetoDetalle = createObjectPayload(prod, unitPrice, qty, discount, discounttype, total, note, clientnote, orderextraFields, product, pack, event, booking, productDetail, packageDetail, eventDetail, detailitinerary, operationtime, orderdetailbeo, serviciosihot, partial, firstpay)
                if (prod.categoryName == 'Reservas') {
                    createObjectBooking(objetoDetalle, prod)
                }
                //push para el detalle
                detail.push(objetoDetalle)
            })
            //objeto con los datos del client y detalles con los valores del cart (payload)            
        const partial = state.cart.partial
        const firstpay = state.cart.firstPay
        const cargomanual = applyManualRoomCharge
        const numreserva = state.cart.numreserva
        
        const { idWelcome:idWelcom, idFarewellMessage:idFired, idAmenity:idAmenities, isQuotation: isQuotes, idTerms: termsId } = state.quotation
        const payload = { grossSale, tax: "0.00", discount, totalSale, customer: id, paymentmethod, currency, paymentreference, paymentlink, saleUser: idUser, hotel, orderStatus: 1, detail, customertype, customeragent, cargomanual, numreserva, hotelsihotcode, canalventa, puntoventa, room, language, partial, firstpay, idWelcom, idFired, idAmenities, isQuotes, termsId }

        // console.log(payload, orderCreated, paymentmethod)     

        if (orderCreated) {
            const order = { order: orderCreated }
            await retryPayment({ commit }, order)
        } else {
            await sendCartItems({ commit }, payload)
        }

    } catch (error) {
        if (paymentmethod == 4) {
            commit('shop/setMakeRoomCharge', true, { root: true })
            commit('shop/setManualRoomChargeCliente', true, { root: true })
        }
        console.log(error)
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/sendInfoClient', 'La petición para enviar los datos del cliente se ha terminado')
    }
}

export async function retryPayment({ commit }, idOrder) {

    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/paymentretry/',
            data: idOrder
        })

        const respuesta = response && response.data
        const { payment, order, invoice } = respuesta

        if (order) { //si hay orden creada seteo el valor            
            commit('shop/setOrderCreated', order, { root: true })
        }

        if ( payment ) {
            const { error, url } = payment
                //sino hay error y url viene, se muta el valor de bankUrl para formulario de pago
            if ( !error && url != '') {
                                                        
                commit('shop/setBankUrl', url, { root: true } )
                const btnPayload = { showRetryPayment: true, showCheckout: false }
                commit('shop/setShowBtnsCheckout', btnPayload, { root: true } ) 
            }
            // console.log(invoice, order)
        } else {
            commit('shop/setEmptyCart', [], { root: true }) // [] cart se vacía, se resetean datos 
            commit('shop/unsetLastNameSearch', '', { root: true })
            commit('shop/unsetDataRoom', '', { root: true })
            commit('bookings/resetToInitialState', null, { root: true })
            Vue.swal.fire(
                'Operación finalizada',
                 `🎉🎉 La orden ha sido completada: ${invoice}. 🎉🎉`,
                 'success'                                 
            )
        }            
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorRetryPayment', errorMsg, { root: true })
    } finally {
        consoleMsgFinally('shop/retryPayment', 'La petición para voler a realizar el pago finalizó')
    }
}

export async function sendCartItems({ commit }, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orders/',
            data: payload
        })
        const respuesta = response && response.data
        const { payment, order, invoice } = respuesta        
        // let paymentSuccess = false
        if (order) { //si hay orden creada seteo el valor            
            commit('shop/setOrderCreated', order, { root: true })
            if ( payment ) {
                const { error, url } = payment
                //sino hay error y url viene, se muta el valor de bankUrl para formulario de pago
                if ( !error && url != '') {
                    commit('shop/setBankUrl', url, { root: true } )
                    const btnPayload = { showRetryPayment: true, showCheckout: false }
                    commit('shop/setShowBtnsCheckout', btnPayload, { root: true } )                    
                }                
            } else {
                commit('shop/setEmptyCart', [], { root: true }) // [] cart se vacía, se resetean datos 
                commit('shop/unsetLastNameSearch', '', { root: true })
                commit('shop/unsetDataRoom', '', { root: true })
                commit('bookings/resetToInitialState', null, { root: true })
                Vue.swal.fire(
                    'Operación finalizada',
                    `🎉🎉 La orden ha sido completada: ${invoice}. 🎉🎉`,
                    'success'
                )
            }
        }
        
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorItemsOrderCart', errorMsg, { root: true })
    } finally {
        consoleMsgFinally('shop/sendCartItems', 'La petición para el envío de los detalles de la compra finalizó')
    }
}


export async function cancelOrder({ commit }, idOrder) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })

        /*commit('setLoading', true, { root: true} ) //start loader                
        const response = await Vue.axios({
            method:'POST',            
            url:'/paymentretry/', 
            data: idOrder
        })  
        const respuesta = response && response.data  */

        commit('shop/setEmptyCart', [], { root: true })
        commit('shop/unsetLastNameSearch', '', { root: true })
        commit('shop/unsetDataRoom', '', { root: true })
        showAlertMessage('Cancelación', 'InfoIcon', `🎉🎉 Orden cancelada completada: ${idOrder || '' }. 🎉🎉`, 'success', 4000, 'bottom-right')
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('shop/setErrorCancelOrderCart', errorMsg, { root: true })
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/cancelOrder', 'La petición para cancelar la orden finalizó')
    }
}
export function typeModel(id, type, detailId) {
    if (type == "product") {
        return {
            product: id,
            package: null,
            event: null,
            booking: null,
            productDetail: detailId,
            packageDetail: null,
            eventDetail: null
        }
    } else if (type == "package") {
        return {
            product: null,
            package: id,
            event: null,
            booking: null,
            productDetail: null,
            packageDetail: detailId,
            eventDetail: null
        }
    } else if (type == "event") {
        return {
            product: null,
            package: null,
            event: id,
            booking: null,
            productDetail: null,
            packageDetail: null,
            eventDetail: detailId
        }
    } else if (type == "booking") {
        return {
            product: null,
            package: null,
            event: null,
            booking: null,
            productDetail: null,
            packageDetail: null,
            eventDetail: null
        }
    }
}
export function setItinerary(item) {
    let detailitinerary = {}
    if (item.itinerario) {
        const flight = item.itinerario.flight || ''
        const flighttime = item.itinerario.flighttime || ''
        const ifrom = item.itinerario.ifrom || ''
        const ito = item.itinerario.ito || ''
        const pax = item.itinerario.pax || ''
        const airline = item.itinerario.airline ? item.itinerario.airline.name : ''
        const pickup = item.itinerario.pickup || ''
        const trip = item.itinerario.trip || ''
        detailitinerary = { flight, flighttime, ifrom, ito, pax, airline, pickup, trip }
    }
    return detailitinerary
}

export function createObjectPayload(item, unitPrice, qty, discount, discounttype, total, note, clientnote, orderextraFields, product, pack, event, booking, productDetail, packageDetail, eventDetail, detailitinerary, operationtime, orderdetailbeo, serviciosihot, partial, firstpay) {

    let detalle = { unitPrice, qty, discount, discounttype, total, note, clientnote, orderextraFields, product, package: pack, event, booking, productDetail, packageDetail, eventDetail, detailitinerary, operationtime, orderdetailbeo, serviciosihot, partial, firstpay }

    if (item.operationdate) {
        detalle.operationdate = item.operationdate
    }
    const itinerarioValido = isValidItinerary(detailitinerary)

    if (!itinerarioValido) {
        delete detalle.detailitinerary
    }

    const beoValido = isValidOrderDetailBEO(orderdetailbeo)

    if (!beoValido) {
        delete detalle.orderdetailbeo
    }

    const extraFieldsValido = isValidaOrderExtraFields(orderextraFields)

    if (!extraFieldsValido) {
        delete detalle.orderextraFields
    }
    console.log(1)
    return detalle

}

export function createObjectBooking(objetoDetalle, item) {
    delete objetoDetalle.detailitinerary
    delete objetoDetalle.operationtime
    objetoDetalle.orderbooking = {
        email: item.Email,
        givenname: item.GivenName,
        surname: item.Surname,
        mealplancode: item.MealPlanCodes,
        // mealplandescription: item.MealPlanDescription,
        nameprefix: item.NamePrefix,
        phonenumber: item.PhoneNumber,
        adults: item.adultos,
        datein: item.dateIn,
        dateout: item.dateOut,
        hotel: item.hotel,
        language: item.idioma,
        children: item.ninosTotal,
        ninos: toJson(item.ninos),
        promotioncode: item.promoCode,
        roomtypecode: item.roomCode,
        rateplancode: item.tarifa,
        vendor: item.vendor,
        rateInit: item.rateInit,
        nights: item.nights,
        total: item.saleprice,
        roomtypename: item.roomtypename,
        rateplanid: item.rateplanid,
        authorizationUser: item.idUserAuthorizeRate || null,
        isAuthorization: item.idUserAuthorizeRate ? true : false,    
        urlAuthorization: item.urlAuthorization,
        urlimage: item.img,
        termscondition: item.term || 1,
        rateplanname: item.rateplanname,
        partial: item.partial,
        firtpay: item.firstPay
    }
}

export function isValidItinerary(itinerario) {
    const { ifrom, ito, pax, trip } = itinerario
    if (ifrom == "" || ito == "" || pax == "" || trip == "") {
        return false
    } else {
        return true
    }
}

export function isValidOrderDetailBEO(dataBEO) {
    const { backup, comentario, locacion, titulo } = dataBEO
    if (backup == null && comentario == "" && locacion == null && titulo == "") {
        return false
    } else if (backup == null || locacion == null || comentario != "" || titulo != "") {
        return true
    }
}

export function isValidaOrderExtraFields(extrafields) {
    if (extrafields.length == 0) {
        return false
    } else {
        return true
    }
}

export async function fetchDescripcionCotizacion({ commit }, datos) {
    const { typeTitle, idLanguage, type } = datos
    try {
        const response = await Vue.axios({
            url: `/catalogs/descripcionCotizacion/`,
            params: {
                idLanguage,
                typeTitle
            }
        })
        const payload = response && response.data
        
        if( type == 'bienvenida'){
            const data = { idWelcome: payload[0].id }
            commit('shop/setIdWelcomedQuotation', data, { root: true })
        }
        if( type == 'despedida'){
            const data = { idFarewellMessage: payload[0].id, }
            commit('shop/setIdFarewellQuotation', data, { root: true })
        }

    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/fetchDescripcionCotizacion', 'Petición para obtener idCotizaciones descripciones')
    }
}

export async function fetchAmenitiesByHotel({ commit }, datos) {
    const { idHotel, idLanguage } = datos
    try {
        const response = await Vue.axios({
            url: `/catalogs/amenities/`,
            params: {
                idLanguage,
                idHotel
            }
        })
        const payload = response && response.data
        const id = payload.length > 0 ? payload[0].id : null        
        commit('shop/setIdAmenity', id, { root: true })        
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('shop/fetchAmenitiesByHotel', 'Petición get amenities by hotel')
    }
}

export async function fetchPaymentStatus({ commit }, order) {

    try {        
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `payment/orderTransactions/?order=${order}`,
        })
        const payload = response && response.data
        commit('shop/setStatusPayment', payload[0].transactionstatus.name, { root: true })

    } catch (error) {
        console.log(error)
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/fetchPaymentStatus', 'La petición para revisar status payment finalizó')
    }
}
