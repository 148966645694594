var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerBooking"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Prefix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Prefix"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.NamePrefix),callback:function ($$v) {_vm.$set(_vm.booking, "NamePrefix", $$v)},expression:"booking.NamePrefix"}},[_c('option',{attrs:{"value":""}},[_vm._v("Choose an option")]),_vm._l((_vm.namesPrefix),function(namesF,index){return _c('option',{key:index,domProps:{"value":namesF.value}},[_vm._v(_vm._s(namesF.text))])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Name"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.GivenName),callback:function ($$v) {_vm.$set(_vm.booking, "GivenName", $$v)},expression:"booking.GivenName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Surname"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.Surname),callback:function ($$v) {_vm.$set(_vm.booking, "Surname", $$v)},expression:"booking.Surname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Phone"}},[_c('b-form-input',{attrs:{"type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"10","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.PhoneNumber),callback:function ($$v) {_vm.$set(_vm.booking, "PhoneNumber", $$v)},expression:"booking.PhoneNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.Email),callback:function ($$v) {_vm.$set(_vm.booking, "Email", $$v)},expression:"booking.Email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Notes( max: 255)"}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"write note","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.clientnote),callback:function ($$v) {_vm.$set(_vm.booking, "clientnote", $$v)},expression:"booking.clientnote"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v(" Has "+_vm._s(_vm.max - _vm.booking.clientnote.length)+" characters left")])],1)],1)}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }