<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content v-if="user">
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ user.name +' '+ user.lastname  }}     
        </p>
        <span class="user-status">{{ user.canalventa.name }} | {{puntoDeVenta}} </span>            
      </div>      
      <div class="avatar">
        <div class="avatar__letters">
          {{user.name.substring(0,1).toUpperCase()+user.lastname.substring(0,1).toUpperCase()  }}
        </div>
        <span class="badge-on"></span>
      </div>
    </template>
    <!-- <b-dropdown-item link-class="d-flex align-items-center" v-if="user">
      📋 <span>{{puntoDeVenta}}</span>
    </b-dropdown-item>             -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Configuración</span>
    </b-dropdown-item> -->


    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="_logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Cerrar Sesión</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>

import { avatarText } from '@core/utils/filter'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require('@/assets/images/default.jpg'),
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed:{
    ...mapState('shop',['puntoventa']),                     
    ...mapState('auth',['user']),
    puntoDeVenta(){
      if( this.puntoventa && this.user ){
        const puntoDeVenta = this.user.puntosventa.find( pv => pv.id === this.puntoventa )
        return puntoDeVenta.name 
      } else {
        return ''
      }              
    }
  },
  methods: {
    ...mapMutations('auth',['logout',]),
    ...mapMutations('shop',['unsetCanalVenta','unsetPuntoDeVenta','setEmptyCart','unsetLastNameSearch','unsetDataRoom']),  
    ...mapMutations('products',['unsetProducts']),
    _logout() {      
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()      
      this.unsetProducts()
      this.$router.push({ name: 'start' })
    }
  },
}
</script>
<style>
.avatar {
    /* Center the content */
    display: inline-block;
    vertical-align: middle;

    /* Used to position the content */
    position: relative;

    /* Colors */
    background-color: #22292f;
    color: #FFF;

    /* Rounded border */
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.avatar__letters {
    /* Center the content */
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
.badge-on {
  position: absolute;
  min-height: 11px;
  min-width: 11px;
  top: 30px;
  right: 2px;
  padding: 5px 5px;
  border-radius: 50%;
  background: #28c76f;
}
</style>