<template>
    <div class="box-skeleton">
        <div class="skeleton">
            <div class="skeleton-left flex1">
                <div class="square-grid"></div>
            </div>
            <div class="skeleton-left flex1">
                <div class="square-grid"></div>
            </div>

            <div class="skeleton-left flex1">
                <div class="square-grid"></div>
            </div>      

            <div class="skeleton-left flex1">
                <div class="square-grid"></div>
            </div>      
        </div>

    </div>
</template>

<script>


export default {
    components: {

    },
    methods: {

    },
}
</script>

