<template>   
 <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Respondamos algunas preguntas
          </h2>
          <b-card-text class="mb-2">
            o elija una categoría para encontrar rápidamente la ayuda que necesita
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <!-- <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend> -->
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Buscar pregunta frecuente..."
              />
            </b-input-group>                       
          </b-form>
          <!-- form -->        
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs" v-if="data.length">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >   
        <!-- payment tab -->        
        <b-tab
          v-for="(category, index) in data" :key="index"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <feather-icon
              :icon="category.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ category.title }}</span>
          </template>
          <FaqQuestionAnswer :options="category" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <b-alert show variant="danger"  class="mt-2" v-else>
			<div class="alert-body text-center"> 😣 <span>No se encontró algo relacionado a su búsqueda</span></div>
		</b-alert>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>¿Todavía tienes una pregunta?</h2>
          <b-card-text class="mb-3">
            Si no puede encontrar una pregunta en nuestras preguntas frecuentes, siempre puede contactarnos. ¡Te responderemos a la brevedad!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>+52 984 -80- 44 -226 </h4>
            <span class="text-body">¡Siempre estaremos felices de ayudar!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>rsantos@thefives.com.mx</h4>
            <span class="text-body">Igual puede solicitar ayuda en este correo</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import { faqData } from '@/data/faq'
import FaqQuestionAnswer from '@/components/faq/FaqQuestionAnswer'

export default {
  components:{
    FaqQuestionAnswer
  },
  data() {
    return {
      faqSearchQuery: '',    
      data: faqData
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.getData()
      },
    },
  },
  methods: {
    getData() {
      if( this.faqSearchQuery != ''){
        const queryLowered = this.faqSearchQuery.toLowerCase()                
        const filteredData = {}
          Object.entries(faqData).forEach(entry => {
            const [ categoryName, categoryObj ] = entry        
            const filteredQAndAOfCategory =
              categoryObj.qandA.filter( qAndAObj => { 
                return qAndAObj.question.toLowerCase().includes(queryLowered)
            }
          )
            if (filteredQAndAOfCategory.length) {
              filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
            }
          })
          this.data = filteredData
      } else {
        this.data = faqData
      }           
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>