var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"misc-wrapper"},[_c('HeaderPayment'),_c('b-container',{staticClass:"mt-2 mb-5 flex-fill container"},[(_vm.detailOrder)?_c('div',[(
           _vm.detailOrder[0].orderdetailStatus != 2 &&
           _vm.detailOrder[0].itinerary.pax != ''
         )?_c('b-row',{staticClass:"mt-3 justify-content-md-center"},[_c('b-col',{staticClass:"col-md-7"},[_c('div',{staticClass:"customers Data"},[_vm._v(" "+_vm._s(_vm.detailOrder[0].customer.name)+" ")]),( _vm.transfersIsValidNull  == true)?_c('div',[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Your transfer is null please contant whit sales person 😞 ")])]):_c('div',[_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_vm._v(" Your transfer is correct 😊 ")])]),_vm._l((_vm.detailOrder),function(detail){return _c('div',{key:detail.key},[_c('ValidationObserver',{ref:"observer3",refInFor:true},[(detail.itinerary.pax != '')?_c('b-form',{staticClass:"card",on:{"change":function($event){_vm.validInfo(_vm.detailOrder), _vm.validCupon()}}},[_c('b-row',{staticClass:"mt-3 centerTitle"},[_c('b-col',{staticClass:"col-md-12"},[_c('div',{staticClass:"titleTransfer"},[_vm._v(_vm._s(detail.itemsold))])]),_c('b-col',{staticClass:"col-md-6"},[_c('h5',[_vm._v(_vm._s(detail.itinerary.trip))])]),_c('b-col',{staticClass:"col-md-6"},[_c('h5',[_vm._v("Arrival date "+_vm._s(detail.operationdate))])])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"col-md-6"},[_c('b-input-group',{attrs:{"prepend":"From"}},[_c('b-form-select',{attrs:{"disabled":true,"options":_vm.destinations},model:{value:(detail.itinerary.ifrom),callback:function ($$v) {_vm.$set(detail.itinerary, "ifrom", $$v)},expression:"detail.itinerary.ifrom"}})],1),_c('br'),_c('b-input-group',{attrs:{"prepend":"Pax"}},[_c('b-form-input',{attrs:{"disabled":true,"type":"number","placeholder":"Enter pax"},model:{value:(detail.itinerary.pax),callback:function ($$v) {_vm.$set(detail.itinerary, "pax", $$v)},expression:"detail.itinerary.pax"}})],1),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"flight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var valid = ref.valid;
         var errors = ref.errors;
return _c('b-input-group',{attrs:{"prepend":"Flight"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Flight","state":errors[0] ? false : valid ? true : null,"disabled":true,"locale":"en"},model:{value:(detail.itinerary.flight),callback:function ($$v) {_vm.$set(detail.itinerary, "flight", $$v)},expression:"detail.itinerary.flight"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"pickup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var valid = ref.valid;
         var errors = ref.errors;
return _c('b-input-group',{attrs:{"prepend":"Pickup"}},[_c('b-form-input',{attrs:{"id":'pickup' + detail.id,"type":"time","disabled":true,"placeholder":"Pickup","state":errors[0] ? false : valid ? true : null,"locale":"en"},model:{value:(detail.itinerary.pickup),callback:function ($$v) {_vm.$set(detail.itinerary, "pickup", $$v)},expression:"detail.itinerary.pickup"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{staticClass:"col-md-6"},[_c('b-input-group',{attrs:{"prepend":"To"}},[_c('b-form-select',{attrs:{"disabled":true,"options":_vm.destinations},model:{value:(detail.itinerary.ito),callback:function ($$v) {_vm.$set(detail.itinerary, "ito", $$v)},expression:"detail.itinerary.ito"}},[_vm._v(" >")])],1),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"airline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var valid = ref.valid;
         var errors = ref.errors;
return _c('b-input-group',{attrs:{"prepend":"Airline"}},[_c('b-form-input',{attrs:{"list":"my-list-id","placeholder":"Add Airline","state":errors[0] ? false : valid ? true : null,"disabled":true,"locale":"en"},model:{value:(detail.itinerary.airline),callback:function ($$v) {_vm.$set(detail.itinerary, "airline", $$v)},expression:"detail.itinerary.airline"}}),_c('datalist',{attrs:{"id":"my-list-id"}},_vm._l((_vm.aerolineas),function(aerolinea){return _c('option',{key:aerolinea.key},[_vm._v(" "+_vm._s(aerolinea.text)+" ")])}),0),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"flighttime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var valid = ref.valid;
         var errors = ref.errors;
return _c('b-input-group',{attrs:{"label":"Fligth time","prepend":"Flight time"}},[_c('b-form-input',{attrs:{"type":"time","disabled":true,"state":errors[0] ? false : valid ? true : null,"locale":"en"},on:{"change":function($event){return _vm.getDate(
                               detail.itinerary.flighttime,
                               detail.itinerary.pickup,
                               detail.id,
                               detail.itinerary,
                               detail.itinerary.trip
                             )}},model:{value:(detail.itinerary.flighttime),callback:function ($$v) {_vm.$set(detail.itinerary, "flighttime", $$v)},expression:"detail.itinerary.flighttime"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('br')],1):_vm._e()],1)],1)}),_c('div',[_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"success","href":_vm.couponLink +
                   _vm.detailOrder[0].order +
                   '/' +
                   _vm.detailOrder[0].productID +
                   '/' +
                   _vm.detailOrder[0].id +
                   '/transfer/',"target":"_blank","disabled":_vm.transfersIsValidNull}},[_c('b-icon-file-earmark-arrow-down'),_vm._v(" Coupon ")],1)],1)],2)],1):_c('div',[(_vm.detailOrder[0].itinerary.pax != '')?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Your transfer is canceled ")]):_vm._e(),(_vm.detailOrder[0].itinerary.pax == '')?_c('div',[_c('div',{staticClass:"w-100 text-center"},[_c('h2',{staticClass:"mb-1"},[_vm._v(" Your transfer is not valid ❌ ")]),_c('img',{staticClass:"img-fluid",attrs:{"src":"/img/error.7cce96d0.svg","alt":"Error page"}})])]):_vm._e()])],1):_c('div',[_c('div',{staticClass:"w-100 text-center"},[_c('h2',{staticClass:"mb-1"},[_vm._v("No information found ❌ ")]),_c('img',{staticClass:"img-fluid",attrs:{"src":"/img/error.7cce96d0.svg","alt":"Error page"}})])])]),_c('FooterPayment')],1)}
var staticRenderFns = []

export { render, staticRenderFns }