import store from '../../store' //para acceder a state para isLogged
const channelBooking = [1,6] //1-id sistemas, 6->contact center 
const canalUser = store.state.auth.user ? store.state.auth.user.canalventa.id : 0
// console.log(canalUser)
const withBooking = [{ title: 'Experiencias', route: 'sales'}, { title: 'Estancias', route: 'booking'} ]
const withOutBooking = [ { title: 'Experiencias', route: 'sales'}]
const subMenuToUse = channelBooking.includes(canalUser) ? withBooking : withOutBooking
// console.log("canalUser", canalUser )
// console.log(channelBooking.includes(canalUser) )

export default [    
  {
    title: 'Experiencias',
    icon: 'ShoppingCartIcon',
    // tag: '2',
    tagVariant: 'light-primary',
    children: subMenuToUse,
  },  
  {
    title: 'Mis ventas',
    route: 'my-sales',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Operación',
    route: 'operations',
    icon: 'CommandIcon',
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
  },
  
]
