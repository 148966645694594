<template>
  <div>
    <HeaderPayment />

    <div class="misc-wrapper">
      <div>
        <b-container class="mt-2 col-sm-12 mb-5 flex-fill container">
          <b-row>
            <b-col class="col-md-5 col-sm-12 columna">
              <div v-if="dataOrderPay">
                <b-card tag="article" class="mb-2 rounded-0">
                  <template #header>
                    <b-row>
                      <div class="col-md-12">
                        <b-icon
                          icon="cart-fill"
                          font-scale="1"
                          animation="fade"
                          aria-hidden="true"
                          class="h4 float-right"
                          style="color: #000000"
                        >
                        </b-icon>
                        <h6 class="mb-0" style="0.75s infinite ease-in-out">
                          Purchased Products({{ dataOrderPay.detail.length }})
                        </h6>
                      </div>
                    </b-row>
                  </template>
                  <!-- Products -->
                  <table class="table animate__animated animate__bounce">
                    <tbody>
                      <tr v-for="item in dataOrderPay.detail" :key="item.folio">
                        <td class="text-small-cart">
                          <span class="" v-if="item.booking.datein">
                            <span style="font-weight: bold; font-size: 16px"
                              >{{ item.booking.roomtypename }}
                            </span>
                            <br />

                            <table class="content booking">
                              <tr></tr>

                              <tr>
                                <td>
                                  <span style="font-weight: bold"
                                    >Check-In:</span
                                  >
                                  <span style="">
                                    {{ item.booking.datein }}</span
                                  >
                                </td>

                                <td>
                                  <span style="font-weight: bold"
                                    >Check-Out:</span
                                  >
                                  <span style="">
                                    {{ item.booking.dateout }}</span
                                  >
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span style="font-weight: bold">Nights:</span>
                                  {{ item.booking.nights }}
                                </td>
                                <td>
                                  <span style="font-weight: bold">Adults:</span>
                                  {{ item.booking.adults }}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span style="font-weight: bold"
                                    >Childrens:</span
                                  >
                                  {{ item.booking.children }}
                                </td>
                              </tr>
                            </table>

                            <table
                              class="content booking"
                              v-if="item.booking.datein"
                            >
                              <tr>
                                <td>
                                  <span style="font-weight: bold">Plan:</span>
                                  {{ item.booking.rateplanname }}
                                </td>
                              </tr>
                            </table>
                          </span>
                          <span v-else class="">
                            <span class="titleProduc" style="font-weight: bold"
                              >{{ item.itemsold }}
                            </span>

                            <span class="titleProduc" style="font-weight: bold"
                              >Operation Date:
                            </span>
                            <span>
                              {{ item.operationdate }}
                            </span>
                          </span>
                        </td>
                      </tr>
                      <hr />
                      <tr class="text-small-cart row">
                        <span class="col-6" style="text-align: right">
                          <span class="text-right">Initial Payment:</span>
                         $ {{
                            (
                              dataOrderPay.firstpay * currencyValue.value
                            ).toFixed(2)
                          }}
                          {{ dataOrderPay.currency.code }}
                        </span>
                      </tr>
                      <hr />
                      <tr class="text-small-cart row">
                        <span class="col-6">
                          <span  style="font-weight: bold">Total Balance:</span>
                         $ {{
                            (
                              dataOrderPay.totalSale * currencyValue.value
                            ).toFixed(2)
                          }}
                          {{ dataOrderPay.currency.code }}
                        </span>
                        <span class="col-6">
                         <span  style="font-weight: bold"> Paid out:</span>
                         $ {{ (sum * currencyValue.value).toFixed(2) }}
                          {{ dataOrderPay.currency.code }}
                        </span>
                      </tr>
                      <hr />

                      <tr v-if="sum != 0">
                        <h4 class="listPayTitle">Transactions</h4>
                        <div>
                          <table class="listPay">
                            <tr>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Reference</th>
                              <th>Status</th>
                              <th>Currency</th>
                            </tr>
                            <tr
                              v-for="listPay in listPayOrders"
                              :key="listPay.id"
                            >
                              <td>
                                {{
                                  listPay.date_transaction_bank
                                    ? listPay.date_transaction_bank
                                    : ""
                                }}
                              </td>
                              <td>$ {{ listPay.paid_amount }}</td>
                              <td>
                                {{
                                  listPay.folio_bank ? listPay.folio_bank : ""
                                }}
                              </td>
                              <td>{{ listPay.transactionstatus.name }}</td>
                              <td>{{ listPay.paid_currency.code }}</td>
                            </tr>
                          </table>
                        </div>
                      </tr>

                      <hr />
                      <tr class="total">
                        <span>
                          Current / Remaining Balance :
                          $ {{
                            (
                              (dataOrderPay.totalSale - sum) *
                              currencyValue.value
                            ).toFixed(2)
                          }}
                          {{ dataOrderPay.currency.code }}
                        </span>
                      </tr>
                    </tbody>
                  </table>
                  <!-- Product end -->
                </b-card>
                <article class="card mb-2 rounded-0">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-12">
                        <svg
                          viewBox="0 0 16 16"
                          width="1em"
                          height="1em"
                          focusable="false"
                          role="img"
                          aria-label="info circle fill"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="bi-info-circle-fill b-icon bi float-right"
                        >
                          <g>
                            <path
                              fill-rule="evenodd"
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                            ></path>
                          </g>
                        </svg>
                        <h6 class="mb-0">Your information</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table animate__animated animate__bounce">
                      <tbody>
                        <tr class="text-small-cart">
                          <td>
                            <strong> Name </strong>
                            <br />
                            <span>
                              {{ dataOrderPay.customer.name }}
                              {{ dataOrderPay.customer.lastname }}
                            </span>
                          </td>
                        </tr>
                        <tr class="text-small-cart">
                          <td>
                            <strong> E-mail </strong>
                            <br />
                            <span>
                              {{ dataOrderPay.customer.email }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
              <div v-else>
                <div class="alert alert-danger" role="alert">
                  No information found
                </div>
              </div>
            </b-col>

            <div
              v-if="urlbank"
              class="col-12 col-md-7 col-sm-12 containerIframe"
            >
              <iframe class="responsive-iframe" :src="urlbank"> </iframe>
            </div>
            <div
              class="col-12 col-md-7 col-sm-12"
              v-else-if="dataOrderPay.totalSale - sum != 0 && sum != 0"
            >
              <div class="checkout-options">
                <b-card>
                  <h3>Pay outstanding balance</h3>
                  <br />
                  <div class="row">
                    <b-col class="col-6 col-md-6 col-sm-6">
                      <ValidationObserver
                        tag="form"
                        @submit.prevent="onSubmit"
                        ref="observer"
                      >
                        <ValidationProvider rules="required" name="balance">
                          <b-input-group
                            slot-scope="{ valid, errors }"
                            prepend="Balance"
                          >
                            <b-form-input
                              type="number"
                              step=".01"
                              :placeholder="
                                parseFloat(
                                  dataOrderPay.totalSale - sum
                                ).toFixed(2) + ' USD'
                              "
                              :state="errors[0] ? false : valid ? true : null"
                              v-model="amount"
                              @input="validTotal()"
                              locale="en"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-input-group>
                        </ValidationProvider>
                      </ValidationObserver>
                    </b-col>
                    <b-col class="col-6 col-md-6 col-sm-6">
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button variant="primary" disabled>
                            💸 Divisa
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-select
                          id="divisaSelect"
                          @change="calcularImportes"
                          v-model="cart.cliente.currency"
                        >
                          <option
                            v-for="cur in currencies"
                            :key="cur.id"
                            :value="cur"
                          >
                            {{ cur.code }}
                          </option>
                        </b-form-select>
                      </b-input-group>
                    </b-col>
                  </div>
                  <!-- <hr> -->
                  <div class="price-details">
                    <div v-if="cart.cliente.currency.code == 'MXN'">
                      <div>
                        <hr />
                        <ul class="list-unstyled">
                          <li class="price-detail">
                            <div class="detail-title detail-total">
                              Tipo de cambio MXN:
                            </div>
                            <div class="detail-amt font-weight-bolder">
                              <b-badge pill>$ {{ tc }}</b-badge>
                            </div>
                          </li>
                          <li class="price-detail">
                            <div class="detail-title detail-total">
                              Total divisa MXN:
                            </div>
                            <div class="detail-amt font-weight-bolder">
                              <b-badge pill>${{ totalDivisa }}</b-badge>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr />

                    <div class="float-right" >
                      <div v-if="!loadvalid">
                      <b-button
                        variant="primary"
                        :disabled="validNull||loadvalid"
                        @click="getLinkPay()"
                      >
                        <b-icon-check-square-fill></b-icon-check-square-fill>
                        Pay
                      </b-button>
                       </div>
                      <div v-else class="text-center" >
                          <b-spinner label="Loading..." variant="success"/>                          
                      </div>
                    </div>
                   
                  </div>
                </b-card>
              </div>
            </div>
            <div
              class="col-12 col-md-7 col-sm-12"
              v-else-if="sum != 0 && dataOrderPay.totalSale - sum == 0"
            >
              <div class="checkout-options">
                <b-card>
                  <div class="w-100 text-center">
                    <h2 class="mb-1">This order is paid 😊👌</h2>
                    <img
                      src="/img/error.7cce96d0.svg"
                      alt="Error page"
                      class="img-fluid"
                    />
                  </div>
                </b-card>
              </div>
            </div>
            <div class="col-12 col-md-7 col-sm-12" v-else>
              <div class="checkout-options">
                <b-card>
                  <div class="w-100 text-center">
                    <h2 class="mb-1">This order was not paid 🕵🏻‍♀️</h2>
                    <img
                      src="/img/error.7cce96d0.svg"
                      alt="Error page"
                      class="img-fluid"
                    />
                  </div>
                </b-card>
              </div>
            </div>
          </b-row>
        </b-container>
      </div>
      <FooterPayment> </FooterPayment>
    </div>
  </div>
</template>


<script>
import HeaderPayment from "@/components/shared/navigations/HeaderPayment";
import FooterPayment from "@/components/shared/footer/FooterPayment";
import { mapState, mapActions, mapMutations } from "vuex";

require("../../assets/css/success.css");
export default {
  async mounted() {
    if (this.order) {
      await this.getDataOrder();
      await this.getDaSum();
      await this.fetchCurrencies(this.cart.cliente);
      this.currencyValue = this.currencies.find(
        (currency) => currency.code === this.dataOrderPay.currency.code
      );
    }
  },
  components: {
    HeaderPayment,
    FooterPayment,
  },

  data() {
    return {
      loadvalid:false,
      order: this.$route.query.order,
      urlbank: this.$route.query.urlbank,
      sum: 0,
      amount: "",
      validNull: true,
      currencyValue: 0,
      listPayOrders: [],
    };
  },
  computed: {
    ...mapState("shop", ["cart"]),
    ...mapState("start", ["currencies"]),

    ...mapState("shop", ["errorMessageApi", "dataOrderPay"]),
    ...mapState("mySales", ["errorMessageApi", "dataTransaction","isloadingProcess"]),
    ...mapState("mySales", ["errorMessageApi", "transactionPay"]),

    subTotal() {
      return (
        Math.round(
          (parseFloat(this.totalDiscountOnAllProducts) +
            parseFloat(this.cart.totalBreakdown.total)) *
            100
        ) / 100
      ).toFixed(2);
    },
    total() {
      return (Math.round(this.amount * 100) / 100).toFixed(2);
    },
    tc() {
      const currencieValor = this.cart.cliente.currency
        ? this.cart.cliente.currency.value
        : 1;
      return (Math.round(currencieValor * 100) / 100).toFixed(2);
    },
    totalDivisa() {
      var amount = this.amount;
      if (amount == "") {
        amount = 0;
      } else {
        amount = this.amount;
      }
      const currencieValor = this.cart.cliente.currency
        ? this.cart.cliente.currency.value
        : 1;
      return (
        Math.round(parseFloat(amount) * parseFloat(currencieValor) * 100) / 100
      ).toFixed(2);
    },
  },
  methods: {
    ...mapActions("shop", ["fetchDataOrder"]),
    ...mapActions("mySales", ["fetchDataTransaction"]),
    ...mapActions("mySales", ["fetchTransactionPay"]),
    ...mapActions("start", ["fetchCurrencies"]),

    ...mapMutations("shop", ["setDataOrder"]),
    ...mapMutations("mySales", ["setDataTransaction","setLoadingProcess"]),
    setNullDataOrder() {
      this.setDataOrder(null);
    },
    async getDataOrder() {
      await this.fetchDataOrder(this.order);
      await this.fetchDataTransaction(this.order);
    },
    getDaSum() {
      this.dataTransaction.forEach((list) => {
        if (list.transactionstatus.name == "Approved") {
          this.sum += parseFloat(list.amount);
        }
        if (list.transactionstatus.name != "Pending") {
          this.listPayOrders.push(list);
        }
      });
    },
    async getLinkPay() {
      const payload = { amount: this.amount, order: this.order };
     
     this.loadvalid=true
      await this.fetchTransactionPay(payload);
      this.loadvalid=false
      this.urlbank = this.transactionPay.link;
    },
    ...mapMutations("shop", ["setCurrency"]),
    calcularImportes() {
      const currencieValor = this.cart.cliente.currency
        ? this.cart.cliente.currency.value
        : 1;
      this.divisaTotal = (
        Math.round(
          parseFloat(this.cart.totalBreakdown.total) *
            parseFloat(currencieValor) *
            100
        ) / 100
      ).toFixed(2);
      this.setCurrency(this.cart.cliente.currency);
    },

    validTotal() {
      const total = parseFloat(this.amount);
      const balance = this.dataOrderPay.totalSale - this.sum;
      if (total <= balance) {
        this.validNull = false;
      } else {
        this.validNull = true;
      }
      console.log(0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-misc.scss";
iframe {
  overflow: hidden;
}

.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 619px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.alert.alert-danger {
  padding: 9px 0 7px 13px;
}

.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1440px;
}

.misc-wrapper {
  display: block;
  flex-basis: 100%;
  min-height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.container {
  padding-bottom: 62px;
}

.misc-wrapper {
  position: relative;
  height: 100%;
  display: contents;
}


/*
#app > div {
  display: inline-table;
}
*/

tr.total {
  text-align: center;
  padding: 14px 0 0 0;
  display: block;
  font-weight: 600;
  font-size: 18px;
}

tr.text-small-cart.row {
  text-align: center;
}

table.payment {
  background: #fff;
  padding: 27px 12px 25px 15px;
  border-collapse: initial;
  text-align: center;
  margin: 31px auto 105px;
  width: 71%;
}

.card-img-top {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0rem;
  min-height: 0rem;
  display: flex;
  align-items: center;
}
tr.listPay {
  text-align: center;
  font-size: 12px;
}

.listPay th,
.listPay td {
  padding: 11px;
  font-size: 13px;
  vertical-align: middle;
}
.listPayTitle {
  text-align: center;
  margin-bottom: 10px;
}
ul.list-unstyled {
  text-align: right;
}
table.content.booking {
  margin: 12px 0 0 0;
}

table.content.booking span {
  padding-right: 5px;
}
table.content.booking tr,
table.content.booking td {
  border: 0;
  padding: 6px 15px 6px 15px;
}

.titleProduc {
  font-weight: bold;
  margin: 3px 0 10px 0;
  display: block;
}

table.listPay {
  margin: 0 auto;
}
</style>
