<template>
	<div>	
		<div class="tabs">
			
			<div class="tab-content">
				<MultiTabs />
			</div>
		</div>
	</div>
</template>

<script>


import MultiTabs from '@/components/shop/booking/MultiTabs'
export default {
	components: {		
		MultiTabs
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>