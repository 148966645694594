<template>    
  <div style="height: inherit">
      <!-- Overlay -->
    <div class="body-content-overlay" /> 
    <div class="text-center mt-2 mb-2" v-if="isLoadingBookings">
		<skeleton-loader/>
	</div>
	<div v-else-if="filteredBookings.length">	
		<b-card no-body style="max-width: 100%;"  v-for="(room, index) in filteredBookings" :key="index">		<b-row no-gutters>				
				<div class="col-xs-6 col-md-3 col-sm-12">
					<b-carousel :interval="0" controls indicators v-if="room.RoomImg" >						
						<b-carousel-slide v-for="(img, index) in room.multimedias" :key="index" >
							<template #img>
								<b-card-img	:src="img.multimedia" height="320" />
							</template>
						</b-carousel-slide>
					</b-carousel>
					<b-card-img v-else style="height:100%;" :src="room.multimedias[0].multimedia"/>
				</div>														
				<div class="col-xs-6 col-md-6 col-sm-12" style="border-right: 1px solid #e0e0e0;">
					<b-card-body>
						<h6 class="item-name mb-1">
							<b-link class="text-body"> {{ room.Name }} </b-link>
						</h6>

						<Rates
							v-if="room.showingRates"
							:room="room"
							:clave="clave"
							:indice="index"
							:terms="terms"
						/>
						<div class="text-center" v-if="!room.showingRates">							
							<b-spinner label="Loading..." variant="success"/><br>
							<strong>Buscando tarifas</strong>
						</div>

						<b-card-text class="long-description text-justify">
							{{ room.DescriptiveText }}
						</b-card-text>
						
						<statistic-card-items :statistic="room.NumberOfUnits.toString()" statistic-title="Available" color="info"/>

					</b-card-body>
				</div>
				<div class="col-xs-6 col-md-3 col-sm-12 mt-4" >
					<!-- <div style="display: flex; justify-content: center;"> -->
					<div style="padding: 1rem; justify-content: center;">
						<BookingOptions							
							:clave="clave"
							:indice="index"
							:room="room"
							:userAuthorizeRate="userAuthorizeRate"	
							@handle-add-to-cart="handleAddToCart"
							@check-price-match="checkPriceMatch"
							@only-number="onlyNumber"
							@set-tax-manual-price="setTaxManualPrice"
						/>					
					</div>					
				</div>
			</b-row>			
			<!-- Aqui modal -->
			<ModalAuthorizeRate
				:clave="clave"
				:indice="index" 
				:room="room"			
				:userAuthorizeRate="userAuthorizeRate"
				@is-valid-user-authorize-rate=isValidUserAuthorizeRate
				@send-data-user-authorize-rate=sendDataUserAuthorizeRate
			/>
		</b-card>	
	</div>

	<b-alert show variant="danger" v-if="filteredBookings.length === 0 && !isLoadingBookings" class="mt-2">
		<div class="alert-body text-center"> 😣 <span>No rooms available</span></div>
	</b-alert>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SkeletonLoader from '@core/components/skeleton-loader/SkeletonLoader'
import Rates from '@/components/shop/booking/Rates'
import ModalAuthorizeRate from '@/components/shop/booking/ModalAuthorizeRate'
import BookingOptions from '@/components/shop/booking/BookingOptions'
import StatisticCardItems from '@core/components/statistics-cards/StatisticCardItems.vue'
import { toJson, showAlertMessage,  toDecimal } from '@/helpers/helpers'

export default {
	props: {		
		clave:{
			type:String,
			required:true
		},		
		currentPage:{
			type:Number,
			required:true
		}
	},
	components: {
		SkeletonLoader,
		Rates,
		ModalAuthorizeRate,
		BookingOptions,
		StatisticCardItems
	},
	data() {
		return {
			imgUrl: process.env.VUE_APP_IMG_SRC_API,       
			perPage: 3,
		}
	},
	computed:{
		...mapState('auth',['user']),
		...mapState('start',['terms','hotels','promoCodes']),    
		...mapState('bookings',['isLoadingBookings','tabs']),
		...mapGetters('bookings',['filteredBookings']),

		listBookings(){
			const items = this.filteredBookings
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},
		totalRows () {
			return this.filteredBookings.length
		},		
		userAuthorizeRate(){
			let dato = this.tabs.find( item => item.key === this.clave )
			return dato.dataSearch.userAuthorizeRate			
		},
	},
	methods:{
		...mapActions('bookings',['checkUserAuthorizeRate', 'authorizeRate','fetchPriceMatchBooking']),
		...mapMutations('shop',['addBookingInCart']), 
		...mapMutations('bookings',['setDataUserAuthorization','setManualPriceDetailSelected','unsetDataUserAuthorization','setUserAuthorizationUrl']), 				
		handleAddToCart( data ){
			const { room, img, indice } = data
			const { detailSelected, RoomTypeCode, RoomTypeName } = room	
			const codeDePromocion = detailSelected.PromotionCode ? detailSelected.PromotionCode : ''	
			const codePromotion = this.promoCodes.find( item => item.name === codeDePromocion )
			// console.log(codePromotion.authorization)
			const infoTab = this.tabs.find( item => item.key === this.clave )			
			const  { dataSearch } = infoTab						
			const { isPromo } = detailSelected
			const dataCart = { detailSelected, dataSearch, RoomTypeCode, RoomTypeName, img }
			const payloadCart = this.setPayloadToAddCart(dataCart)
			if( codePromotion ){
				if( codePromotion.authorization ){
					this.openCloseModalRateAuthorization(indice, true)
				} else {
					this.addBookingToCart(payloadCart)
				}				
			} else if( isPromo ){
				this.openCloseModalRateAuthorization(indice, true)
			} else {
				this.addBookingToCart(payloadCart)
			}			
		},
		addBookingToCart( data ){
			this.addBookingInCart( toJson(data) )
			this.unsetDataUserAuthorization({key: this.clave})		
		},
		setPayloadToAddCart( data ){
			const { detailSelected, dataSearch, RoomTypeCode, RoomTypeName, img } = data
			detailSelected.dataSearch = dataSearch
			detailSelected.modelType = 'booking'
			detailSelected.categoryName = 'Reservas'			
			detailSelected.descmax = 0
			detailSelected.img = img
			detailSelected.hotel = dataSearch.hotel
			detailSelected.nights = dataSearch.nights
			detailSelected.tarifa = detailSelected.RatePlanCode
			detailSelected.detaildisplay = RoomTypeCode+' | '+RoomTypeName  
			detailSelected.extrapax = false
			detailSelected.detailId = detailSelected.RoomTypeCode
			detailSelected.saleprice = detailSelected.AmountAfterTax
			detailSelected.serviciosihot = null
			const pmCode = dataSearch.promoCode ? dataSearch.promoCode.name : null
			detailSelected.promoCode = detailSelected.PromotionCode || pmCode || dataSearch.promoCodeManual
			detailSelected.idUserAuthorizeRate = dataSearch.userAuthorizeRate.idUser 
			detailSelected.urlAuthorization = dataSearch.userAuthorizeRate.urlAuthorization						
			detailSelected.roomtypename = RoomTypeName
			detailSelected.term = 1
			detailSelected.rateplanid = parseInt(detailSelected.RatePlanID)
			detailSelected.rateInit = toDecimal(detailSelected.priceInit)
			detailSelected.rateManual = toDecimal(detailSelected.newManualPriceInit)
			detailSelected.rateplanname = detailSelected.MealPlanDescription			
			return detailSelected
		},
		changePage( toPage ){
			this.$emit('set-page-to-number', toPage)
		},
		onlyNumber(event){
			let keyCode = (event.keyCode ? event.keyCode : event.which);
			if ((keyCode < 48 || keyCode > 57) ) { // 46 is dot
				event.preventDefault()
			}
		},
		setTaxManualPrice( data ){	
			const { newManualPrice, indice, allowPriceMatch  } = data 	
			const payload = { newManualPrice, key: this.clave, indice, allowPriceMatch, error:false}			
			this.setManualPriceDetailSelected(payload)
		},
		openCloseModalRateAuthorization(index, boolean){
			const showHide = boolean ? 'bv::show::modal' : 'bv::hide::modal'
			const modal = 'modal-edit-rate'+index+this.clave
			const btnShowRate = 'btnShowRate'+index+this.clave
			this.$root.$emit(showHide, modal, btnShowRate) 
            const dataUrlUser = { key:this.clave, url:''} 
			this.setUserAuthorizationUrl(dataUrlUser)
		},		
		async isValidUserAuthorizeRate(user){
			const { username, code } = user
			if( username != "" && username.length > 5 ){
				const payload = { key: this.clave, username, code, canalVenta: this.user.canalventa.id}
				user.key = this.clave 
				// console.log(payload)
				this.setDataUserAuthorization( user )			
				await this.checkUserAuthorizeRate(payload)		
			}
		},
		async sendDataUserAuthorizeRate( data ){
			const { user, room, img, indice, fileImg } = data			 
			const dato = this.tabs.find( item => item.key === this.clave )// busqueda en el state			
			const userAuthorize = dato.dataSearch.userAuthorizeRate
			const { code, idUser, username } = user
			const { detailSelected, RoomTypeCode, RoomTypeName } = room			
			const isPriceMatch = detailSelected.allowPriceMatch
			const payload = { user: idUser, code, username, file: fileImg, key: this.clave, isPriceMatch }
			const  { dataSearch } = dato
			// console.log(payload)
			user.key = this.clave 			
			const dataCart = { detailSelected, dataSearch, RoomTypeCode, RoomTypeName, img }
			await this.setDataUserAuthorization(user)
			await this.authorizeRate(payload)
			const payloadCart = this.setPayloadToAddCart(dataCart)	
			if ( userAuthorize.idUser && !userAuthorize.isInvalid){
				this.addBookingToCart(payloadCart)
				this.openCloseModalRateAuthorization(indice, false)
			} 
		},
		async checkPriceMatch(data){
			const { keyRoom, newManualPrice, indice, precioManualPrevio, isManualRate, dataPromoCode, allowPriceMatch, promotionCode, isTabOne, isTabTwo } =  data 					
			const dato = this.tabs.find( item => item.key === this.clave )// busqueda en el state			
			const { dataSearch, dingusBookings } = dato
			const detailSelected = dingusBookings.find(dingB => dingB.keyRoom === keyRoom).detailSelected	
			// const allowPriceMatch = detailSelected.allowPriceMatch 
			const hasPromotionCode = detailSelected.PromotionName == "" ? false : true			
			const namePromotion = detailSelected.PromotionName			
			const priceInit = detailSelected.priceInit			
			const hotelData = this.hotels.find( hotel => hotel.id === dataSearch.hotel)	

			// console.log(toJson(dingusBookings[index].detailSelected))
			const payloadRequest = {
				key: dato.key,
				rateplanid: "",
				tarifa: detailSelected.RatePlanCode,
				isManualRate,
				manualRate: newManualPrice != "" ? parseInt(newManualPrice) : "",
				rate: parseFloat(priceInit), // 1800, no cambiar el precio manual, que se que el anterior
				//rate: parseFloat(detailSelected.AmountAfterTax), // 
				vendor: dataSearch.vendor,			
				idioma: dataSearch.language,
				roomCode: detailSelected.RoomTypeCode,
				hotel: hotelData.dingusCode || '',
				dateIn: dataSearch.dateIn,
				dateOut: dataSearch.dateOut,
				adults: dataSearch.adults,
				children: dataSearch.numberChildren,
				ninos: toJson(dataSearch.ninos),
				promotionCode: promotionCode || "",
				keyRoom,
				priceInit,
				indice,
				precioManualPrevio,
				dataPromoCode,
				allowPriceMatch	
			}
				// console.log(payloadRequest)
			if( isTabOne ){				
				await this.fetchPriceMatchBooking(payloadRequest)								
			} else if( isTabTwo && !hasPromotionCode){
				await this.fetchPriceMatchBooking(payloadRequest)								
			} else {
				const text = `😪😪 No se puede hacer el proceso tiene la promoción ${namePromotion}`
				showAlertMessage( 'Información', 'InfoIcon', text, 'danger', 4000, 'bottom-right')				
			}

		},
	}
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.long-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>