export default {
    categories:[],
    currencies:[],
    customerTypes:[],
    departaments:[],
    destinations:[],
    discounts:[],
    extraFieldsTypes:[],      
    hotels:[],
    paymentMethods:[],
    terms:[],
    airlines:[],
    promoCodes:[],
    languages:[],
    selectedCategory: null,
    errors: {
        categories: { error: false, message: '' },
        currencies: { error: false, message: '' },
        customerTypes: { error: false, message: '' },
        discounts: { error: false, message: '' },
        departaments: { error: false, message: '' },
        destinations: { error: false, message: '' },
        extraFieldsTypes: { error: false, message: '' },
        hotels: { error: false, message: '' },
        paymentMethods: { error: false, message: '' },
        terms: { error: false, message: '' },
        airlines: { error: false, message: '' },
        promoCodes: { error: false, message: '' },
        languages: { error: false, message: '' },
    }               
}