<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <Logo/>
      <h2 class="brand-text text-primary ml-1">
        Salescore
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div v-if="success">
          <h1 class="mb-1"> Thanks for your payment 💵🎉🎉 </h1>        
          <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex; font-size:2.5em;">
            <span class="swal2-success-line-tip">            
            </span>
            <span class="swal2-success-line-long">            
            </span>
            <div class="swal2-success-ring">
            </div>           
          </div>
          <hr>
              <h3 class="mb-1"> Your payment was completed successfully 🤩</h3> 
          <hr>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-dark">
                <tr >
                  <th scope="col">Reference</th>
                  <th scope="col">Authorization number</th>                    
                  <th scope="col">Email</th>              
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>              
                  <td>{{referencia}}</td>
                  <td>{{nuAut}}</td>                      
                  <td>{{email}}</td>
                  <td>{{importe}}</td>
                </tr>            
              </tbody>
            </table>
          </div>
          <p class="text-center">
            <router-link :to="{name:'sales'}" class="btn btn-info">
              <b-icon-cart-check-fill></b-icon-cart-check-fill> Back to start
            </router-link>
          </p> 
        </div>

        <div v-else>
          <h1 class="mb-1">An error has occurred with the payment, the process has failed 😪 </h1>        
          <div class="swal2-icon swal2-error swal2-animate-error-icon" style="display: flex; font-size:2em;">
            <span class="swal2-x-mark">
              <span class="swal2-x-mark-line-left"></span>
              <span class="swal2-x-mark-line-right"></span>
            </span>
          </div> 
            <hr>
              <h3 class="mb-1"> An error has occurred with the payment, the process has failed 😔</h3> 
            <hr>
            <div class="table-responsive">
              <table class="table table-bordered">
                  <thead class="thead-dark">
                    <tr >
                      <th scope="col">Reference</th>
                      <th scope="col">Authorization number</th>
                      <th scope="col">Email</th>              
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>              
                      <td>{{referencia}}</td>
                      <td>{{nuAut}}</td>
                      <td>{{email}}</td>
                      <td>{{importe}}</td>
                    </tr>            
                  </tbody>
                </table>
            </div>
            
            <router-link :to="{name:'sales'}"  class="btn btn-info float-left" v-if="user">
              <b-icon-cart-check-fill></b-icon-cart-check-fill> Back to start         
            </router-link>

              <!-- <b-button
                v-if="user"         
                variant="success" class="float-right"
                @click="_retryPayment"
              ><b-icon icon="credit-card" aria-hidden="true"></b-icon> Retry payment
              </b-button>
                                    
              <b-button
                v-if="!user"         
                variant="success" class="float-right"
                @click="_retryPaymentLink"
              ><b-icon icon="credit-card" aria-hidden="true"></b-icon> Retry payment 
              </b-button>           -->
        </div>
        
      </div>
    </div>
  </div>
<!-- / BankResponse page-->
</template>

<script>
/* eslint-disable global-require */
import Vue from 'vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import Logo from '@core/layouts/components/Logo.vue'
require("../../assets/css/success.css")

export default {
  components: {
    Logo,    
  },
  mounted(){     
    if( this.success ){
      this.setEmptyCart()
       Vue.swal.fire('Operación finalizada', `🎉🎉 El pago ha sido completado. 🎉🎉`, 'success')                                                                      
    }
    this.splitText()
  },
  data() {
    return {
      success: (this.$route.query.success === 'true') ? true : false,
      nbResponse: this.$route.query.nbResponse || '',
      referencia: this.$route.query.referencia || '',
      importe: this.$route.query.importe || '',
      nuAut: this.$route.query.nuAut || '',        
      email: this.$route.query.email || '',
      urlBase: process.env.VUE_APP_BASE_URL_API,
      splitedReferencia: ""
    }
  },
  computed: {
    ...mapState('shop',['cart']),
    ...mapState('auth',['isLogged','user']),     
  },
  methods:{
    ...mapActions('shop',['sendInfoClient']),
    ...mapMutations('shop',['setEmptyCart','unsetLastNameSearch','unsetDataRoom']),
    ...mapMutations('bookings',['resetToInitialState']),

    async _retryPayment(){                  
      await this.sendInfoClient(this.cart.cliente)
    },
    async _retryPaymentLink(){
      window.location.href = this.urlBase +'payment/link/?order='+this.splitedReferencia[2]+'&link=1'
    },
    splitText(){        
      this.splitedReferencia = this.referencia.split("-")      
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
