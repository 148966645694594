import Vue from 'vue'
import { stringAleatorio, toJson, showAlertMessage, consoleMsgFinally} from './../../helpers/helpers'
import store from '../../store' //para acceder a state para isLogged
// import { handlerErrors } from './../../helpers/helpers'

export async function fetchBookings({ commit }, datos) {
    const { key } = datos 
    try {                
        commit('bookings/setLoadingBookings', true, { root: true }) //end loader        
        const response = await Vue.axios({
            method:'POST',       
            url: `/bookAvailable/`,
            data: datos
        })        
        const respuesta = response && response.data
        const { status, isPromo } = respuesta              
        if( status ){
            let payload = JSON.parse(JSON.stringify( respuesta.data )) 
            if( payload.length > 0 ){
                for (let i = 0; i < payload.length; i++) {                    
                    payload[i].keyRoom = stringAleatorio()  
                    payload[i].showingRates = true
                    payload[i].detailSelected =  payload[i].rates[0] || [] 
                    payload[i].detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }
                    //agrego campo priceMatchTax                              
                    for (let j = 0; j < payload[i].rates.length; j++) {                        
                        const esPromo = isPromo ? isPromo : false
                        payload[i].rates[j].priceInit = payload[i].rates[j].AmountAfterTax
                        payload[i].rates[j].divisa = { currencyid: 1, code: "USD", value: "1.00" }           
                        payload[i].rates[j].newManualPrice = ''                      
                        payload[i].rates[j].newManualPriceInit = 0                      
                        payload[i].rates[j].priceMatchTax = '' 
                        payload[i].rates[j].allowPriceMatch = true 
                        payload[i].rates[j].hasMadePriceMatch = false 
                        payload[i].rates[j].isPromo = esPromo                        
                    }
                }                 
            } else {
                payload = []                
            }
            const data = { key, payload }
            // console.log(JSON.parse(JSON.stringify( payload )) )           
            commit('bookings/setBookings', data, { root: true })                                     
        } else {
            const payload = []
            const data = { key, payload}
            commit('bookings/setBookings', data, { root: true })                                      
        }
                
    } catch (error) {
        console.log(error)  
        commit('bookings/setErrorBookingDingus', 'a ocurrido un error en dingus, intenta de nuevo', { root: true })
    } finally {
        commit('bookings/setLoadingBookings', false, { root: true }) //end loader        
        consoleMsgFinally('bookings/fetchBookings','La petición para obtener datos para booking ha terminado')
    }
}

export async function fetchPriceMatchBooking({ commit }, datos) {

    const { key, keyRoom, priceInit, manualRate, tarifa, roomCode,
        indice, precioManualPrevio, dataPromoCode, allowPriceMatch } = datos     
        // dataPromoCode
    const previousRooms = store.state.bookings.tabs.find( item => item.key === key ).dingusBookings
    
        try { 
        commit('bookings/setLoadingProcess', true, { root: true }) //init loader     
        const dataShowRate = {key, indice, boolean: false} 
        commit('bookings/showRatesRoom', dataShowRate, { root: true })                                 
        const response = await Vue.axios({
            method:'POST',       
            url: `/bookAvailable/`,
            data: datos
        })        
        const respuesta = response && response.data    
        const { status, message, isPromo } = respuesta                  
        if( status ){
            let payload = JSON.parse(JSON.stringify( respuesta.data ))             
            if( payload.length > 0 ){                
                for (let i = 0; i < payload.length; i++) {                                                       
                    payload[i].detailSelected =  payload[i].rates[0] || [] 
                    payload[i].detailSelected.newManualPriceInit = manualRate
                    payload[i].detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }      
                    payload[i].keyRoom = stringAleatorio()  
                    payload[i].showingRates = true                                        
                    //agrego campo priceMatchTax                              
                    for (let j = 0; j < payload[i].rates.length; j++) {
                        // const boolPriceMatch = !message ? false : true
                        const boolHasMadePriceMatch= !message ? true : false
                        const priceMatchTax = !message ? payload[i].rates[j].AmountAfterTax : "0.00"
                        const RatePlanCode = payload[i].rates[j].RatePlanCode 
                        const RoomTypeCode = payload[i].rates[j].RoomTypeCode 
                        const newPrice =(RatePlanCode == tarifa && RoomTypeCode == roomCode)?manualRate:''
                        const esPromo = isPromo ? isPromo : false
                        payload[i].rates[j].divisa = { currencyid: 1, code: "USD", value: "1.00" }           
                        payload[i].rates[j].priceMatchTax = priceMatchTax  
                        payload[i].rates[j].priceInit = priceInit  
                        payload[i].rates[j].newManualPrice = newPrice
                        payload[i].rates[j].newManualPriceInit = manualRate 
                        payload[i].rates[j].allowPriceMatch = allowPriceMatch 
                        payload[i].rates[j].hasMadePriceMatch = boolHasMadePriceMatch 
                        payload[i].rates[j].isPromo = esPromo                                           
                    }
                }
            }                         
            const DataRooms = await makePayloadRate( { key, keyRoom, newRoom: payload[0], previousRooms, manualRate, pmp: parseFloat(precioManualPrevio)} )
            const newData = { key, payload: DataRooms } 
            await commit('bookings/setBookings', newData, { root: true }) 
            if(message){
                showAlertMessage( 'Proceso terminado', 'InfoIcon', `😪😪 ${message}`, 'warning', 4000, 'bottom-right')  
            }
            if( dataPromoCode ){
                const data = { key, indice, promotionCode:dataPromoCode}            
                await commit('bookings/setPromotionCodeDetailSelected', data, { root: true }) 
            }        
        }                 
    } catch (error) {
        if(error.response){

            if( error.response.statusText == 'Bad Request' ){                                
                showAlertMessage( 'Promoción excede', 'InfoIcon', `😪😪 ${error.response.data}`, 'danger', 4000, 'bottom-right')
            }
        }    
    } finally {    
        commit('bookings/setLoadingProcess', false, { root: true }) //end loader 
        const dataShowRate = {key, indice, boolean: true}         
        commit('bookings/showRatesRoom', dataShowRate, { root: true })                  
        consoleMsgFinally('bookings/fetchPriceMatchBooking','La petición para Price match ha terminado')
    }
}

export function makePayloadRate(dataRate){
    const { previousRooms, newRoom, keyRoom } = dataRate        
    const room = previousRooms.find( room => room.keyRoom === keyRoom ) 
    room.rates = newRoom.rates    
    room.detailSelected = newRoom.detailSelected    
    return toJson(previousRooms)
}

export async function fetchListRooms({ commit }, datos) {
    const { key } = datos    
    try {
        const info = { value: '', RoomTypeName: 'Buscando habitación' } 
        commit('bookings/setListRooms', { key, info }, { root: true })
        const response = await Vue.axios({
            method:'POST',       
            url: `/bookInfo/`,
            data: datos
        })
        const respuesta = response && response.data
        const { status } = respuesta      
        if( status ){
            const info = JSON.parse(JSON.stringify( respuesta.data )) 
            const datos = { key, info }
            commit('bookings/setListRooms', datos, { root: true })                             
        }                      
    } catch (error) {
        console.log(error)
    } finally {           
        consoleMsgFinally('bookings/fetchListRooms','La petición para obtener las habitaciones disponibles ha terminado')
    }
}

export async function sendBookingDingus({ commit }, datos) {
    try {                
        commit('bookings/setLoadingBookings', true, { root: true }) //end loader        
        const response = await Vue.axios({
            method:'POST',       
            url: `/bookReservations/`,
            data: datos
        })
        console.log(JSON.parse(JSON.stringify(response.data)))
        // const respuesta = response && response.data
        // const { status } = respuesta        
        // if( status ){
        //     var payload = JSON.parse(JSON.stringify( respuesta.data ))

        //     commit('bookings/setBookings', payload, { root: true })                           

        // } else {
        //     commit('bookings/setBookings', [], { root: true })                       
        // }

    } catch (error) {
        // const errorMsg = handlerErrors(error.message, error.response)
        console.log(error)  
        commit('bookings/setErrorBookingDingus', 'a ocurrido un error en dingus, intenta de nuevo', { root: true }) 
        // commit('cartError', errorMsg)
    } finally {
        commit('bookings/setLoadingBookings', false, { root: true }) //end loader                
        consoleMsgFinally('bookings/sendBookingDingus','La petición para obtener los datos del guest ha terminado')
    }
}

export async function checkUserAuthorizeRate({ commit }, datos) {
    const { key, username, code, canalVenta} = datos    
    try {
        const response = await Vue.axios({    
            url: `/authlogin/`,
            params: {
                username, canalVenta
            }
        })
        const respuesta = response && response.data
        const { status } = respuesta      
        if( status ){
            const { id } = respuesta
            const payload = { key, code, idUser:id, isInvalid:false, username }            
            commit('bookings/setDataUserAuthorization', payload, { root: true })  
			showAlertMessage( 'Usuario encontrado', 'InfoIcon', '🎉🎉 El usuario sí es válido', 'success', 4000, 'bottom-right')
                  
        } else {  
            const payload = { key, code, idUser:null, isInvalid:true, username }
            commit('bookings/setDataUserAuthorization', payload, { root: true })   
            showAlertMessage( 'No existe usuario, datos incorrectos', 'InfoIcon', '😪😪 Usuario incorrecto, o el usuario no existe', 'danger', 4000, 'bottom-right')            
        }                     
    } catch (error) {
        console.log(error)        
    } finally {   
        consoleMsgFinally('bookings/checkUserAuthorizeRate','La petición para verificar usuario si es valido finalizado')
    }
}


export async function authorizeRate({commit},datos) {
    const { username, file, key } = datos 
    // console.log(datos, file )     
    try {              
        commit('bookings/setLoadingProcess', true, { root: true }) //init loader 
        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(datos))        
        // console.log(formData)
        const response = await Vue.axios({   
            method:'POST',       
            url: `/authlogin/`, 
            data: formData
        })
        const respuesta = response && response.data
        const { status, urlAuthorization } = respuesta         
        if( status ){
            showAlertMessage(
                'Autorización realizada', 'InfoIcon',
                '🎉🎉La autorización ha sido exitoso',
                'success', 4000, 'bottom-right'
            )
            const dataUrl = {key, url: urlAuthorization} 
            commit('bookings/setUserAuthorizationUrl', dataUrl, { root: true })         
        }                    
    } catch (error) {        
        if(error.response){
            if( error.response.statusText == 'Unauthorized' ){
                showAlertMessage( 'Código incorrecto', 'InfoIcon', `😪😪 El código para su usuario ${username} es incorrecto`, 'danger', 4000, 'bottom-right')                 
                commit('bookings/unsetDataUserAuthorization', datos, { root: true })         
            }
            if( error.response.statusText == 'Bad Request' ){                                
                showAlertMessage( 'Bad Request', 'InfoIcon', `😪😪 Petición invalida`, 'danger', 4000, 'bottom-right')
                commit('bookings/unsetDataUserAuthorization', datos, { root: true })                
            }
        }    
    } finally {    
        commit('bookings/setLoadingProcess', false, { root: true }) //init loader         
        consoleMsgFinally('bookings/authorizeRate','La petición para autorizar con código ha finalizado')
    }
}

