import store from '../store/index'
import router from './../router'
import { find } from 'lodash'
import Vue from 'vue'
import * as moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


const handlerErrors = (errorMessage, errorResponse) => {
    let stringError = ''
    const responseStatus = errorResponse.status || false
    const responseStatusText = errorResponse.statusText || false
    const responseStatusDataDetail = errorResponse.data.detail || false

    stringError += '<strong>Error message:</strong> ' + errorMessage + '<br>'

    if (responseStatus) {
        stringError += '<strong>Error code:</strong> ' + responseStatus + '<br>'
    }

    if (responseStatusText) {
        stringError += '<strong>Status text:</strong> ' + responseStatusText + '<br>'
    }

    if (responseStatusDataDetail) {
        stringError += '<strong>Detail:</strong> ' + responseStatusDataDetail + '<br>'
    }

    return stringError
}

const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}

const formatDate = (fecha, locale) => {

    const date = new Date(fecha + 'T00:00:00')

    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = date.getMonth()
    const yyy = date.getFullYear()

    const stringEs = dia + '  ' + mesesSpanish[mes] + ' ' + yyy
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}


const currentDateSecondsAndExpires = () => {
    const date = new Date()
    const seconds = date.getTime() / 1000
    return { currenTime: Math.trunc(seconds), expiresIn: Math.trunc(seconds) + 900 }
}

const sessionHasExpired = (expiration) => {
    const date = new Date()
    const currentSeconds = Math.trunc(date.getTime() / 1000)
    return currentSeconds > expiration ? true : false
}

const updateExpiresTimeUserOrLogout = (expiresTime) => {
    const { currenTime } = currentDateSecondsAndExpires()
    if (!sessionHasExpired(expiresTime)) {
        store.commit('auth/updateExpiresTime', currenTime, { root: true })
        return true
    } else {
        store.commit('auth/logout', {}, { root: true })
        store.commit('auth/setSesionExpired', true, { root: true })
        router.push('/login')
        return false
    }
}

const validateDataInCart = (cart) => {
    const itemFind = find(cart, { requiredFieldsFilled: false })
    if (itemFind) {
        Vue.$toast({
            component: ToastificationContent,
            props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: `💡 Existe por lo menos algún elemento sin completar sus datos, asegurese de completar esa información: ${itemFind.detaildisplay}.`,
                variant: 'danger',
            },
        }, {
            position: 'bottom-right',
            timeout: 4000,
        })
        return true
    } else {
        return false
    }
}

const checkItemsCartSihotCode = (cart ) => {
    let resultados = cart.filter(item => item.serviciosihot == null)
    if (resultados.length > 0 ) {
        store.commit('shop/setMakeRoomCharge', true, { root: true })
        store.commit('shop/setManualRoomChargeCliente', true, { root: true })
        return { ok: true, objetos: resultados }
    } else {
        store.commit('shop/setMakeRoomCharge', false, { root: true })
        store.commit('shop/setManualRoomChargeCliente', false, { root: true })
        return { ok: false, objetos: null }
    }

}
const findItemInSelectedorder = (order, id) => {
    return order.find(order => order.id === id)
}
const formatDetailsBEOOrder = (detalles, id, infobeo) => {
    detalles.forEach((detalle) => {
        delete detalle.infobeo
        detalle.ordersdetail = id
        detalle.infobeo = infobeo
        detalle.departamento = detalle.departamentoid,
            delete detalle.departamentoid
        delete detalle.departamentoname

    })
    return detalles
}
const transfersIsValid = (detailOrder) => {
    let valiTranfers = detailOrder.filter(item => item.coupon != '')
    let resultados = valiTranfers.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '')
    console.log(resultados)
    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}

const transfersIsValidNull = (detailOrder) => {
    let transfersIsValidNull = detailOrder
    let resultados = transfersIsValidNull.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '' || item.itinerary.flighttime == '' || item.itinerary.ito == '' || item.itinerary.ifrom == '')
    console.log(resultados)
    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}


const yearsMinMax = () => {
    const yearMin = moment().subtract(5, 'years').year()
    const yearMax = moment().add(5, 'years').year()
    return { yearMin, yearMax }
}

const stringAleatorio = () => {
    const now = new Date()
    const seconds = now.getSeconds()
    const miliseconds = now.getMilliseconds()
    return seconds + Math.random().toString(36).substring(2, 9) + miliseconds
}
const validUserLogin = () => {
    /*
    Si usuario está logueado, tiene canal de venta y punto de venta
    */
    const isLoggedUser = store.state.auth.isLogged
    const hasSalesChannel = store.state.shop.canalventa
    const hasPointOfSales = store.state.shop.puntoventa
    // console.log({isLoggedUser, hasSalesChannel, hasPointOfSales})
    if (isLoggedUser && hasSalesChannel && hasPointOfSales) {        
        return true
    } else {        
        return false
    }
}

const camposRequeridosPorLLenar = (product) => {
    let camposFaltantes = []
    const { categoryName, itinerario, operationdate, NamePrefix, GivenName, Surname, PhoneNumber, Email, term } = product
    if (categoryName == "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
        if (!itinerario.ifrom || itinerario.ifrom == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "From" })
        }
        if (!itinerario.ito || itinerario.ito == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "To" })
        }
        if (!itinerario.pax || itinerario.pax == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Pax" })
        }
        if (!itinerario.trip || itinerario.trip == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Service type" })
        }
    }
    if (categoryName == "Flights") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
        if (!itinerario.ifrom || itinerario.ifrom == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "From" })

        }
        if (!itinerario.ito || itinerario.ito == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "To" })
        }
        if (!itinerario.trip || itinerario.trip == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Type of service" })
        }
    }

    if (categoryName == "Reservas") {
        if (!NamePrefix || NamePrefix == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Prefix" })
        }
        if (!GivenName || GivenName == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Name" })

        }
        if (!Surname || Surname == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Lastname" })
        }
        if (!PhoneNumber || PhoneNumber == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Phone" })
        }
        if (!Email || Email == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Email" })
        }
        if (!term || term == null ) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Term and condition" })
        }
    }

    if (product.categoryName != "Transfer" && product.categoryName != "Flights" && product.categoryName != "Reservas") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
    }
    return camposFaltantes
}
const toDecimal = (stringToDecimal) => {
    return String((Math.round(stringToDecimal * 100) / 100).toFixed(2))
}

const toJson = (stringToJson) => {
    return JSON.parse(JSON.stringify(stringToJson))
}

const showAlertMessage = (title = 'Ok', icon = 'BellIcon', text, variant = 'success', timeout = '3000', position = 'bottom-right') => {
    Vue.$toast({
        component: ToastificationContent,
        props: { title, icon, text, variant },
    }, { timeout, position })
}

const consoleMsgFinally = (action, message) => {
    if( process.env.NODE_ENV == 'development'){
        console.log(`[${action}] ${message}`)
    }
}

const getUrlParamAsObject = (url = window.location.href) => {
    let searchParams = url.split('?')[1];
    const result = {};
    //in case the queryString is empty
    if (searchParams!==undefined) {
        const paramParts = searchParams.split('&');
        for(let part of paramParts) {
            let paramValuePair = part.split('=');
            //exclude the case when the param has no value
            if(paramValuePair.length===2) {
                result[paramValuePair[0]] = decodeURIComponent(paramValuePair[1]);
            }
        }

    }
    return result;
}
export {
    validUserLogin,
    handlerErrors,
    currentDate,
    formatDate,
    currentDateSecondsAndExpires,
    sessionHasExpired,
    updateExpiresTimeUserOrLogout,
    validateDataInCart,
    findItemInSelectedorder,
    formatDetailsBEOOrder,
    transfersIsValid,
    transfersIsValidNull,
    checkItemsCartSihotCode,
    yearsMinMax,
    stringAleatorio,
    camposRequeridosPorLLenar,
    toDecimal,
    toJson,
    showAlertMessage,
    consoleMsgFinally,
    getUrlParamAsObject
}