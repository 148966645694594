import { find, filter } from 'lodash'
import { stringAleatorio, showAlertMessage } from '../../helpers/helpers'
import * as moment from 'moment'


export function addProductInCart(state, product) {
    // con lodash busco si en el cart no se repite en base a su detailId y tipo
    const prodInCart = find(state.cart.items, { detailId: product.detailId, modelType: product.modelType })
    const productClone = Object.assign({}, product)
    removeKeysProduct(productClone)
    setPropertiesProduct(productClone, state)

    if (!prodInCart) {
        state.cart.items.push(productClone)
        showAlertMessage(
            'Product added', 'CheckCircleIcon',
            `🎉🎉 Product has been added: ${product.detaildisplay}. `,
            'success', 5000, 'bottom-right'
        )
    }

    if (prodInCart) {
        if (prodInCart.categoryName == "Transfer") {
            state.cart.items.push(productClone)
            showAlertMessage(
                'Transfer added', 'CheckCircleIcon',
                `🎉🎉 Transfer ${product.detaildisplay} has been added to cart. `,
                'success', 5000, 'bottom-right'
            )
        } else {
            prodInCart.qty += 1
            showAlertMessage(
                'Increased amount of', 'PlusIcon',
                `🎉🎉 Increased amount of: ${product.detaildisplay}. `,
                'success', 5000, 'bottom-right'
            )
        }
    }
    calculateTotal(state)
    setIfRequiredFieldsFilled(state)
}

export function addBookingInCart(state, data) {
    setPropertiesBooking(data)
    removeKeysBooking(data)
    const itemClone = Object.assign({}, data)
    state.cart.items.push(itemClone)
    showAlertMessage(
        'Added reservation', 'BellIcon',
        `🎉🎉 Added reservation, room ${data.detaildisplay}. `,
        'success', 5000, 'bottom-right'
    )
    calculateTotal(state)
    setIfRequiredFieldsFilled(state)
}

export function removeKeysBooking(item) {
    delete item.dataSearch
    delete item.AmountAfterTax
    delete item.AmountBeforeTax
    delete item.CurrencyCode
    delete item.MealPlanDescriptionCustom
    delete item.MinimumMarkup
    delete item.PromotionName
    delete item.RatePlanCode
    delete item.RatePlanID
    delete item.ReasonNotAvailable
    delete item.RoomRateDescription
    delete item.RoomTypeCode
    delete item.Taxes
    delete item.TotalOffersApplied
    delete item.newManualPrice
    delete item.newManualPriceInit
    delete item.priceMatchTax
    delete item.divisa
    delete item.allowPriceMatch
    delete item.hasMadePriceMatch
}

export function setPropertiesBooking(item) {
    item.salepriceWithDiscount = item.AmountAfterTax
    item.originalSalePrice = item.AmountAfterTax
    item.qty = 1
    item.cost = "0.00"
    item.vendor = item.dataSearch.vendor
    item.idioma = item.dataSearch.language ? item.dataSearch.language : 'en'
    item.roomCode = item.RoomTypeCode
    item.dateIn = item.dataSearch.dateIn
    item.dateOut = item.dataSearch.dateOut
    item.adultos = item.dataSearch.adults
    item.ninosTotal = item.dataSearch.numberChildren
    item.nightsTotal = []
    item.nightsNum = 1
    item.firstPay = item.AmountAfterTax
    item.nightsTotalAmount = 0,
        item.partial = 0, // valor para saber si aplica pago parcial
        item.ninos = item.dataSearch.ninos
        // item.promotionCode = item.dataSearch.promocode
        // item.promoCode = item.promoCode
    item.typeDiscount = 'Sin descuento'
    item.note = '',
    item.clientnote = '',    
    item.discountApplied = 0
    item.requiredFieldsFilled = false //campos requeridos llenos
    item.folio = stringAleatorio()
    item.fechaDeCreacion = moment(new Date()).format('YYYY-MM-DD')
    item.NamePrefix = 'Mr'
    item.GivenName = ''
    item.Surname = ''
    item.PhoneNumber = ''
    item.Email = ''
    item.orderdetailbeo = {
            titulo: '',
            backup: null,
            locacion: null,
            comentario: '',
            pax: 0,
            numreserva: '',
            detailbeo: []
        }
        // MealPlanCodes
        // RatePlanCode
}

export function removeKeysProduct(product) {
    //remuevo keys que no requiero del producto
    delete product.category
        // delete product.coverImage
    delete product.detail
    delete product.currencyCode
    delete product.type
    delete product.gallery
    delete product.videolink
    delete product.weblink
    delete product.productinfo
    delete product.terms
    delete product.servicesihot
    delete product.currency
    delete product.hotel
    delete product.metatag
    delete product.location
    delete product.startDate
    delete product.startTime
    delete product.endDate
    delete product.endDateFormat
    delete product.endTimeFormat
    delete product.hotelName
    delete product.locationName
    delete product.startDateFormat
    delete product.startTimeFormat
}

export function setPropertiesProduct(product, state) {
    product.folio = stringAleatorio() //les genero string aleatorios
    product.salepriceWithDiscount = product.saleprice
    product.originalSalePrice = product.saleprice
    product.qty = 1
    product.cost = product.cost || "0.00"
    product.itinerario = {}
    product.orderdetailbeo = {
        titulo: '',
        backup: null,
        locacion: null,
        comentario: '',
        pax: 0,
        numreserva: '',
        detailbeo: []
    }
    product.discountApplied = 0
    product.operationdate = state.cart.cliente.date
    product.operationtime = '12:00'
    product.note = ''
    product.clientnote = '',
    product.typeDiscount = 'Sin descuento'
    product.orderextraFields = []
    product.requiredFieldsFilled = false
    if (product.categoryName == "Transfer") {
        product.itinerario.trip = 'Arrival'
    }
}

export function calculateTotal(state) {
    let sum = 0
    let sumProduc = 0
    let sumNight = 0
    const decimalDiscount = parseFloat(state.cart.totalBreakdown.discountApplied) / 100;
    state.cart.items.forEach((product) => {
        let precio = (product.discountApplied > 0) ? product.salepriceWithDiscount : product.saleprice
        sum += (parseFloat(precio) * parseFloat(product.qty))
        if (product.modelType == 'booking' && product.partial == 1) {
            sumNight += (parseFloat(product.firstPay))
        } else {
            product.firstPay = 0;
            sumProduc += (parseFloat(precio) * parseFloat(product.qty))
        }


    })
    const descuento = sum * decimalDiscount
    state.cart.firstPay = sumNight
    state.cart.totalBreakdown.total = ((Math.round(sum * 100) / 100).toFixed(2)) - descuento
    state.cart.firstPayTotal = ((Math.round(sumProduc * 100) / 100).toFixed(2)) - descuento    
}

export function calculateTotalNights(state, nights) {

    var numNigths = []
    for (let i = 0, len = nights.nights; i < len; i++) {
        numNigths.push({ num: i + 1 })
    }

    nights.nightsTotal = numNigths
}

export function setIfRequiredFieldsFilled(state) {
    state.cart.items.forEach((item) => {
        item.requiredFieldsFilled = false
        if (item.categoryName == "Transfer") {
            if (
                item.operationdate && item.operationdate != '' &&
                item.itinerario.ifrom && item.itinerario.ifrom != '' &&
                item.itinerario.ito && item.itinerario.ito != '' &&
                item.itinerario.pax && item.itinerario.pax != '' &&
                item.itinerario.trip && item.itinerario.trip != ''
            ) {
                item.requiredFieldsFilled = true
            }

            if (item.extrapax != false) {
                item.requiredFieldsFilled = true
            }
        }

        if (item.categoryName == "Flights") {
            if (
                item.operationdate && item.operationdate != '' &&
                item.itinerario.ifrom && item.itinerario.ifrom != '' &&
                item.itinerario.ito && item.itinerario.ito != '' &&
                item.itinerario.trip && item.itinerario.trip != ''
            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName == "Reservas") {
            if (
                item.NamePrefix && item.NamePrefix != '' &&
                item.GivenName && item.GivenName != '' &&
                item.Surname && item.Surname != '' &&
                item.PhoneNumber && item.PhoneNumber != '' &&
                item.Email && item.Email != '' &&
                item.term && item.term != null

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName != "Transfer" && item.categoryName != "Flights" && item.categoryName != "Reservas") {
            if (item.operationdate && item.operationdate != '') {
                item.requiredFieldsFilled = true
            }
        }
    })
}

export function removeProductFromCart(state, product) {
    if (state.cart.items.length === 1) {
        state.cart.totalBreakdown.discountApplied = 0
    }
    state.cart.items = filter(state.cart.items, ({ folio }) => folio !== product.folio)
    calculateTotal(state)
    setIfRequiredFieldsFilled(state)
}
export function setLoadingProcess(state, bool) {
    state.isLoadingProcess = bool
}

//seteo del canal de venta
export function setCanalVenta(state, canalVenta) {
    state.canalventa = canalVenta
}
//limpio el canal de venta
export function unsetCanalVenta(state) {
    state.canalventa = null
}
//seteo del punto de venta
export function setPuntoDeVenta(state, puntoDeVenta) {
    state.puntoventa = puntoDeVenta
}
//limpio el punto de venta
export function unsetPuntoDeVenta(state) {
    state.puntoventa = null
}
//pongo el currency default para el client
export function setCurrency(state, currency) {
    state.cart.cliente.currency = currency
}

// para itinerario
export function setItinerary(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.itinerario = product.itinerario
    setIfRequiredFieldsFilled(state)
}
//para estados BEO en modal edit item cart
export function setDetalleinfoBEO(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.orderdetailbeo.detailbeo.push(product.extraField)
}
export function deleteFieldBEO(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.orderdetailbeo.detailbeo = filter(item.orderdetailbeo.detailbeo, ({ key }) => key !== product.fieldToDelete.key)
}

export function setNoteItem(state, data) {
    const { key, notaInterna, notaCliente } = data
    let item = state.cart.items.find(item => item.folio === key)
    item.note = notaInterna
    item.clientnote = notaCliente
    setIfRequiredFieldsFilled(state)
}

export function setDataProductItem(state, data) {
    const { folio, cost, saleprice, originalSalePrice, salepriceWithDiscount, operationdate, operationtime, note, clientnote, itinerario, extraField, orderdetailbeo, typeDiscount } = data
    let item = state.cart.items.find(item => item.folio === folio)

    item.cost = cost
    item.saleprice = saleprice
    item.originalSalePrice = originalSalePrice
    item.salepriceWithDiscount = salepriceWithDiscount

    item.operationdate = operationdate
    item.operationtime = operationtime
    item.note = note
    item.clientnote = clientnote
        //Para itinerario se pone el objeto directamente
    item.itinerario = itinerario
        //para indicar el descuento
    item.typeDiscount = typeDiscount
        //encabezados del beo
    item.orderdetailbeo.titulo = orderdetailbeo.titulo
    item.orderdetailbeo.backup = orderdetailbeo.backup
    item.orderdetailbeo.locacion = orderdetailbeo.locacion
    item.orderdetailbeo.comentario = orderdetailbeo.comentario
    item.orderdetailbeo.pax = orderdetailbeo.pax
    item.orderdetailbeo.numreserva = orderdetailbeo.numreserva
    if (extraField) {
        item.orderdetailbeo.detailbeo.push(extraField) //array de detalles BEO
    }
    calculateTotal(state)
    setIfRequiredFieldsFilled(state)
}

export function setDataBookingItem(state, data) {
    const { folio, NamePrefix, GivenName, Surname, PhoneNumber, Email, note, clientnote, term } = data
    let item = state.cart.items.find(item => item.folio === folio)
    item.NamePrefix = NamePrefix
    item.GivenName = GivenName
    item.Surname = Surname
    item.PhoneNumber = PhoneNumber
    item.Email = Email
    item.note = note
    item.clientnote = clientnote
    item.term = term
    setIfRequiredFieldsFilled(state)
}


//para guardar el nombre del cliente del cart
export function setidUserClient(state, idCliente) {
    state.cart.cliente.idUser = idCliente
}
//para guardar el nombre del cliente del cart
export function setInfoClient(state, clientData) {
    state.cart.cliente.name = clientData.name.trim()
    state.cart.cliente.lastname = clientData.lastname.trim()
    state.cart.cliente.email = clientData.email.trim()
    state.cart.cliente.phone = clientData.phone.trim()
    state.cart.cliente.credit = clientData.credit
}

export function setNameClient(state, nameCliente) {
    state.cart.cliente.name = nameCliente.trim()
}

//para guardar el nombre del cliente del cart
export function setNameAgente(state, nameAgent) {
    state.cart.cliente.customeragent = nameAgent.trim()
}
//para indicar el hotel del cliente
export function setHotelCliente(state, hotel) {
    state.cart.cliente.hotel = hotel
    setIfRequiredFieldsFilled(state)
}
//
export function setLanguageCliente(state, language) {
    state.cart.cliente.language = language
    setIfRequiredFieldsFilled(state)
}

//para indicar el hotel del cliente
export function setSihotCodeHotel(state, sihotCode) {
    state.cart.cliente.hotelsihotcode = sihotCode
}
//para guardar el tipo del cliente del cart
export function setTipoCliente(state, tipoCliente) {
    state.cart.cliente.customertype = tipoCliente
}

//para guardar los apellidos del cliente del cart
export function setLastNameClient(state, lastNameCliente) {
    state.cart.cliente.lastname = lastNameCliente
    state.cart.cliente.lastnameSearch = lastNameCliente
}
//para guardar el email del cliente del cart
export function setEmailCliente(state, emailCliente) {
    state.cart.cliente.email = emailCliente.trim()
}
//para guardar el email del cliente del cart
export function unsetEmailCliente(state, emailCliente) {
    state.cart.cliente.email = emailCliente
}

//para guardar el telefono del cliente del cart
export function setPhoneCliente(state, phoneCliente) {
    state.cart.cliente.phone = phoneCliente.trim()
}
//para guardar la forma de pago del cliente en el cart
export function setPaymentMethodClient(state, paymentMethodyCliente) {
    state.cart.cliente.paymentmethod = paymentMethodyCliente
}
export function setReferenceClient(state, referenceCliente) {
    state.cart.cliente.paymentreference = referenceCliente.trim()
}

export function setPaymentLinkClient(state, paymentLinkCliente) {
    state.cart.cliente.paymentlink = paymentLinkCliente.trim()
}

//para poner valor booleano en la propiedad del estado
export function setMakeRoomCharge(state, boolean) {
    state.cart.makeRoomCharge = boolean
}
//booleano checkbox cargo manual en modal
export function unsetMakeRoomCharge(state) {
    state.cart.makeRoomCharge = false
}
//para poner valor booleano en la propiedad applyManualRoomCharge del estado en cliente
export function setManualRoomChargeCliente(state, value) {
    state.cart.cliente.applyManualRoomCharge = value
}
export function unsetManualRoomChargeCliente(state) {
    state.cart.cliente.applyManualRoomCharge = false
}
//se agrega numero de habitación para el cliente
export function setLastNameChargeClient(state, lastname) {
    state.cart.cliente.lastnameSearch = lastname
}
export function unsetLastNameSearch(state) {
    state.cart.cliente.lastnameSearch = ''
}
//se setea los datos de la habitación en guests
export function setDataRoom(state, data) {
    state.guests = data
}
//para setear la reserva y habitacion del cliente
export function setReservaRoomCharge(state, data) {
    state.cart.numreserva = data.numReserva
    state.cart.room = data.room
    state.cart.cliente.paymentreference = data.numReserva
}
// limpio valores asociados al hacer busqueda por apellido en modal cargo habitación
export function unsetDataRoom(state) {
    state.guests = []
    state.cart.numreserva = ''
    state.cart.room = ''
    state.cart.cliente.paymentreference = ''
}
//para poner valor en la propiedad orderCreated del estado
export function setOrderCreated(state, invoice) {
    state.orderCreated = invoice
}
//limpieza del carrito al terminar la venta
export function setEmptyCart(state) {
    state.cart.items = []
    state.cart.cliente.name = ''
    state.cart.cliente.lastname = ''
    state.cart.cliente.lastnameSearch = ''
    state.cart.cliente.customertype = ''
    state.cart.cliente.customeragent = ''
    state.cart.cliente.email = ''
    state.cart.cliente.phone = ''    
    state.cart.cliente.paymentreference = ''
    state.cart.cliente.paymentlink = ''
    state.cart.firstPay = 0.00
    state.cart.firstPayTotal = 0.00
    state.cart.partial = 0;
    state.cart.cliente.paymentmethod = 1
    state.cart.totalBreakdown.total = 0.00
    state.cart.totalBreakdown.discountApplied = 0
    state.bankUrl = null
    state.showBtnCheckout = true
    state.showBtnRetryCheckout = false  
    state.quotation.isQuotation = false  

    delete state.orderCreated
    delete state.cart.cliente.hotel
}
//shop module state errors, check email
export function setErrorCheckEmail(state, errorMessage) {
    state.errors.emailCheck.error = true
    state.errors.emailCheck.message = errorMessage
}
export function unsetErrorCheckEmail(state) {
    state.errors.emailCheck.error = false
    state.errors.emailCheck.message = ''
}
//shop module state errors, check email
export function setErrorRoomCharge(state, errorMessage) {
    state.errors.roomCharge.error = true
    state.errors.roomCharge.message = errorMessage
}
export function unsetErrorRoomCharge(state) {
    state.errors.roomCharge.error = false
    state.errors.roomCharge.message = ''
}
//shop module state errors, check email
export function setErrorInfoOrder(state, errorMessage) {
    state.errors.sendInfoOrder.error = true
    state.errors.sendInfoOrder.message = errorMessage
}
export function unsetErrorInfoOrder(state) {
    state.errors.sendInfoOrder.error = false
    state.errors.sendInfoOrder.message = ''
}
//shop module state errors, retryPayment
export function setErrorRetryPayment(state, errorMessage) {
    state.errors.retryPayment.error = true
    state.errors.retryPayment.message = errorMessage
}
export function unsetErrorRetryPayment(state) {
    state.errors.retryPayment.error = false
    state.errors.retryPayment.message = ''
}

//shop module state errors, sendItemsOrderCart
export function setErrorItemsOrderCart(state, errorMessage) {
    state.errors.sendItemsOrderCart.error = true
    state.errors.sendItemsOrderCart.message = errorMessage
}
export function unsetErrorItemsOrderCart(state) {
    state.errors.sendItemsOrderCart.error = false
    state.errors.sendItemsOrderCart.message = ''
}

export function setDataOrder(state, data) {
    state.dataOrderPay = data
    state.errorMessageApi = ''
}

export function setBankUrl(state, url) {
    state.bankUrl = url
}

export function setShowBtnsCheckout(state, data) {
    const { showRetryPayment, showCheckout } = data 
    state.showBtnCheckout =  showCheckout
    state.showBtnRetryCheckout = showRetryPayment
}

export function setDataClientFromQueryParams(state, data) {
    const { fname, lname, email, phone } = data 
    state.cart.cliente.name = fname || ''
    state.cart.cliente.lastname = lname || ''
    state.cart.cliente.email = email || ''
    state.cart.cliente.phone = phone || ''
}

export function setIdWelcomedQuotation(state, data) {
    const { idWelcome } = data
    state.quotation.idWelcome = idWelcome    
}

export function setIdFarewellQuotation(state, data) {
    const { idFarewellMessage} = data    
    state.quotation.idFarewellMessage = idFarewellMessage
}

export function setIdAmenity(state, idAmenity) {      
    state.quotation.idAmenity = idAmenity
}

export function setValueisQuotation(state, bool) {      
    state.quotation.isQuotation = bool    
}

export function setStatusPayment(state, statusPayment) {  
    state.statusPayment = statusPayment
}

export function setDatesOperationInAllProducts(state, operationDate ) {    
    state.cart.items.forEach((product) => {
         product.operationdate = operationDate
    })  
    setIfRequiredFieldsFilled(state)
}