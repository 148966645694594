<template>
	<div>		
		<b-form-group>            
			<v-select
        label="MealPlanDescriptionCustom"
				:dir="$store.state.uiAppConfig.isRTL ? 'rtl' : 'ltr'"
        :options="options"
        v-model="selected"
        @input="setPlanDetail(selected)"
      >
      </v-select>   
		</b-form-group>  		
	</div>
  
</template>

<script>
import {mapMutations} from 'vuex'
import vSelect from 'vue-select'    

export default {
  props:{
    room: {
      type: Object,
      required: true
    },    
    terms: {
      type: Array,
      required: true
    },
    clave:{
			type:String,
			required:true
		},   
    indice:{
			type:Number,
			required:true
		}      
  }, 
  components:{
    vSelect,
  }, 
  data(){
	return {               
		options: this.room.rates,
		selected: this.room.detailSelected.MealPlanDescriptionCustom,
		politicas: [
			{ value: '', name: 'Demo 1' },
			{ value: '', name: 'Demo 2' },
		],
	}

  },
  methods:{      
    ...mapMutations('bookings',['setDetailSelectedPlan']),            
    setPlanDetail( detailSelected ){
      if( detailSelected ){        
        const plan = JSON.parse(JSON.stringify(detailSelected))
        // console.log(plan)
        const divisa = { currencyid: 1, code: "USD", value: "1.00" }
        const payload = { plan, key: this.clave, indice: this.indice, divisa}
        this.setDetailSelectedPlan(payload)        
      }      
    }
  }
}
</script>
