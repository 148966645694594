import Vue from 'vue'
import Vuex from 'vuex'

import auth from '../modules/auth'
import uiAppConfig from '../modules/uiAppConfig'
import start from '../modules/start'
import products from '../modules/products'
import shop from '../modules/shop'
import bookings from '../modules/bookings'

import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth','shop','bookings','products']
})

export default new Vuex.Store({
  state: { loading: false },  
  mutations:{
    setLoading(state,bool) {
      state.loading = bool
    }
  },
  modules: {      
    auth,      
    products,
    shop,
    start,
    uiAppConfig,
    bookings
  },
  plugins:[vuexLocal.plugin],
  strict: process.env.DEV,
})
