<template>
  <ValidationObserver ref="observer">                                                                        
      <b-row>                                                                                          
        <b-col md="6" v-if="fieldsRequireds.showInputDate">
          <ValidationProvider rules="required" name="date">
            <b-form-group slot-scope="{ valid, errors }" label="Dates">
                <b-form-input
                    type="date"
                    :min="fechaActual"
                    v-model="product.operationdate"   
                    :state="errors[0] ? false : (valid ? true : null)"
                    @input="setDataProduct(product)"
                >
                </b-form-input>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="time">
            <b-form-group slot-scope="{ valid, errors }" label="Time">
                <b-form-input
                    type="time"                                                  
                    v-model="product.operationtime"   
                    :state="errors[0] ? false : (valid ? true : null)"
                    @input="setDataProduct(product)"
                >
                </b-form-input>
            </b-form-group>
          </ValidationProvider>
        </b-col>      

        <b-col md="6" v-if="fieldsRequireds.showInputFrom">
            <ValidationProvider rules="required" name="from">
              <b-form-group slot-scope="{ valid, errors }" prepend="From">
								<label for="hotel">From</label>
                  <b-form-select :state="errors[0] ? false : (valid ? true : null)" v-model="product.itinerario.ifrom" :options="destinations"
                  @change="setDataProduct(product)"
                  ></b-form-select>                        
                  <b-form-invalid-feedback>
                      {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>                
            </ValidationProvider>
        </b-col>

        <b-col md="6" v-if="fieldsRequireds.showInputTo">
          <ValidationProvider rules="required" name="To">
            <b-form-group slot-scope="{ valid, errors }" prepend="To">
              <label for="Destinos">To</label>
                <b-form-select
                :state="errors[0] ? false : (valid ? true : null)" 
                v-model="product.itinerario.ito" :options="destinations"
                @change="setDataProduct(product)"
                >               
                </b-form-select>           
                <b-form-invalid-feedback>
                    {{ errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>                
          </ValidationProvider>
        </b-col>

        <b-col md="6" v-if="fieldsRequireds.showInputPax">
            <ValidationProvider rules="required" name="pax" >
              <b-form-group slot-scope="{ valid, errors }" prepend="Num. Pax">
                <label for="Pax">Num. Pax</label>
                  <b-form-input
                      type="number" placeholder="Enter pax" 
                      v-model="product.itinerario.pax"                         
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                  >
                  </b-form-input>                                                            
                  <b-form-invalid-feedback>
                      {{ errors[0] }} 
                  </b-form-invalid-feedback>
              </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6" v-if="fieldsRequireds.showInputTypeService">
          <ValidationProvider rules="required" name="trip" >
            <b-form-group slot-scope="{ valid, errors }" prepend="Type of service">
              <label for="">Type of service</label>
                <b-form-select
                :state="errors[0] ? false : (valid ? true : null)" 
                v-model="product.itinerario.trip" :options="optionsTrip"
                @change="setDataProduct(product)"
                >                                                                 
                </b-form-select>                
                <b-form-invalid-feedback>
                    {{ errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>                
          </ValidationProvider>
        </b-col>
        
        <b-col md="6">
          <ValidationProvider rules="max:255" name="Note">
            <b-form-group slot-scope="{ valid, errors }" label="Notes( max: 255)">            
              <b-input-group>
                <b-form-textarea
                  v-model="product.clientnote"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Write a note"
                  :maxlength="max"
                  rows="1" 
                  @input="setDataProduct(product)"
                >
                </b-form-textarea>                    
                  <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                </b-input-group>
                <div class="text-center">
                <b-badge variant="success" style="width: 60%; cursor: not-allowed;"> Has {{max - product.clientnote.length}} characters left </b-badge>
                </div>                               
            </b-form-group>
          </ValidationProvider>
        </b-col>             
      </b-row>                                          
    </ValidationObserver>
</template>

<script>

import { mapState, mapMutations} from 'vuex'
import { currentDate } from '@/helpers/helpers' 
import { optionsTrip } from '@/data/data'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },       
  },
  data () {
    return {
      max: 255,                                
      fechaActual: currentDate(),
      optionsTrip,
      desgloce: [
        {
          quntaity: this.product.qty,
          Discount_applied: this.product.discountApplied+'%',
          Precio_venta: this.product.saleprice,
          Total: '$'+this.total(),
        },        
      ],
    }
  },        
  computed:{
    // ...mapState('cart',['product']),
    ...mapState('start',['destinations','airlines']),
    fieldsRequireds(){
      const categoria = this.product.categoryName
      const showInputDate = true
      const showInputFrom = categoria == 'Transfer' || categoria == 'Flights' ? true: false
      const showInputTo = categoria == 'Transfer' || categoria == 'Flights' ? true: false
      const showInputPax = categoria == 'Transfer' ? true: false

      const showInputTypeService = categoria == 'Flights' ? true: false
      return { showInputDate, showInputFrom, showInputTo, showInputPax, showInputTypeService }      
    }
           
                                   
  },
  methods: {
    ...mapMutations('shop',['setDataProductItem']),  
    setDataProduct(item, cost, saleprice){
      if( cost ){
        item.cost = cost        
      }
      if( saleprice ) {
        item.saleprice = saleprice
        item.originalSalePrice = saleprice
        item.salepriceWithDiscount = saleprice         
      }      
      this.setDataProductItem(item)
    },                                                                                                      
    total(){
      return ( (Math.round( this.product.qty * ((this.product.discountApplied > 0 ) ? this.product.salepriceWithDiscount : this.product.saleprice ) * 100) / 100).toFixed(2) )
    },
   
  }
}    
</script>