<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <!-- <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul> -->

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1  d-lg-flex">
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <b-link            
            :to="{ name: 'sales' }" class="nav-link" :class="activeClass('sales')"
          >            
            <span>🗺 Experiences</span>
          </b-link>          
        </li>
        <li class="nav-item" v-if="showMenuBookings ">
          <router-link :to="{ name: 'booking' }" class="nav-link" :class="activeClass('booking')">
                <span>🏖 Booking</span>                
            </router-link>          
        </li>
        <li class="nav-item">
          <b-link            
            :to="{ name: 'checkout' }" class="nav-link" :class="activeClass('checkout')"
          >            
            <span>🛒 Checkout </span>
          </b-link>          
        </li>

        <!-- <li class="nav-item">
          <b-link            
            :to="{ name: 'payment' }" class="nav-link" :class="activeClass('payment')"
          >
          <span>💸 Checkout </span>           
          </b-link>          
        </li> -->
      </ul>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">      
      <!-- <b-nav-item @click="showPhrase">
        <feather-icon
          size="21"
          icon="SmileIcon"
        />
      </b-nav-item> -->
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-lg-block" /> -->
      <!-- <search-bar /> -->
      <cart-dropdown id="cartItemVertical" v-if="$route.name != 'checkout'"/>
      <!-- <notification-dropdown /> -->
      <!-- <user-dropdown /> -->
    </b-navbar-nav>
  </div>
</template>

<script>

import {  mapState } from 'vuex'

// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
// import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
// import UserDropdown from './components/UserDropdown.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {

    // Navbar Components
    // Bookmarks,
    // Locale,
    // SearchBar,
    // DarkToggler,
    CartDropdown,
    // NotificationDropdown,
    // UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },  
  computed:{
    ...mapState('auth',['user','quote']),
    showMenuBookings(){    
      return  this.user?.canalventa?.includebooking || false
    },
  },
  methods:{
    showPhrase(){
      this.$toast({
        component: ToastificationContent,
        props: {
            title: `Por: ${this.quote.author}`,
            icon: 'BellIcon',
            text: `🎉🎉 ${this.quote.phrase} `,
            variant:'success',
        },
      },{
        position:'bottom-right',
        timeout:7000,
      })
    },
    activeClass: function (...names) {
      for (let name of names){
        if (name == this.$route.name)
          return 'item-active'
        }
    }
  }
}
</script>
<style scoped>
  .item-active {
    /* background-color: #eceff1; */
    /* border-radius: 5px; */
    border-bottom: 2px solid black;
  }
  .item-active span {
    color: black;
    font-weight: bold;
  }
</style>
