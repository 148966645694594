<template>
  <div>
    <div v-if="!isloadingProducts">    
      <!-- bloque si vista de productos es grid-view  -->
      <b-row class="my-flex-card" v-if="productos.length && itemView=='grid-view'">
        <b-col md="6" lg="3" v-for="product in productos" :key="product.id">
          <b-card img-top no-body >                    
            <ImgProductVertical :product="product"/>                    
            <b-card-body class="mb-2">
              <NameDetailProductVertical :product="product"/>     


              
              <b-link class="card-text text-justify short-description pb-1 color-success" v-if="product.categoryName == 'Wedding Packages'"
                :to="{ name: 'sales-product-details', params: { slug: 'products-'+ product.id } }"
              > See description
              </b-link>

              <b-card-text  v-else
                class="text-justify short-description"
              >
                <div v-html="product.description"></div>    
                {{product.categoryName}}          
              </b-card-text>
              
              <!-- <ChildProducts :product="product" v-if="product.detail"/> -->
              
              <div v-if="product.modelType == 'event'" class="mb-1">                
                <span><strong>Hotel:</strong> {{ product.hotelName }}</span><br>
                <span><strong>Locación:</strong> {{ product.locationName }}</span><br>
                <span><strong>Fecha inicio:</strong> {{ product.startDateFormat }}</span><br>
                <span><strong>Hora:</strong> {{ product.startTimeFormat  }} - {{ product.endTimeFormat  }}</span>                                                      
              </div>
              
              <strong>Price: ${{product.detailSelected.saleprice}} USD</strong>
            </b-card-body>
              <div>
                <div v-if="existsInCart(product)">                
                  <b-button variant="warning" block :to="{ name: 'checkout' }" >
                    🛒Show in cart
                  </b-button>           
                </div>
                <b-button variant="primary" block tag="a" class="btn-cart" v-else
                  @click="addProductToCart(product)"
                >            
                  <span> 🛒 Add to cart</span>
                </b-button>
              </div>                                  
          </b-card>
        </b-col>    
      </b-row>

      <!-- bloque si vista de productos es list-view  -->
      <div v-if="productos.length && itemView=='list-view'">
        <b-card no-body style="max-width: 100%;" v-for="product in productos" :key="product.id">
          <b-row no-gutters>
            <div class="col-xs-6 col-md-3 col-sm-12 ">
              <ImgProductHorizontal :product="product" style="height:100%"/>                  
            </div>
            <div class="col-xs-6 col-md-6 col-sm-12" style="border-right: 1px solid #e0e0e0;">
              <b-card-body>
                <NameDetailProductHorizontal :product="product"/>
                <!-- <ChildProducts :product="product" v-if="product.detail"/>              -->
                <b-card-text                
                  class="text-justify short-description"
                >              
                  <div v-html="product.description"></div>
                </b-card-text>
                

                <div v-if="product.modelType == 'event'" class="mb-1">                
                  <span><strong>Hotel:</strong> {{ product.hotelName }}</span><br>
                  <span><strong>Locación:</strong> {{ product.locationName }}</span><br>
                  <span><strong>Fecha inicio:</strong> {{ product.startDateFormat }}</span><br>
                  <span><strong>Hora:</strong> {{ product.startTimeFormat  }} - {{ product.endTimeFormat  }}</span>                                                      
                </div>
              </b-card-body>
            </div>
            <div class="col-xs-6 col-md-3 col-sm-12" style="padding:.5rem">
              <div class="mt-2 mb-1">
                <strong>Price: ${{product.detailSelected.saleprice}} USD</strong>
              </div>
              <div>
                <b-button v-if="existsInCart(product)" variant="warning"  :to="{ name: 'checkout' }">
                  🛒Show in cart
                </b-button>           
                <b-button variant="primary"  tag="a" class="btn-cart" v-else
                  @click="addProductToCart(product)"                
                >            
                  <span> 🛒 Add to cart</span>
                </b-button>
              </div>            
            </div>
          </b-row>
        </b-card>
      </div>  
    </div>    
    <!-- <div class="text-center" v-if="isloadingProducts">							
      <b-spinner label="Loading..." variant="success"/><br>
      <strong>Loading products</strong>
		</div>   -->

    <div class="text-center mt-2 mb-2" v-if="isloadingProducts">
      <skeleton-loader-grids/>
    </div>

  </div>   
</template>

<script>
import { mapState, mapGetters, mapMutations} from 'vuex'
// import ChildProducts from '@/components/shop/sales/ChildProducts/ChildProducts'
import ImgProductVertical from '@/components/shop/sales/ImgProductVertical'
import ImgProductHorizontal from '@/components/shop/sales/ImgProductHorizontal'
import NameDetailProductVertical from '@/components/shop/sales/NameDetailProductVertical'
import NameDetailProductHorizontal from '@/components/shop/sales/NameDetailProductHorizontal'
import SkeletonLoaderGrids from '@core/components/skeleton-loader/SkeletonLoaderGrids'


import { find } from 'lodash'
export default {
  props: {
    itemView: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },       
  },
  components:{
    // ChildProducts,
    ImgProductVertical,
    ImgProductHorizontal,
    NameDetailProductVertical,
    NameDetailProductHorizontal,
    SkeletonLoaderGrids
  },         
  computed:{  
    ...mapState('shop',['cart']),    
    ...mapState('products',['filter','isloadingProducts']),    
    ...mapGetters('products',['filteredProducts']), 
    productos(){
      const items = this.filteredProducts
      return items.slice(
        (this.filters.currentPage - 1) * this.filters.perPage,
        this.filters.currentPage * this.filters.perPage
      )
    },
    totalRows() {
      return this.filteredProducts.length
    }    
  },
  methods:{
    ...mapMutations('shop',['addProductInCart']), 
    addProductToCart( item ){
      const product = JSON.parse(JSON.stringify(item))        
      delete product.detail
      delete product.detailSelected.currency
      const { descmax, detaildisplay, extrapax, id, saleprice, servicesihot } = product.detailSelected
      //seteo nuevos atributos utiles para cart
      product.descmax = parseInt(descmax) || 0
      product.detaildisplay = detaildisplay   
      product.extrapax = extrapax
      product.detailId = id
      product.saleprice = saleprice
      product.serviciosihot = servicesihot
      delete product.detailSelected
      this.addProductInCart(product)        
    },
    existsInCart( item ) {  
      const { detailSelected, categoryName}  = item
      //filtro items del cart los que coinciden con el listado del stado productos a nivel de detalle
      const prodInCart = find(this.cart.items, { detailId: detailSelected.id, modelType: item.modelType })    
      return ( prodInCart && categoryName != "Transfer" ) ? true : false      
    },
    isHtmlDescription(description){
      /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(description)
    }
          
  }
}
</script>


<style lang="scss" scoped>

.short-description {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.long-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-flex-card > div > div.card {
    height: calc(100% - 50px);
    margin-bottom: 15px;
}
</style>