<template>
	<b-card-code title="Make reservation">					
		<!-- form -->			
		<validation-observer ref="simpleRules" tag="form">			
			<b-form>			
				<b-row>
					<b-col md="3">
						<validation-provider  name="Hotel" rules="required" >
							<b-form-group slot-scope="{ errors }" label="Choose hotel*">
								<b-form-select 
									:state="errors.length > 0 ? false:null"
									@input="setHotel"
									v-model="searchBookingData.hotel"
								>
									<option value="">Choose hotel</option>                                            
									<option v-for="hotel in filteredHotels" :key="hotel.id" :value="hotel.id">{{hotel.name}}</option>
								</b-form-select>
									<b-form-invalid-feedback>
									{{ errors[0] }}
									</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</b-col>
								
					<b-col md="3">
						<b-form-group>
							<label for="checkInOut">Check in - Check out*</label>
							<flat-pickr
								v-model="searchBookingData.rangeDate"
								class="form-control"
								:config="{ mode: 'range', minDate: 'today'}"
								@input="getNights"
							/>
						</b-form-group>
					</b-col>

					<b-col md="3">
						<b-form-group>
							<label :for="'nights'+clave">Nigths</label>
							<b-form-input :id="'nights'+clave" v-model="searchBookingData.nights"  disabled />
						</b-form-group>

					</b-col>

					<b-col md="3">
						<b-form-group>
							<validation-provider
								name="Adults"
								rules="required"
							>
							<b-form-group slot-scope="{ errors }" label="Adults*">
								<b-form-select 
									v-model="searchBookingData.adults"
									:state="errors.length > 0 ? false:null"
									@input="setDataForm"
								>
									<option v-for="(adulto, index) in adultOptions" :key="index" :value="adulto.value">{{adulto.title}}</option>
								</b-form-select>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
							</validation-provider>
						</b-form-group>
					</b-col>
					
					<b-col md="3">
						<b-form-group>
							<validation-provider
								#default="{ errors }"
								name="Niños"
								rules="required"
							>
							<label for="children">Kids*</label>
							<b-form-select
								v-model="searchBookingData.numberChildren"
								:options="childrenOptions"
								:state="errors.length > 0 ? false:null"
								@input="createArrayNinios"
							></b-form-select>
							<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="3" v-for="(child,index) in searchBookingData.ninos" :key="index">				
						<label :for="clave+'AgeChild'+index">Edad niño {{index+1}}*</label>					
						<b-input-group>
							<b-form-select
								:id="clave+'AgeChild'+index"						
								v-model="searchBookingData.ninos[index].edad"
								@change="setAgeNinio(index, searchBookingData.ninos[index].edad)"
							>
								<option v-for="(edad, index) in ageChildrenOptions" :key="index" :value="edad.value">{{edad.text}} </option>							
							</b-form-select>
							<b-input-group-append>						
								<b-button variant="danger" size="sm" @click="deleteAgeChild(index)">
									<b-icon icon="trash-fill" ></b-icon>
								</b-button>
							</b-input-group-append>
						</b-input-group>				
					</b-col>
									
					<b-col md="3">
						<b-form-group label="Promotional code">						
							<b-form-input v-model="searchBookingData.promoCodeManual"
							@change="setDataForm"
							:disabled="searchBookingData.promoCode != null"/>
						</b-form-group>						
					</b-col>
					
					<b-col cols="12">
						<b-button
							variant="primary"
							type="submit"
							@click.prevent="getBookings"
						> Search
						</b-button>							
						<div class="float-right" v-if="dataDingusBookings.length">							
							<b-button variant="warning" @click.prevent="resetTab">Clear</b-button>
						</div>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</b-card-code>
</template>

<script>
import * as moment from 'moment'
import BCardCode from '@core/components/b-card-code'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapMutations, mapState} from 'vuex'
import { vendorOptions, rateOptions, languageOptions, adultOptions, childrenOptions, ageChildrenOptions } from '@/data/data'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//import { toJson } from '@/helpers/helpers'

export default {
  props: {
	searchBookingData:{
		type:Object,
		required:true
	},
	dataDingusBookings:{
		type:Array,
		required:true
	},
	clave:{
		type:String,
		required:true
	},
	indiceTab:{
		type:Number,
		required:true
	}
  },
  components: {
	BCardCode,
	// vSelect,
	flatPickr
  },
  data() {
    return {
		required,		
		vendorOptions,
		rateOptions,
		languageOptions,		
		adultOptions,
		childrenOptions,
		ageChildrenOptions,
    }
  },

  computed:{
		...mapState('start',['hotels','promoCodes']),
		...mapState('bookings',['isLoadingBookings','tabs']),
		filteredHotels(){
			return this.hotels.filter(hotel => hotel.dingusCode != null) 
		//return this.hotels 
	},
	roomsTab(){
		const item = this.tabs.find( item => item.key === this.clave )
		return item.rooms
	}	
  },

  methods: {
	...mapActions('bookings',['fetchListRooms']),
	...mapMutations('bookings',['setDataSearch','addArrayNinios','setAgeChild','removeAgeChild','resetToInitialStateTab']),	
	...mapMutations('shop',['setHotelCliente']),
	async setHotel(e){
		if(e){
			// const hotel = this.hotels.find( hotel => hotel.id === e)
			this.setDataForm()
			// const payload = {
			// 	hotel : hotel.dingusCode,
			// 	idioma : "en",
			// 	key: this.clave
			// }
			if(this.indiceTab == 0 ){
				this.setHotelCliente(e)				
			}
			// await this.fetchListRooms(payload)
		}
    },
    getBookings() {
		const el = document.getElementById('footer')
		const { rangeDate, dateIn, dateOut, nights } = this.searchBookingData
		el.scrollIntoView({behavior: "smooth"})
		this.$refs.simpleRules.validate().then(success => {
			if (success) {
				if(rangeDate != 0 && dateIn != "" && dateOut != "" && nights > 0 ){
					this.searchBookingData.key = this.clave
					this.$emit('search-bookings', this.searchBookingData)
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: `Fecha: Check in - Check out`,
							icon: 'InfoIcon',
							text: `😰😰 Falta que se indique valor para Check in - Check out`,
							variant:'danger',
						},
					},{
						timeout:3000,
						position: 'bottom-right'
					})
				}
			}
		})
	},	
	resetTab(){
		this.resetToInitialStateTab(this.clave)
		this.$toast({
			component: ToastificationContent,
			props: {
				title: `Datos limpiados`,
				icon: 'BellIcon',
				text: `🎉🎉 Datos de formulario y disponibilidades limpiados`,
				variant:'success',
			},
		},{
			timeout:3000,
			position: 'bottom-right'
		})
		this.$refs.simpleRules.reset()
	},
	ageChild(object){
		return object.edad
	},
	createArrayNinios(numberOfChildren){
		let ageChildrens = []		
		for (let i = 0;  i < numberOfChildren; i++) {
			ageChildrens.push({edad: 1})                       					             
		}
		const payload = { key: this.clave, ageChildrens }
		this.addArrayNinios(payload)
		this.setDataForm()
	},
	setAgeNinio(indice, edad){		
		const payload = { indice, key: this.clave, edad }	
		this.setAgeChild(payload)			
	},
	deleteAgeChild(indice){
		const payload = { indice, key: this.clave }			
		this.removeAgeChild(payload)
	},
	getNights(){
		if( this.searchBookingData.rangeDate.includes("to") ){			
			const splitdate = this.searchBookingData.rangeDate.split(' to ')
			this.searchBookingData.dateIn = splitdate[0] || moment(new Date()).format('YYYY-MM-DD')
			this.searchBookingData.dateOut = splitdate[1] || moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
			const inicio = moment(splitdate[0])
			const fin = moment(splitdate[1])
			this.searchBookingData.nights = ( splitdate[0] && splitdate[1] )? fin.diff(inicio, 'days') : 0
			this.setDataForm() 
		}
	},
	setDataForm(){	
		this.searchBookingData.key = this.clave
		this.setDataSearch(this.searchBookingData)
		if(this.dataDingusBookings.length > 0 ){
			if(this.searchBookingData.promoCode != null || this.searchBookingData.promoCodeManual !=''){
				this.getBookings()						
			}
			if(this.searchBookingData.promoCode == null || this.searchBookingData.promoCodeManual ==''){
				this.getBookings()						
			}						
		}		
	}

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
