//start module state Hotels
export function setHotels(state, hotels) {
    state.hotels = hotels
}
//start module state Categories
export function setCategories(state, categories) {
    state.categories = categories
}

//start module state Categories
export function setCategoriesWeddings(state, categories) {
    // console.log(categories)
    state.categories = categories
}

//start module state Destinations
export function setDestinations(state, destinations){
    state.destinations = destinations   
}

export function setSelectedCategory(state, categoryName){
    state.selectedCategory = categoryName
}  
//start module state Currencies
export function setCurrencies(state, currencies) {
    state.currencies = currencies
}

export function setPromoCodes(state, promoCodes){
    state.promoCodes = promoCodes   
}

export function setLanguages(state, languages){
    state.languages = languages   
}


//start module state departamentos 
export function setDepartaments(state, departaments) {
    state.departaments = departaments
}

export function setCustomerTypes(state, customerTypes) {
    state.customerTypes = customerTypes
}

export function setAirlines(state, airlines) {
    state.airlines = airlines
}

//pongo en el estado la lista de metodo de pago del usuario logueado
export function setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods
}

//Terminos y condiciones 
export function setTerms(state, terms){
    state.terms = terms   
}

export function setErrorHotels(state, errorMessage){
    state.errors.hotels = true
    state.errors.message = errorMessage    
}
export function unsetErrorHotels(state){
    state.errors.hotels = false
    state.errors.message = ''    
}

export function setErrorCurrencies(state, errorMessage){
    state.errors.currencies = true
    state.errors.message = errorMessage
}
export function unsetErrorCurrencies(state) {
    state.errors.currencies = false
    state.errors.message = ''
}

export function setErrorCategories(state, errorMessage){
    state.errors.categories = true
    state.errors.message = errorMessage    
}
export function unsetErrorCategories(state){
    state.errors.categories = false
    state.errors.message = ''    
}

export function setErrorDestinations(state, errorMessage){
    state.errors.destinations = true
    state.errors.message = errorMessage
}
export function unsetErrorDestinations(state) {
    state.errors.destinations = false
    state.errors.message = ''
}

export function setErrorCustomerTypes(state, errorMessage){
    state.errors.customerTypes = true
    state.errors.message = errorMessage
}
export function unsetErrorCustomerTypes(state) {
    state.errors.customerTypes = false
    state.errors.message = ''
}

export function setErrorDepartaments(state, errorMessage){
    state.errors.departaments = true
    state.errors.message = errorMessage    
}
export function unsetErrorDepartaments(state){
    state.errors.departaments = false
    state.errors.message = ''    
}

export function setErrorTerms(state, errorMessage) {
    state.errors.terms = true
    state.errors.message = errorMessage    
}

export function unsetErrorTerms(state, errorMessage){
    state.errors.terms = false
    state.errors.message = errorMessage    
}
export function setErrorAirlines(state, errorMessage){
    state.errors.airlines = true
    state.errors.message = errorMessage    
}
export function unsetErrorAirlines(state){
    state.errors.airlines = false
    state.errors.message = ''    
}

export function setErrorPromoCodes(state, errorMessage){
    state.errors.promoCodes = true
    state.errors.message = errorMessage    
}
export function unsetErrorPromoCodes(state){
    state.errors.promoCodes = false
    state.errors.message = ''    
}

export function setErrorLanguages(state, errorMessage){
    state.errors.languages = true
    state.errors.message = errorMessage    
}
export function unsetErrorLanguages(state){
    state.errors.languages = false
    state.errors.message = ''    
}