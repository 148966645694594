import Vue from 'vue'
import { currentDate, formatDate } from './../../helpers/helpers'
import store from '../../store/index' //para acceder a state

//para peticiones asyncronas para obtener lista de productos best seller
export async function fetchProducts(context) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id 
    let products = []
    try {
        context.commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/products/`,
            params: { bs: 1, idseccanal  }
        })
        const payload = response && response.data
                
        payload.forEach((product) => {
            product.modelType = 'product'
            product.categoryName = product.category.name || ''
            product.detailSelected = product.detail[0] ? product.detail[0] : null
            delete product.gallery
            delete product.videolink
            delete product.weblink
            delete product.type
            delete product.category
            delete product.productinfo
            delete product.detailId

            if(product.detailSelected){
                products.push(product)
            }
        })
        context.commit('products/setProducts', products, { root: true })
    
    } catch (error) {
        console.log(error)
    } finally {
        context.commit('products/setLoadingProducts', false, { root: true })    
        console.log('fetchProducts BS se ha terminado')
    }
}

//peticiones para obtener lista de productos por categorias
export async function fetchProductsByCategories({ commit }, category) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id 
    let products = []
    const { id } = category
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/products/`,
            params: {
                idcat: id, idseccanal
            }
        })
        const payload = response && response.data
            //le agrego el tipo (productos, eventos, paquetes)
        payload.forEach((product) => {
            product.modelType = 'product'
            product.categoryName = product.category.name || ''
            product.detailSelected = product.detail[0] ? product.detail[0] : null            
            delete product.gallery
            delete product.videolink
            delete product.weblink
            delete product.type
            delete product.category
            delete product.productinfo
            if(product.detailSelected){
                products.push(product)
            }
        })
        commit('products/setProducts', products, { root: true })        
    } catch (error) {
        console.log(error)
    } finally {
        commit('products/setLoadingProducts', false, { root: true })    
        console.log('fetchProductsByCategories end')

    }
}


//peticiones para obtener lista de productos por categorias weddings
export async function fetchProductsByCategoriesWeddings({ commit }, category) {
    // const { id } = category
    const idseccanal =  store.state.auth.user?.sectionCanal?.id 
    let products = []
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/products/`,
            params: {
                idsub: category, idseccanal
            }
        })

        const payload = response && response.data    
        //le agrego el tipo (productos, eventos, paquetes)
        payload.forEach((product) => {
            product.modelType = 'product'
            product.categoryName = product.category.name || ''
            product.detailSelected = product.detail[0] ? product.detail[0] : null                        
            delete product.gallery
            delete product.videolink
            delete product.weblink
            delete product.type
            delete product.category
            delete product.productinfo
            if(product.detailSelected){
                products.push(product)
            }
        })
        commit('products/setProducts', products, { root: true })        
    } catch (error) {
        console.log(error)
    } finally {
        commit('products/setLoadingProducts', false, { root: true })    
        console.log('fetchProductsByCategoriesWeddings end')
    }
}


//peticiones para obtener lista de paquetes
export async function fetchPackages({ commit }) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id     
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/packages/`,
            params: {
                idseccanal
            }
        })
        const payload = response && response.data
            //le agrego el tipo (productos, eventos, paquetes)
        payload.forEach((product) => {
            product.modelType = 'package'
            product.categoryName = 'Packages'            
            product.detailSelected = {
                descmax: 0,
                detaildisplay: product.name,
                extrapax: false,
                id: product.id,
                saleprice: product.saleprice,
                servicesihot: product.servicesihot
            }
            delete product.products
            delete product.gallery
            delete product.videolink
            delete product.weblink
            delete product.category
            delete product.productinfo
        })
        commit('products/setProducts', payload, { root: true })
    } catch (error) {
        console.log(error)
    } finally {
        commit('products/setLoadingProducts', false, { root: true })        
        console.log('fetchPackages end')
    }
}
//peticiones para obtener lista de eventos
export async function fetchEvents({ commit }) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id     
    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/events/`,
            params: {
                idseccanal
            }
        })
        const payload = response && response.data

        const todayDate = currentDate()
        const fecha = new Date(todayDate)
            //se filtra los eventos que sean iguales o mayores a la fecha actual
        const result = payload.filter(item => new Date(item.endDate) >= fecha)
        result.forEach((product) => {
            const timeInitFormat = product.startTime.slice(0, -3)
            const timeEndFormat = product.endTime.slice(0, -3)            
            const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
            const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
            product.modelType = 'event'
            product.categoryName = 'Events'
            const detalleIngles = product.eventdetail[1]
            product.detailSelected = {
                descmax: detalleIngles ? parseInt(detalleIngles.descmax) : 0,
                detaildisplay: product.name,
                extrapax: false,
                id: detalleIngles ? detalleIngles.id : product.id,
                saleprice: product.saleprice,
                servicesihot: detalleIngles ? detalleIngles.servicesihot : null
            }
            product.startDateFormat = formatDate(product.startDate, 'en')
            product.endDateFormat = formatDate(product.endDate, 'en')
            product.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
            product.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
            product.currencyCode = product.currency.code
            product.hotelName = product.hotel.name
            product.locationName = product.location.name
            product.description = detalleIngles ? detalleIngles.description : ''
            delete product.eventdetail
            delete product.gallery
            delete product.currency
            delete product.hotel
            delete product.location
            delete product.category
            delete product.productinfo
        })
        commit('products/setProducts', result, { root: true })
    } catch (error) {
        console.log(error)        
    } finally {
        commit('products/setLoadingProducts', false, { root: true })    
        console.log('products/fetchEvents end')

    }
}

export async function fetchProductDetail({ commit }, payload) {
    const idseccanal = store.state.auth.user?.sectionCanal?.id     
    const { idProducto, endPoint } = payload

    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: {
                id: idProducto,
                idseccanal
            }
        })
        const payload = response && response.data
        if (endPoint == 'packages') {
            payload[0].categoryName = 'Packages'
            payload[0].modelType = 'package'
            if (payload[0].gallery) {
                payload[0].gallery.forEach((item) => {
                    item.key = Math.random().toString(36).substring(2, 9)
                })
            }
            if (payload[0].products) {
                payload[0].products.forEach((item) => {
                    item.keyItem = Math.random().toString(36).substring(2, 9)
                })
            }
            payload[0].detailSelected = {
                products: payload[0].products,
                gallery: payload[0].gallery,
                cost: payload[0].cost || 0,
                descmax: 0,
                detailDesc: payload[0].name,
                detaildisplay: payload[0].name,
                extrapax: false,
                id: payload[0].id,
                saleprice: payload[0].saleprice,
                servicesihot: payload[0].servicesihot || null
            }
        }
        if (endPoint == 'products') {
            payload[0].categoryName = 'Products'
            payload[0].modelType = 'product'
            delete payload[0].detail[0].currency
            if (payload[0].detail[0]) {
                payload[0].detailSelected = payload[0].detail[0]
            }
            if (payload[0].category) {
                payload[0].categoryName = payload[0].category.name
            }

        }

        if (endPoint == 'events') {
            delete payload[0].currency
            payload[0].categoryName = 'Events'
            payload[0].modelType = 'event'
            payload[0].hotelName = payload[0].hotel.name
            payload[0].locationName = payload[0].hotel.name
            payload[0].description = payload[0].name
            const timeInitFormat = payload[0].startTime.slice(0, -3)
            const timeEndFormat = payload[0].endTime.slice(0, -3)
            const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
            const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
            payload[0].startDateFormat = formatDate(payload[0].startDate, 'en')
            payload[0].endDateFormat = formatDate(payload[0].endDate, 'en')
            payload[0].startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
            payload[0].endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
            payload[0].detailSelected = {
                cost: payload[0].cost || 0,
                descmax: 0,
                detailDesc: payload[0].name,
                detaildisplay: payload[0].name,
                extrapax: false,
                id: payload[0].id,
                saleprice: payload[0].saleprice,
                servicesihot: payload[0].servicesihot || null
            }
            delete payload[0].hotel
            delete payload[0].endDate
            delete payload[0].endTime
            delete payload[0].startDate
            delete payload[0].startTime
            delete payload[0].location
        }
        delete payload[0].type
        delete payload[0].category
        delete payload[0].active
        delete payload[0].eventdetail
        delete payload[0].productinfo
        delete payload[0].products
        delete payload[0].gallery
        commit('products/setDetailProduct', payload[0], { root: true })
    } catch (error) {
        console.log(error) 
    } finally {
        commit('products/setLoadingProducts', false, { root: true })        
        console.log('products/fetchProductDetail end')
    }
}


export async function fetchproductswebsites({ commit }, payload) {
    const idseccanal = store.state.auth.user?.sectionCanal?.id     

    const { idProducto, endPoint, language } = payload

    try {
        commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: {
                id: idProducto,
                language: language,
                idseccanal
            }
        })
        const payload = response && response.data
        if (endPoint == 'productswebsites') {
            payload[0].categoryName = 'productswebsites'
            payload[0].modelType = 'package'
            if (payload[0].gallery) {
                payload[0].gallery.forEach((item) => {
                    item.key = Math.random().toString(36).substring(2, 9)
                })
            }
            if (payload[0].products) {
                payload[0].products.forEach((item) => {
                    item.keyItem = Math.random().toString(36).substring(2, 9)
                })
            }
            payload[0].detailSelected = {
                products: payload[0].products,
                gallery: payload[0].gallery,
                cost: payload[0].cost || 0,
                descmax: 0,
                detailDesc: payload[0].name,
                detaildisplay: payload[0].name,
                extrapax: false,
                id: payload[0].id,
                saleprice: payload[0].saleprice,
                servicesihot: payload[0].servicesihot || null
            }
        }
        if (endPoint == 'productswebsites') {
            payload[0].categoryName = 'Products'
            payload[0].modelType = 'product'
            delete payload[0].detail[0].currency
            if (payload[0].detail[0]) {
                payload[0].detailSelected = payload[0].detail[0]
            }
            if (payload[0].category) {
                payload[0].categoryName = payload[0].category.name
            }
            if (payload[0].productinfo[0].terms) {
                payload[0].terms = payload[0].productinfo[0].terms
                payload[0].descriptionTerms = payload[0].productinfo[0].description
            }
        }

        if (endPoint == 'eventswebsites') {
            delete payload[0].currency
            payload[0].categoryName = 'Events'
            payload[0].modelType = 'event'
            payload[0].hotelName = payload[0].hotel.name
            payload[0].locationName = payload[0].hotel.name
            payload[0].description = payload[0].name
            const timeInitFormat = payload[0].startTime.slice(0, -3)
            const timeEndFormat = payload[0].endTime.slice(0, -3)
            const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
            const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
            payload[0].startDateFormat = formatDate(payload[0].startDate, 'en')
            payload[0].endDateFormat = formatDate(payload[0].endDate, 'en')
            payload[0].startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
            payload[0].endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
            payload[0].detailSelected = {
                cost: payload[0].cost || 0,
                descmax: 0,
                detailDesc: payload[0].name,
                detaildisplay: payload[0].name,
                extrapax: false,
                id: payload[0].id,
                saleprice: payload[0].saleprice,
                servicesihot: payload[0].servicesihot || null
            }
            if (payload[0].eventdetail[0].terms) {
                payload[0].terms = payload[0].eventdetail[0].terms
                payload[0].descriptionTerms = payload[0].eventdetail[0].description
            }
            delete payload[0].hotel
            delete payload[0].endDate
            delete payload[0].endTime
            delete payload[0].startDate
            delete payload[0].startTime
            delete payload[0].location
        }

        if (endPoint == 'termsWebsites') {
            payload[0].categoryName = 'Booking'
            payload[0].modelType = 'Booking'
            payload[0].coverImage = payload[0].urlimage
            payload[0].name = payload[0].roomtypename
            payload[0].descriptionTerms = '<h4>' + payload[0].termscondition.titulo + '</h4>'
            payload[0].terms = payload[0].termscondition.contenido
            delete payload[0].mealplancode
            delete payload[0].roomtypename
            delete payload[0].urlimage

        }

        delete payload[0].type
        delete payload[0].category
        delete payload[0].active
        delete payload[0].eventdetail
        delete payload[0].productinfo
        delete payload[0].products
        delete payload[0].gallery
        commit('products/setDetailProduct', payload[0], { root: true })
    } catch (error) {
        console.log(error)  
        commit('products/unsetDetailProduct', "", { root: true })
    } finally {
        commit('products/setLoadingProducts', false, { root: true })        
        console.log('products/fetchproductswebsites end')
    }
}