<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">      
      <!-- Brand logo-->
      <BrandLogo/>      
      <!-- contiene la imagen-->
      <SideBrand :imgUrl="imgUrl"/>                  
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">                                    
          <Login :user="usuario" @login-user="login" v-if="!isLogged"/>
          <!-- loader al pedir datos a la api -->
          <div class="text-center mt-2 mb-2" v-if="isLoadingAuth">
            <b-spinner label="Loading..." variant="info" />
          </div>         
          <SelectPOS v-if="isLogged && user && !puntoventa"/>          
        </b-col>
      </b-col>  
    </b-row>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'
import BrandLogo from '@/components/auth/BrandLogo.vue'
import SideBrand from '@/components/auth/SideBrand.vue'
import Login from '@/components/auth/Login'
import SelectPOS from '@/components/auth/SelectPOS'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { appVersion } from '@/data/data'
// import { toJson } from '@/helpers/helpers'



export default {
  components: {    
    BrandLogo,
    SideBrand,
    Login,
    SelectPOS
  },
  mixins: [togglePasswordVisibility],
  mounted(){  
    const { version, date }  = appVersion
    if(localStorage.getItem('localAppVersion')){           
      const lsDataVersion = JSON.parse(localStorage.getItem('localAppVersion'))
      const { localVersion } = lsDataVersion               
      if( version != localVersion){
        localStorage.setItem('localAppVersion', JSON.stringify({localVersion: version, localDate:date})) 
        this.clearDataApp()        
      }
    }

    if(!localStorage.getItem('localAppVersion')){      
      localStorage.setItem('localAppVersion', JSON.stringify({localVersion: version, localDate:date})) 
      this.clearDataApp()        
    }
  },
  data() {
    return {      
      sideImg: require('@/assets/images/login/login-v2-dark.png'),            
      usuario: {
        username:'',
        password:''        
      }
    }
  },
  computed: {
    ...mapState('auth',['isLogged', 'user', 'isLoadingAuth','quote']),
    ...mapState('shop',['canalventa','puntoventa']),        
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.uiAppConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapMutations('auth', ['logout']),
    ...mapMutations('shop',['unsetCanalVenta','unsetPuntoDeVenta','setEmptyCart','unsetLastNameSearch','unsetDataRoom']),
    ...mapMutations('bookings',['resetToInitialState']),
    ...mapMutations('products',['unsetProducts']),
    async login(){           
      await this.signIn( this.usuario ) 
      if( this.isLogged && this.canalventa && this.puntoventa){
        let timeout = 10000
        this.$router.push({name: 'sales'})
        this.$toast({
          component: ToastificationContent,
          props: {
              title: `${this.quote.author}`,
              icon: 'BellIcon',
              text: `🎉🎉 ${this.quote.phrase}`,
              variant:'success',               
          },
          
        },
        {
          position:'bottom-right',
          timeout,
        })
      }     
    },
    createLocalStorageDataVersion(){

    },
    clearDataApp(){
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.resetToInitialState()
      this.unsetProducts()
      localStorage.removeItem('vuex')
      localStorage.removeItem('dataCategoryHotels')
      localStorage.removeItem('dataCategories')
      localStorage.removeItem('dataCategoryServices')
      window.location.reload()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>


