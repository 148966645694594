export default {        
    isLoadingBookings: false,
    isLoadingProcess: false,
    tabSelected: '01default',
    tabs:[
        {
            key: '01default',
            dataSearch: {                                
                vendor: '',                    
                language: null,
                name: '', 
                rangeDate: 0,
                dateIn: '',
                dateOut: '',
                nights: 0,
                hotel: '', 
                room: null,
                adults: 1, 
                numberChildren: 0, 
                ninos:[],                
                promoCode:null,
                promoCodeManual:'',                
                userAuthorizeRate: {
                    isInvalid: true,
                    idUser: null,
                    username: '',
                    code:'',                    
                    urlAuthorization: ''
                }
            },
            rooms:[],
            dingusBookings:[],
        }
    ],     
    errors: {
        bookings: { error:  false, message: ''},
        listRoom: { error: false, message: ''},
    },
}