<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <!-- <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Filtros
            </h6>
          </b-col>
        </b-row> -->
        <!-- Filters' Card -->
        <b-card>
          <!-- {{selectedCategory}} -->        
          <!-- Hotels -->
          <div class="multi-range-price" v-if="showCategoryHotels">
            <h6 class="filter-title mt-0">
            🏩 Hotel services
            </h6>
            <b-spinner label="Loading..." variant="info" v-if="categoryHotels.length == 0"/>
            <b-form-radio-group v-else
              name="cats"            
              v-model="dataCategoryHotel"
              class="categories-radio-group"
              stacked
              :options="categoryHotels"
              @change="getProductsByHotel"
            />       
          </div>
                            
          <!-- categories -->
          <div class="brands" v-if="showCategories">
            <h6 class="filter-title">
              📑 Categories
            </h6>


            <b-spinner label="Loading..." variant="info" v-if="categories.length == 0"/>

            <b-form-radio-group 
              name="cats"
              v-model="dataCategory"
              class="brands-radio-group"
              stacked
              :options="sortCategoriesnosubcat"
              @change="getProducts"
            /> 

            <app-collapse   v-for="cat in sortCategories" :key="cat.value">
              <app-collapse-item class="coll-title" :title="cat.text" v-if="cat.subcategory.length > 0">
                
                <b-form-radio-group
                name="cats"
                v-model="dataCategory"
                class="brands-radio-group"
                stacked
                :options="cat.subcategory"
                @change="getProductsweddings"
                />


              </app-collapse-item>
            </app-collapse>


          </div>

          <!-- services -->
          <div class="brands" v-if="showCategoryServices">
            <h6 class="filter-title">
              📑 Services
            </h6>          
            <b-form-radio-group 
              name="cats"
              v-model="dataCategoryService"
              class="brands-radio-group"
              stacked
              :options="services"
              @change="getProductsByServices"
            />
          </div> 

          <!-- <div class="brands">
            <h6 class="filter-title">
              📑 Servicios
            </h6>          
            
          <b-form-group stacked @change="getProductsByServices">
            <b-form-radio class="brands-radio-group mb-1" v-for="(item, index) in services" :key="index" v-model="dataCategoryService" name="cats" :value="item.text" @change="getProductsByServices">{{item.text}}</b-form-radio>
            {{dataCategoryService}}
          </b-form-group>
          </div>  -->
              
        </b-card>        
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState} from 'vuex'
import _ from 'lodash';
//seeds data
import {categoryHotels, services} from '@/data/data'

//Collapse
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {

  components: {
    AppCollapse,
    AppCollapseItem
  },

  async mounted(){     
    await this.fetchCategoriesWeddings()    
  },
  
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },  
  data() {
    return {
      categoryHotels,
      services
    }
  },
  computed:{
    ...mapState('start',['categories','selectedCategory']),
    ...mapState('auth',['user']),
   
    sortCategories() {
      return _.orderBy(this.categories, 'text', 'desc');
    },

    sortCategoriesnosubcat() {
      let arrayCategory = []
      this.categories.forEach( (cat) => {            
        if(cat.subcategory.length == 0){
          arrayCategory.push(cat)
        }
      })
      return  _.orderBy(arrayCategory, 'text', 'desc');
    },


    showCategoryHotels(){      
      return  this.user?.canalventa?.filterbyhotel || false
    },
    showCategories(){      
      return  this.user?.canalventa?.filterbycategory || false
    },
    showCategoryServices(){
      const showCategory = this.user?.canalventa?.filterbyservices || false
      return  showCategory
    },
    dataCategoryHotel: {
      get(){
        if( JSON.parse(localStorage.getItem('dataCategoryHotels')) ){
          const data = JSON.parse(localStorage.getItem('dataCategoryHotels'))
          return data.value
        }   
        return 0   
      },
      set(value){
        this.removeItemLocalstorage()
        const hoteles = JSON.parse( JSON.stringify( this.categoryHotels ) )      
        let hotel = hoteles.find( hotel => hotel.value === value ) 
        return localStorage.setItem('dataCategoryHotels', JSON.stringify({value, text: hotel.text })) 
      }  
    },


    dataCategory:{
      get(){
        if( JSON.parse(localStorage.getItem('dataCategories')) ){
        const data = JSON.parse(localStorage.getItem('dataCategories'))
        return data.value
      }
        return 0   
      },
      set(value){
        console.log(value)
        this.removeItemLocalstorage()
        // const cats = JSON.parse( JSON.stringify( this.categories ) )      
        // let categoria = cats.find( cat => cat.value === value ) 
        // return localStorage.setItem('dataCategories', JSON.stringify({value, text: categoria.text })) 
      } 
    },


    dataCategoryService:{
      get(){
        if( JSON.parse(localStorage.getItem('dataCategoryServices')) ){
        const data = JSON.parse(localStorage.getItem('dataCategoryServices'))
        return data.value
      }
        return 0  
      },
      set(value){
        this.removeItemLocalstorage()
        const servicios = JSON.parse( JSON.stringify( this.services ) )      
        let servicio = servicios.find( serv => serv.value === value ) 
        return localStorage.setItem('dataCategoryServices', JSON.stringify({value, text: servicio.text })) 
      }
    }
  },
  methods:{
    ...mapActions('start',['fetchCategoriesWeddings']), 
    ...mapActions('products',['fetchProducts','fetchProductsByCategoriesWeddings','fetchProductsByCategories','fetchPackages','fetchEvents']),
    ...mapMutations('start',['setSelectedCategory']),

    async getProductsByHotel( idHotel ){      
      const hoteles = JSON.parse( JSON.stringify( this.categoryHotels ) )      
      let hotel = hoteles.find( hotel => hotel.value === idHotel )      
      if( hotel ){
        const { text, value } = hotel
        hotel.id = value
        hotel.name = text
        delete hotel.value
        delete hotel.text
        await this.fetchProductsByCategoriesWeddings( hotel )
        this.$emit('set-current-page')
      }          
    },
    async getProducts( idCategoría ){       
      const categorias = JSON.parse( JSON.stringify( this.categories ) )      
      let categoria = categorias.find( cat => cat.value === idCategoría )    
      if( categoria ){
        const { actionFetch, value, text } =  categoria 
        categoria.id = value
        categoria.name = text
        delete categoria.value
        delete categoria.text
        if( actionFetch == "get-products-best-seller" ){
          await this.fetchProducts()   
          this.$emit('set-current-page')
        }
        if( actionFetch == "get-products-by-category" ){          
          await this.fetchProductsByCategories(categoria)
          this.$emit('set-current-page')
        }
                        
      }      
    },


    async getProductsweddings( categoria ){   
        await this.fetchProductsByCategoriesWeddings(categoria)
        this.$emit('set-current-page')    
    },


    async getProductsByServices( key ){
      let categoria = services.find( cat => cat.value === key )    
      const { action } =  categoria
      if( categoria ){        
        if(action == "get-packages" ){
          await this.fetchPackages()
          this.$emit('set-current-page')
        }
        if( action == "get-events" ){
          await this.fetchEvents()
          this.$emit('set-current-page')
        }
      }      
    },
    removeItemLocalstorage(){
      localStorage.removeItem('dataCategoryHotels')
      localStorage.removeItem('dataCategories')
      localStorage.removeItem('dataCategoryServices')
    }      
}
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }

}
</style>
