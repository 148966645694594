<template>
  <b-row>
      <b-col>
          <b-card-header class="flex-column align-items-start">
              <b-card-title>Your information</b-card-title>
              <b-card-text class="text-muted mt-25">
                  Be sure to fill in the required fields *
              </b-card-text>
          </b-card-header>
      </b-col>
      
  </b-row>    
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    
    formaPago:{
      type: String,
      required: true,      
    },
    subTotal:{
      type: String,
      required: true,      
    },    
    total:{
      type: String,
      required: true,      
    }     
  }, 
  computed:{ 
    ...mapGetters('shop',['totalDiscountOnAllProducts']),
       subTotalParcial(){
       return ( (Math.round( (this.cart.firstPay ) * 100) / 100).toFixed(2) )
    },   
     subTotalParcialBooking(){
       return ( (Math.round( (this.cart.firstPayTotal+this.cart.firstPay ) * 100) / 100).toFixed(2) )
    }, 
  },
}
</script>

<style lang="scss" scoped>

.totalParcial{
color:crimson 
}
</style>