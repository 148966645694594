<template>
  <section id="ecommerce-header">
    <div class="row">
      <div class="col-sm-12">
        <div class="ecommerce-header-items">
          <div class="result-toggler">
            <feather-icon
              icon="MenuIcon"
              class="d-block d-lg-none"
              size="21"
              @click="mQShowSidebarLeft"
            />
            <div class="search-results">
              {{ filteredProducts.length }} products found
            </div>
          </div>
          <div class="view-options d-flex">

            <!-- Sort Button -->
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="sortBy.text"
              right
              variant="outline-primary"
            >                          
              <b-dropdown-item
                v-for="sortOption in sortByOptions"
                :key="sortOption.value"
                @click="sortByOption(sortOption)"
              >
                {{ sortOption.text }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Item View Radio Button Group  -->
            <b-form-radio-group
              v-model="itemView"
              class="ml-1 list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
              @change="chageView"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon
                  :icon="option.icon"
                  size="18"
                />
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>
  </section>    
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    mqShallShowLeftSidebar:{
       type: Boolean,
      required: true,      
    },
    sortBy: {
      type: Object,
      required: true,
    },
    sortByOptions:{
      type: Array,
      required: true,
    },    
    itemViewOptions:{
      type: Array,
      required: true,
    }   
  }, 
  directives: {
    Ripple,
  },  
  data() {
    return {
      itemView:'grid-view',      
    }
  },
  computed:{    
    ...mapState('products',['filter']),
    ...mapGetters('products',['filteredProducts']), 
  },
  methods:{
    chageView(viewType){      
      this.$emit("change-view", viewType)   
    },
    sortByOption(option){      
      this.$emit("sort-by-option", option)   
    },
    mQShowSidebarLeft(){
      this.$emit("mq-show-sidebar-left", true)   
    }    
  }
}
</script>

