import { $themeConfig } from '@themeConfig'

export default {
    //App state
    windowWidth: 0,
    shallShowOverlay: false,

    //App-config state
    layout: {
        isRTL: $themeConfig.layout.isRTL,
        skin: 'light' || $themeConfig.layout.skin,
        routerTransition: $themeConfig.layout.routerTransition,
        type: $themeConfig.layout.type,
        contentWidth: $themeConfig.layout.contentWidth,
        menu: {
            hidden: true,
        },
        navbar: {
            type: $themeConfig.layout.navbar.type,
            backgroundColor: $themeConfig.layout.navbar.backgroundColor,
        },
        footer: {
            type: $themeConfig.layout.footer.type,
        },
    },    
    //Vertical-menu state
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,    
}