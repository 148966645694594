<template>
  <!-- Error page-->
  <div class="misc-wrapper">
          

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center" v-if="nbresponse == 'Aprobado'">
            <b-card
                text-variant="center"
                class="card card-congratulations mb-0"
            >

                <b-avatar
                variant="warning"
                size="70"
                class="shadow mb-2"
                >
                <feather-icon
                    size="28"
                    icon="CheckIcon"
                />
                </b-avatar>
                <h1 class="mb-1 mt-50 text-white">
                Congratulations
                </h1>
                <b-card-text class="m-auto w-75">
                Your payment was completed <strong>successfully</strong>.
                </b-card-text>
            </b-card>

            <b-card
            class="text-left"
            >
                <b-card-text>
                    <p class="mb-0"><span class="font-weight-bold">Reference ID:</span> {{referencia}}</p>
                    <p class="mb-0"><span class="font-weight-bold">Total:</span> {{importe}}</p>
                    <p class="mb-0"><span class="font-weight-bold">Email:</span> {{email}}</p>
                    <p class="mb-0"><span class="font-weight-bold">Date and time:</span> {{fecha}}</p>
                </b-card-text>
            </b-card>

      </div>
      
      <div class="w-100 text-center" v-else>
            <b-card
              text-variant="center"
              class="card card-error mb-0"
            >
                <b-avatar
                variant="warning"
                size="70"
                class="shadow mb-2"
                >
                <feather-icon
                    size="50"
                    icon="FrownIcon"
                />
                </b-avatar>
                <h1 class="mb-1 mt-50 text-white">
                The transaction has been declined
                </h1>
                <b-card-text class="m-auto w-75">
               your payment was <strong>declined</strong>. would you like to try again?.
                </b-card-text>
            </b-card>

            <b-card
            class="text-left"
            title="Please try again or contact us"
            >
                <b-card-text>
                    <p class="mb-0"><span class="font-weight-bold">Total:</span> {{importe}}</p>
                    <p class="mb-0"><span class="font-weight-bold">Email:</span> {{email}}</p>
                </b-card-text>
            </b-card>

      </div>

    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
// import Vue from 'vue'
import { mapMutations } from 'vuex'

export default {  
  mounted(){     
    if( this.success ){      
      this.setEmptyCart()      
      this.unsetLastNameSearch()      
      this.unsetDataRoom()      
      this.resetToInitialState()            
    }    
  },
  data() {  
    console.log(this.$route.query)  
    return {
      success: (this.$route.query.success === 'true') ? true : false,
      nbresponse: this.$route.query.nbResponse || '',
      referencia: this.$route.query.referencia || '',
      importe: this.$route.query.importe || '',
      nuAut: this.$route.query.nuAut || '',        
      email: this.$route.query.email || '',
      fecha: this.$route.query.fecha || '',
      urlBase: process.env.VUE_APP_BASE_URL_API,
    }
  },
  methods:{    
    ...mapMutations('shop',['setEmptyCart','unsetLastNameSearch','unsetDataRoom']),
    ...mapMutations('bookings',['resetToInitialState']),    
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>


