<template>
  <div class="center-btn">
    <div class="mb-2">
      <strong>Price:</strong>                     
        <b-badge variant="warning">
          ${{room.detailSelected.priceInit}} 
        </b-badge>
    </div>
    <b-button               
      variant="primary"
      @click="handleAddToCart(room, room.multimedias[0].multimedia, indice)"
    > <feather-icon icon="ShoppingCartIcon"/> Add to cart</b-button>    	
  </div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
// import vSelect from 'vue-select'
import { toJson, toDecimal } from '@/helpers/helpers'

export default { 
  props:{
    room: {
      type: Object,
      required: true
    },
    clave:{
			type:String,
			required:true
		},   
    indice:{
			type:Number,
			required:true
		},
    userAuthorizeRate:{
      type:Object,
			required:true      
    }   
  },  
  // components: {	vSelect	},
  data() {
		return {
			imgUrl: process.env.VUE_APP_IMG_SRC_API,       
			perPage: 3,
      pManualPrevio: this.room.detailSelected.newManualPrice, 
      isPriceMatch: true,
		}
	},
  computed:{
		...mapState('start',['promoCodes', 'currencies']),    
		...mapState('bookings',['tabs']),
		...mapState('shop',['cart']),
    
    precioConversion(){  
      const precioToUse = (this.room.detailSelected.priceMatchTax != '') ? 
                          this.room.detailSelected.priceMatchTax :
                          this.room.detailSelected.AmountAfterTax
      const conversion = precioToUse * this.room.detailSelected.divisa.value  
      return toDecimal(conversion) 
    },  
    promocodeSelect: {
      get(){
        const promoCode = this.room.detailSelected.PromotionCode
          const codigo = promoCode ? promoCode : ""
          const promo = this.promoCodes.find( item => item.name === codigo )
          return promo || null
        },
        set( pmCode ){        
          const name  = pmCode ? pmCode.name : null
          return this.promoCodes.find( pmCode => pmCode.name === name )             
        } 
      }, 
  },
 
  methods:{
    ...mapMutations('bookings',['setAllowPriceMatch']),           
    handleAddToCart(room, img, indice){
      const payload = { room, img, indice }
      this.$emit('handle-add-to-cart', payload)
    },
    onlyNumber(event){      
      this.$emit('only-number', event)
    },
    setTaxManualPrice(newManualPrice, indice){
      /* si newManualPrice es diferente */
     const allowPriceMatch = newManualPrice != this.pManualPrevio ? true :false     
     const payload = { newManualPrice,  indice, allowPriceMatch }
     this.$emit('set-tax-manual-price', payload)
    },
    checkPriceMatchByManualRate(keyRoom, newManualPrice, indice){
      const data = { key:this.clave, indice: this.indice, allowPriceMatch: true}
      this.setAllowPriceMatch(data)          
      const payload = { keyRoom, newManualPrice,  indice, precioManualPrevio: this.pManualPrevio, isManualRate: true, dataPromoCode: null, allowPriceMatch: true, isTabOne: false, isTabTwo: true}
      this.$emit('check-price-match', payload)
    }, 
    checkPriceMatchByPromotionCode( promotionCode ){       
      if(promotionCode){
        this.room.detailSelected.PromotionCode = promotionCode 
        const data = { key:this.clave, indice: this.indice, allowPriceMatch: false}
        this.setAllowPriceMatch(data)   
        const { name } = promotionCode         
        const payload = {
          keyRoom: this.room.keyRoom,
          newManualPrice: '', 
          indice: this.indice,
          precioManualPrevio: this.pManualPrevio,
          isManualRate: false,
          promotionCode: name,
          dataPromoCode: toJson(promotionCode),
          allowPriceMatch: false, 
          isTabOne: true,
          isTabTwo: false
        }
        // console.log(payload, authorization)  
             
        this.$emit('check-price-match', payload)             
      }
      if( !promotionCode ){
        this.room.detailSelected.PromotionCode  = null 
        const data = { key:this.clave, indice: this.indice, allowPriceMatch: true}
        this.setAllowPriceMatch(data)
      }
    }        
  }
}
</script>
<style>
.underline-price {
  color: black;
	text-decoration: line-through;
  text-decoration-color: black;
}
.underline-price-btn {
  color: black;
	text-decoration: line-through;
  text-decoration-color: black;
}
.center-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
     
}
</style>
