var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('b-row',[(_vm.fieldsRequireds.showInputDate)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Dates"}},[_c('b-form-input',{attrs:{"type":"date","min":_vm.fechaActual,"state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.operationdate),callback:function ($$v) {_vm.$set(_vm.product, "operationdate", $$v)},expression:"product.operationdate"}})],1)}}],null,false,2075341701)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Time"}},[_c('b-form-input',{attrs:{"type":"time","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.operationtime),callback:function ($$v) {_vm.$set(_vm.product, "operationtime", $$v)},expression:"product.operationtime"}})],1)}}])})],1),(_vm.fieldsRequireds.showInputFrom)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"from"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"From"}},[_c('label',{attrs:{"for":"hotel"}},[_vm._v("From")]),_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null),"options":_vm.destinations},on:{"change":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.itinerario.ifrom),callback:function ($$v) {_vm.$set(_vm.product.itinerario, "ifrom", $$v)},expression:"product.itinerario.ifrom"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,false,3936040968)})],1):_vm._e(),(_vm.fieldsRequireds.showInputTo)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"To"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"To"}},[_c('label',{attrs:{"for":"Destinos"}},[_vm._v("To")]),_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null),"options":_vm.destinations},on:{"change":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.itinerario.ito),callback:function ($$v) {_vm.$set(_vm.product.itinerario, "ito", $$v)},expression:"product.itinerario.ito"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,false,2195524546)})],1):_vm._e(),(_vm.fieldsRequireds.showInputPax)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"pax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Num. Pax"}},[_c('label',{attrs:{"for":"Pax"}},[_vm._v("Num. Pax")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter pax","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.itinerario.pax),callback:function ($$v) {_vm.$set(_vm.product.itinerario, "pax", $$v)},expression:"product.itinerario.pax"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,false,2462977043)})],1):_vm._e(),(_vm.fieldsRequireds.showInputTypeService)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"trip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Type of service"}},[_c('label',{attrs:{"for":""}},[_vm._v("Type of service")]),_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null),"options":_vm.optionsTrip},on:{"change":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.itinerario.trip),callback:function ($$v) {_vm.$set(_vm.product.itinerario, "trip", $$v)},expression:"product.itinerario.trip"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,false,4114083090)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"Note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Notes( max: 255)"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Write a note","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.clientnote),callback:function ($$v) {_vm.$set(_vm.product, "clientnote", $$v)},expression:"product.clientnote"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v(" Has "+_vm._s(_vm.max - _vm.product.clientnote.length)+" characters left ")])],1)],1)}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }