import Vue from 'vue'
import { showAlertMessage, consoleMsgFinally } from '../../helpers/helpers'
import router from './../../router/'



export async function signInWidgetApp(context, user){    
    try {
        context.commit('auth/setLoadingAuth', true, { root: true} )
        const response =  await Vue.axios({            
            method: 'POST',
            url:'/weblogin/',
            data: user
        })        
        //desestructuración de lo que me regresa en el respose payload      
        const {id, name, lastname, canalventa, puntosventa, seccioncanalventa } = response && response.data
       
        user.idUser = id
        user.name = name
        user.lastname = lastname                
        user.canalventa = canalventa         
        user.puntosventa = puntosventa  
        user.sectionCanal = seccioncanalventa  
        
        delete user.password //del user elimino el password, para guardarlo en el state                       
        if( user.canalventa && user.puntosventa.length > 0 ){
            context.commit('setUser', user)   
            context.commit('shop/setCanalVenta', user.canalventa.id, { root: true})             
                       
            context.commit('shop/setidUserClient', user.idUser, { root: true})                                     
            if( user.puntosventa.length > 0 ){            
                context.commit('shop/setPuntoDeVenta', puntosventa[0].id, { root: true})  
            }
        } else { 
            showAlertMessage('Error','BellIcon', '😪😪 Aun no tiene un canal de venta y/o punto de venta para su cuenta, contacte a sistemas para que se le asigne respectivamente..', 'danger', 4000, 'bottom-right')
        }                                                
    } catch (error) { 
        if(error.response){
            console.log(error.response.statusText)
            if( error.response.statusText == 'Bad Request' ){
                showAlertMessage(`${error.response.statusText || 'No existe usuario' } ${': datos incorrectos'}`,'InfoIcon', '😪😪 Usuario o Contraseña incorrectos, o el usuario no existe', 'danger', 5000, 'bottom-right')                 
            }
            if( error.response.statusText == 'Unauthorized' ){
                showAlertMessage(`${error.response.statusText || 'No existe usuario' } ${': acceso invalido'}`,'InfoIcon', '😪😪 Credenciales de acceso inválidos', 'danger', 5000, 'bottom-right')
                // context.commit('auth/removeQueryParams', {}, { root: true} ) 
                setTimeout( () => {
                    router.push({ name: 'not-authorized' })                
                }, 3000)                
            }
            
        } 
    } finally {
        context.commit('auth/setLoadingAuth', false, { root: true} ) //se terminar de mostrar el loader          
        consoleMsgFinally('auth/signIn','Peticion de datos de usuario terminada')
    }
}