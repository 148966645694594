// import { filter } from 'lodash'
import initialState from './state'

export function setLoadingBookings( state, bool) {
    state.isLoadingBookings = bool
}
export function setLoadingProcess( state, bool) {
    state.isLoadingProcess = bool
}

export function addTab(state, data) {    
    state.tabs.push(data)
}

export function addArrayNinios(state, data) {  
    const { key, ageChildrens } = data
    let item = state.tabs.find( item => item.key === key )
    item.dataSearch.ninos = ageChildrens  
}

export function deleteTab(state, valor) {     
    state.tabs = state.tabs.filter(item => item.key !== valor) 
}

export function setSelectedTab(state, data) {
    state.tabSelected = data
}

export function setDataSearch(state, data) {
    let item = state.tabs.find( item => item.key === data.key )
    delete data.key
    item.dataSearch = data    
}


export function setDataUserAuthorization(state, data) {
    const { key, code, idUser, isInvalid, username } = data    
    let item = state.tabs.find( item => item.key === key )    
    item.dataSearch.userAuthorizeRate.isInvalid = isInvalid  
    item.dataSearch.userAuthorizeRate.idUser = idUser  
    item.dataSearch.userAuthorizeRate.username = username  
    item.dataSearch.userAuthorizeRate.code = code
    delete item.dataSearch.userAuthorizeRate.key
}
export function unsetDataUserAuthorization(state, data) {
    const { key } = data    
    let item = state.tabs.find( item => item.key === key )    
    item.dataSearch.userAuthorizeRate.isInvalid = true  
    item.dataSearch.userAuthorizeRate.idUser = null  
    item.dataSearch.userAuthorizeRate.username = ''  
    item.dataSearch.userAuthorizeRate.code = ''
    delete item.dataSearch.userAuthorizeRate.key
}

export function setUserAuthorizationUrl(state, data) {
    const { key, url } = data    
    let item = state.tabs.find( item => item.key === key )        
    item.dataSearch.userAuthorizeRate.urlAuthorization = url
    delete item.dataSearch.userAuthorizeRate.key
}
export function showRatesRoom(state, data) {
    const { key, indice, boolean} = data
    let bookings = state.tabs.find( item => item.key === key ).dingusBookings
    bookings[indice].showingRates = boolean    
}


export function setAgeChild(state, data) {
    const { indice, key, edad } = data
    let item = state.tabs.find( item => item.key === key )
    item.dataSearch.ninos[indice].edad = edad        
}

export function removeAgeChild(state, data) {
    const { indice, key } = data
    let item = state.tabs.find( item => item.key === key )
    item.dataSearch.ninos = item.dataSearch.ninos.splice(indice, 1) 
    item.dataSearch.numberChildren = item.dataSearch.numberChildren - 1
}

export function setListRooms( state, data) { 
    const { key, info } = data
    let item = state.tabs.find( item => item.key === key )
    item.rooms = info
}

export function setBookings(state, data) {
    const { key, payload } = data    
    let item = state.tabs.find( item => item.key === key )
    item.dingusBookings = payload
}

export function setCountBookings( state, status) {
    state.countDingusBookings = status
}

export function setDetailSelectedPlan(state, data) {
    const { key, indice, plan} = data    
    let item = state.tabs.find( item => item.key === key )  
    item.dingusBookings[indice].detailSelected = plan
}

export function setManualPriceDetailSelected(state, data) {
    const { key, indice, newManualPrice, allowPriceMatch } = data    
    let item = state.tabs.find( item => item.key === key ) 
    const precioUsar = newManualPrice       
    // console.log(newManualPrice)
    item.dingusBookings[indice].detailSelected.newManualPrice = newManualPrice    
    item.dingusBookings[indice].detailSelected.newManualPriceInit = precioUsar    
    item.dingusBookings[indice].detailSelected.allowPriceMatch = allowPriceMatch    
}

export function setPromotionCodeDetailSelected(state, data) {
    const { key, indice, promotionCode } = data       
    let item = state.tabs.find(item => item.key === key) 
    item.dingusBookings[indice].detailSelected.PromotionCode = promotionCode.name  
}

export function setAllowPriceMatch(state, data) {
    const { key, indice, allowPriceMatch} = data       
    let item = state.tabs.find(item => item.key === key) 
    item.dingusBookings[indice].detailSelected.allowPriceMatch = allowPriceMatch
}

export function resetToInitialState(state) {
    Object.assign(state, initialState)
}

export function resetToInitialStateTab(state, key) {
    let item = state.tabs.find( item => item.key === key ) 
    item.dataSearch = {
        vendor: '', language: null, name: '', rangeDate: 0, dateIn: '', dateOut: '', nights: 0, hotel: '', room: null, adults: 1, numberChildren: 0, ninos:[],  promoCode: null, promoCodeManual:'', userAuthorizeRate: {isInvalid: true, idUser: null, username: '', code:'' }
    }
    item.rooms = []
    item.dingusBookings = []
}

export function setErrorBookingDingus(state, errorMessage){
    state.errors.bookings.error = true
    state.errors.bookings.message = errorMessage
}

export function unsetErrorBookingDingus(state){
    state.errors.bookings.error = false
    state.errors.bookings.message = ''
}