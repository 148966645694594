//Descuento aplicado a toda la orden
export function totalDiscountCuenta(state) {
  
    let sum = 0;
    const decimalDiscount = parseFloat( state.cart.totalBreakdown.discountApplied ) / 100;  
    state.cart.items.forEach( (product) => {
       sum += (parseFloat(product.saleprice) * parseFloat(product.qty));
    })
    return (Math.round( sum * decimalDiscount * 100) / 100).toFixed(2);
  }
  
//sumatoria en el total de descuento en cada producto,se ocupa para obtener el subtotal
export function totalDiscountOnAllProducts(state) {  
    let desc = 0
        state.cart.items.forEach( (product) => {
            const decimalDiscount = parseFloat(product.discountApplied ) / 100
            desc +=  (parseFloat(product.originalSalePrice ) * parseFloat(decimalDiscount) * parseFloat(product.qty))
        });
    return (Math.round( desc * 100) / 100).toFixed(2)
}



export function totalBreakdown(state) {  
    return (Math.round( state.cart.totalBreakdown.total * 100) / 100).toFixed(2);
}

export function onlyBookings( state ) {
    let items = []
    const bookings = state.cart.items.filter(item => item.modelType === 'booking') 
    bookings.forEach(({detaildisplay, folio}) => {
        items.push({name: detaildisplay, key: folio})        
    })
    return items  
}