<template>
  <div>    
		<!-- <nab-tabs/>       -->
    <div style="height: inherit">
      <!-- Header shop -->
      <Header
        :mqShallShowLeftSidebar="mqShallShowLeftSidebar"
        :sortBy="sortBy"
        :sortByOptions="sortByOptions"
        :itemView="itemView"
        :itemViewOptions="itemViewOptions"
        @change-view="changeView"
        @sort-by-option="sortByOption"
        @mq-show-sidebar-left="mQShowSidebarLeft"
      />            
      <!-- Overlay -->
      <div class="body-content-overlay"/>
      <!-- Searchbar -->
      <Searchbar/>
      <!-- Products -->      
      <Products :itemView="itemView" :filters="filters" class="mt-1"/>
      <!-- Pagination of products -->      
      <ProductsPagination :totalRows="filteredProducts.length" :filters="filters"/>
      <!-- Sidebar filter-->
      <portal to="content-renderer-sidebar-detached-left">
        <FiltroSidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @set-current-page="setCurrentPage"
        />
      </portal>
    </div>
  </div>    
</template>

<script>
import {mapState, mapActions,  mapGetters, mapMutations} from 'vuex'
// import NabTabs from '@/components/NabTabs'
import Header from '@/components/shop/sales/Header'
import FiltroSidebar from '@/components/shop/sales/FiltroSidebar'
import Searchbar from '@/components/shop/sales/Searchbar'
import Products from '@/components/shop/sales/Products'
import ProductsPagination from '@/components/shop/sales/ProductsPagination'
//seeds data
import {itemViewOptions, sortByOptions, filterOptions, filters} from '@/data/data'

export default {
  async mounted(){

    await this.fetchCurrencies( this.cart.cliente ) 
    
    if( this.filteredProducts.length == 0 ){

      await this.fetchProducts()

      if( this.filteredProducts.length > 0 ){        
        await localStorage.setItem('dataCategories', JSON.stringify({value:'BestSeller-01', text: 'Best Seller' }))    
      }                
    }    
    this.setDataFromQueryParams()
  },
  components: {       
    // SFC
    // NabTabs,
    Header,
    Searchbar,
    FiltroSidebar,
    Products,
    ProductsPagination
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,            
      itemView:'grid-view',
      itemViewOptions,
      sortBy :{ text: 'Alphabetical order', value: 'alfabético' },
      sortByOptions,      
      filters,
      filterOptions,
      mqShallShowLeftSidebar: false
    }
  },
  computed:{
    ...mapState('shop',['cart']),               
    ...mapState('auth',['queryParams']),               
    ...mapState('start',['categories']),               
    ...mapGetters('products',['filteredProducts']),               
  },
  methods: {
    ...mapActions('products',['fetchProducts','fetchProductsByCategories']),  
    ...mapActions('start',['fetchCurrencies',]), 
    ...mapMutations('products',['setSortBy']),  
    ...mapMutations('shop',['setDataClientFromQueryParams']),          

    changeView(viewType){
      this.itemView = viewType      
    },
    sortByOption(option){
      this.sortBy = option
      this.setSortBy(JSON.parse(JSON.stringify(this.sortBy)))          
    },
    mQShowSidebarLeft(booleano){
      this.mqShallShowLeftSidebar = booleano      
    },
    setCurrentPage(){
      this.filters.currentPage = 1
    },
    removeItemLocalstorage(){
      localStorage.removeItem('dataCategoryHotels')
      localStorage.removeItem('dataCategories')
      localStorage.removeItem('dataCategoryServices')
    },
    setDataFromQueryParams(){      
      this.setDataClientFromQueryParams( this.queryParams )      
    }    
  },
  watch:{
    categories(){      
      if( this.filteredProducts.length == 0 ){               
        this.fetchProductsByCategories( this.categories[0] )         
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" >

  .ecommerce-header-items {
    display: flex;
    justify-content: space-between;

    .result-toggler {
      display: flex;
      align-items: center;

      // Sidebar toggler
      .shop-sidebar-toggler {
        padding-left: 0;

        &:active,
        &:focus {
          outline: 0;
        }

        .navbar-toggler-icon {
          height: auto;
          i,
          svg {
            color: #6e6b7b;
            height: 1.5rem;
            width: 1.5rem;
            font-size: 1.5rem;
          }
        }
      }
      // Search result title
      .search-results {
        font-weight: 500;
        color: #5e5873;
      }
    }
  }

  // Product search bar
  .ecommerce-searchbar {
    .input-group {
      box-shadow: 0 2px 8px 0 rgba(#22292f, 0.14);
      border-radius: 0.3rem;
    }
  }

  // Search bar
  .search-product,
  .input-group-text {
    height: 48px;
    border: none;
    font-size: 0.95rem;
    padding-left: 1.25rem;

    &::placeholder {
      font-size: 0.95rem;
    }
  }  
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
