import Vue from 'vue'
import { handlerErrors,  consoleMsgFinally } from '../../helpers/helpers'
import store from '@/store' 


import { find } from 'lodash'

export async function fetchHotels(context) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/hotels/'
        })
        const payload = response && response.data
        const hotels = payload.filter( hotel => hotel.showinweb )
        context.commit('setHotels', hotels )
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorHotels', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchHotels','La petición para obtener los hoteles se ha terminado')    
    }
}

export async function checkIfExistBSProducts() {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id 
    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: { bs: 1, idseccanal}
        })
        const payload = response && response.data                
        const result = payload.length > 0 ?  true : false
        return result
    
    } catch (error) {
        console.log(error)
    } finally {          
        console.log('checkIfExistBSProducts end')        
    }
}

export async function fetchCategories({ commit }) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/category/'
        })
        const payload = response && response.data
        payload.forEach( ( category ) => {
            category.actionFetch = 'get-products-by-category'
            category.text = category.name,
            category.value = category.id,            
            delete category.id
            delete category.name
        })
        const existBestSellers = await checkIfExistBSProducts()
        
        if( existBestSellers ){
            payload.unshift({
                value: 'BestSeller-01',
                text: 'Best Seller',
                actionFetch: 'get-products-best-seller'            
            })
        }
        
        commit('start/setCategories', payload, { root:true } )            
    } catch (error) {
        commit('setErrorCategories', error.message)
    } finally {
        consoleMsgFinally('start/fetchCategories','La petición para obtener las categorías se ha terminado')        
    }
}


export async function fetchCategoriesWeddings({ commit }) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/categoryweddings/'
        })
        const payload = response && response.data

        payload.forEach( ( category ) => {
            category.actionFetch = 'get-products-by-category'
            category.text = category.name,
            category.value = category.id,  
            delete category.id
            delete category.name

        })
        const existBestSellers = await checkIfExistBSProducts()
        
        if( existBestSellers ){
            payload.unshift({
                value: 'BestSeller-01',
                text: 'Best Seller',
                actionFetch: 'get-products-best-seller'            
            })
        }

        commit('start/setCategoriesWeddings', payload, { root:true } )            
    } catch (error) {
        commit('setErrorCategories', error.message)
    } finally {
        consoleMsgFinally('start/fetchCategoriesWeddings','La petición para obtener las categorías se ha terminado')        
    }
}


export async function fetchCurrencies({ commit }, cliente) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/currency/'
        })
        const payload = response && response.data
            //sino hay un currency se setea uno por default para el cliente        
        if (cliente) {
            if(!cliente.currency){
                const currency = find(payload, { code: 'USD' })
                commit('shop/setCurrency', currency, { root: true })
            }
        }
        commit('start/setCurrencies', payload, { root: true })
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('start/setErrorCurrencies', errorMsg, { root: true })
    } finally {
        consoleMsgFinally('start/fetchCurrencies','La petición para obtener los currencies se ha terminado')        
    }
}

export async function fetchDestinations(context) {

    try {
        const response = await Vue.axios({
            url: '/catalogs/destinos/'
        })
        const payload = response && response.data

        payload.forEach((destination) => {
            destination.value = destination.name
            destination.text = destination.name
            delete destination.id
            delete destination.name
        })
        context.commit('setDestinations', payload)
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorDestinations', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchDestinations','La petición para obtener los destinos se ha terminado')
    }
}

export async function fetchDepartaments(context) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/departamentos/'
        })
        const payload = response && response.data
            //ordeno alfabeticamente los nombres de los departamentos
        payload.sort((a, b) => a.name.localeCompare(b.name))
        payload.forEach((departament) => {
            departament.value = departament.id,
                departament.text = departament.name
            delete departament.id
            delete departament.deleted
            delete departament.name
            delete departament.createdate
        })
        context.commit('setDepartaments', payload)
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorDepartaments', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchDepartaments','La petición para obtener los departamentos se ha terminado')    
    }
}

export async function fetchAirlines(context) {
           
    try {
        const response = await Vue.axios({
            url:'/catalogs/aerolineas/'
        })
        const payload = response && response.data
        context.commit('setAirlines', payload )    
    } catch (error) {        
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorAirlines', errorMsg)
    } finally {        
        consoleMsgFinally('start/fetchAirlines','La petición para obtener las aerolineas se ha terminado')    

    }
}


export async function fetchTypeClients(context) {

    try {
        const response = await Vue.axios({
            url: '/catalogs/tipocliente/'
        })
        const payload = response && response.data
        context.commit('setCustomerTypes', payload)
        const clienteTipo = store.state.shop.cart.cliente.customertype
        if(clienteTipo == ''){
            const typeCustomer = find(payload, { name: 'Externo' })
            context.commit('shop/setTipoCliente', typeCustomer.id, { root: true })
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorCustomerTypes', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchTypeClients','La petición para obtener los tipos de clientes se ha terminado')                
    }
}

//Obtener terminos y condiciones por canal
export async function fetchTerms({ commit }, data) {
    const {typeTerms, idLanguage } = data
    try {
        const response = await Vue.axios({
            url: '/catalogs/terminosycondiciones/',
            params: {
                typeTerms,
                idLanguage
            }
        })
        const payload = response && response.data
        commit('start/setTerms', payload, { root: true })
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('start/setErrorTerms', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchTerms','La petición para obtener los terminos se ha terminado')    
    }
}


//Obtener listado de aerolineas
export async function fetchAerolineas(context) {

    try {
        const response = await Vue.axios({
            url: '/catalogs/aerolineas/'
        })

        const payload = response && response.data
        // payload.forEach((aerolineas) => {
        //     aerolineas.value = aerolineas.name
        //     aerolineas.text = aerolineas.name
        //     delete aerolineas.id
        //     delete aerolineas.name
        // })
        context.commit('setAirlines', payload)
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorCustomerTypes', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchAerolineas','La petición para obtener las aerolíneas se ha terminado')
    }
}

export async function fetchPromoCodes(context) {           
    try {
        const response = await Vue.axios({
            url:'/catalogs/promoCodes/'
        })
        const payload = response && response.data
        context.commit('setPromoCodes', payload )    
    } catch (error) {        
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorPromoCodes', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchPromoCodes','La petición para obtener los promocodes se ha terminado')        
    }
}

export async function fetchLanguages(context) {           
    try {
        const response = await Vue.axios({
            url:'/catalogs/language/'
        })
        const payload = response && response.data
        context.commit('setLanguages', payload )    
    } catch (error) {        
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorLanguages', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchLanguages','La petición para obtener los lenguajes se ha terminado ') 
    }
}

export async function fetchPaymentMethods(context) {           
    try {
        const response = await Vue.axios({
            url:'/catalogs/paymentmethod/'
        })
        const payload = response && response.data
        const creditCardPM = payload.find( fp => fp.id === 1 && fp.name === 'Credit Card' ) 
        context.commit('setPaymentMethods', payload )  
        context.commit('shop/setPaymentMethodClient', creditCardPM.id, { root: true})
    } catch (error) {        
        const errorMsg = handlerErrors(error.message, error.response)
        context.commit('setErrorLanguages', errorMsg)
    } finally {
        consoleMsgFinally('start/fetchPaymentMethods','La petición para obtener los métodos de pago se ha terminado ') 
    }
}
