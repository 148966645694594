
export const faqData = [
    {
        icon: 'CreditCardIcon',
        title: 'Formas de pago',
        subtitle: 'Sobre las formas de pago',
        qandA: [
            {
                question: '¿Que es The Fives Benefit?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },
    {
        icon: 'ShoppingBagIcon',
        title: 'Entrega',
        subtitle: 'Lo concerniente al finalizar una orden',
        qandA: [
            {
                question: '¿Al finalizar una orden, que sigue despues?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },{
        icon: 'RefreshCwIcon',
        title: 'Cancelaciones',
        subtitle: 'Lo concerniente a políticas de cancelación y devoluciones',
        qandA: [
            {
                question: '¿Como se gestiona una cancelación?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },
    {
        icon: 'BookmarkIcon',
        title: 'Reservas',
        subtitle: 'Lo referente a las reservas',
        qandA: [
            {
                question: '¿Como funciona las reservas?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },
    {
        icon: 'ArchiveIcon',
        title: 'Productos',
        subtitle: 'Lo concerniente a productos y servicios de TFH',
        qandA: [
            {
                question: '¿Como este tema de productos y servicios ?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },
    {
        icon: 'PackageIcon',
        title: 'Paquetes',
        subtitle: 'sobre los distintos paquetes y servicios',
        qandA: [
            {
                question: '¿Es lo mismo paquetes y servicios?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },
    {
        icon: 'ShoppingCartIcon',
        title: 'Mis ventas',
        subtitle: 'Lo referente a las ventas que se realiza',
        qandA: [
            {
                question: '¿que puedo realizar aqui?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    },
    {
        icon: 'ActivityIcon',
        title: 'Operaciones',
        subtitle: 'Sección de operaciones',
        qandA: [
            {
                question: '¿Que se hace aquí?',
                answer: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, ullam exercitationem voluptates, recusandae dignissimos odio architecto, quis labore laudantium fugiat repellendus laborum explicabo? Accusantium deleniti repudiandae pariatur sit eius consequuntur.',
            },                
        ]            
    }            
]