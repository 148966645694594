<template>
  <div v-if="products.length">
    <b-card
      no-body
      style="max-width: 100%"
      v-for="product in products"
      :key="product.folio"
    >
      <b-row no-gutters>

        <div class="col-lg-4 col-xs-6 col-md-6 col-sm-12">
          <b-card-img
            fluid
            v-if="product.modelType != 'booking'"
            class="card-img-checkout"
            :src="imgUrl + product.coverImage"
            @error="imgAlternativo"
          />
        </div>

        <div class="col-lg-8 col-xs-6 col-md-6 col-sm-12 text-center">
          <b-card-body>
            <div class="item-options text-right">
              <b-button
                variant="danger"
                class="btn-sm ml-1"
                @click="deleteItem(product)"
                v-b-tooltip.hover title="Delete item"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>


            <div v-if="product.categoryName != 'Reservas'" class="item-name">
              <h5 class="mb-2 font-weight-bolder">{{ product.detaildisplay || "" }}</h5>


            </div>
            <!-- <span class="item-company">Category: {{ product.categoryName }}</span> -->

             <!-- <b-button
                  variant="danger"
                  class="btn-md ml-1"
                  @click="deleteItem(product)"
                  v-b-tooltip.hover title="Delete item"
            > -->

            <div class="item-options text-center" v-if="product.modelType != 'booking'" >
              <div class="item-wrapper">
                <h5 class="quantity-title mb-1">Quantity</h5>
                <div class="item-quantity">
                  <b-form-spinbutton
                    v-model="product.qty"
                    min="0"
                    size="sm"
                    class="ml-75"
                    inline
                    @change="changeQty($event, product)"
                    :readonly="product.categoryName == 'Transfer'"
                  />



                </div>
                

                <div class="item-cost mt-1">
                  <h5 class="item-price font-weight-bolder">$ {{ subTotalProductoQty(product) }}</h5>
                </div>
              </div>            
            </div>          


          </b-card-body>
        </div>
        
      </b-row>
      <!-- para productos, eventos, paquetes -->
      <b-modal
        :id="'modal-edit-product' + product.folio"
        ok-only
        no-close-on-backdrop
        size="lg"
        ok-title="Ok"
        centered
        :title="product.detaildisplay"
      >
        <TabProductDetail :product="product" />        
      </b-modal>
      <!-- para booking-->
      <b-modal
        :id="'modal-edit-booking' + product.folio"
        ok-only
        no-close-on-backdrop
        size="lg"
        ok-title="Ok"
        centered
        :title="product.detaildisplay"
      >
        
        <TabBookingDetail :booking="product"/>
        
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { currentDate } from '@/helpers/helpers' 
import { mapState, mapMutations } from "vuex"
import { typeDiscounts, namesPrefix } from "@/data/data" 

import TabProductDetail from "@/components/shop/checkout/TabProductDetail"
import TabBookingDetail from "@/components/shop/checkout/TabBookingDetail"
// import TabProductItinerary from "@/components/shop/checkout/TabProductItinerary"
// import FieldsRequired from "@/components/shop/checkout/FieldsRequired"

// import TabProductBEO from "@/components/shop/checkout/TabProductBEO"
import { camposRequeridosPorLLenar, showAlertMessage } from "@/helpers/helpers"
// import vSelect from "vue-select"

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      typeDiscounts,
      namesPrefix,
      fechaActual: currentDate(),
      max: 255, 
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require("@/assets/images/default.jpg"),
      options: [
        { text: "No", value: 0 },
        { text: "Si", value: 1},
      ],
      selectedPartial: 0,
      selectedNights: 1,
   
      nightsTotal: [],
    };
  },
  components: {
    // vSelect,
    //SFC
    TabProductDetail,
    TabBookingDetail,
    // TabProductItinerary,
    // FieldsRequired,
    // TabProductBEO,
  },
  computed: {
    ...mapState("start", ["terms"]),
    ...mapState("shop", ["cart"]),
   
  },
  methods: {
    camposRequeridosPorLLenar,
    ...mapMutations("shop", [
      "removeProductFromCart",
      "calculateTotal",
      "setDataProductItem",
      "setDataBookingItem",
      "setIfRequiredFieldsFilled",
      "calculateTotalNights",
    ]),
    imgAlternativo(event) {
      event.target.src = this.imdDefault;
    },
    isPackageOrEvent(categoryName) {
      const categorias = ["Events", "Packages"];
      return categorias.includes(categoryName);
    },
    isBooking(categoryName) {
      const categorias = ["Reservas"];
      return categorias.includes(categoryName);
    },
    disabledInputDiscount(typeDiscount) {
      /*Si descuento es cortesía, sin descuento o incluido en el paquete, se deshabilita input */
      return (
        typeDiscount == "Cortesía" ||
        typeDiscount == "Sin descuento" ||
        typeDiscount == "Incluido en el paquete"
      );
    },
    subTotalProductoQty(item) {
      const subTotal = (
        Math.round(
          item.qty *
            (item.discountApplied > 0
              ? item.salepriceWithDiscount
              : item.saleprice) *
            100
        ) / 100
      ).toFixed(2);
      return subTotal;
    },
   async setPriceNights(item) {
      const total = item.saleprice / item.nights;
    
      const nightsTotalPrice = item.nightsNum * total;
     item.firstPay=  (nightsTotalPrice).toFixed(2);

   await this.calculateTotal(item)
     
    },
    changeQty(qty, item) {
      const product = JSON.parse(JSON.stringify(item));
      if (qty == 0) {
        this.removeProductFromCart(product);
      }
      if (qty > 0) {
        this.calculateTotal();
      }
    },
    deleteItem(item) {
      this.removeProductFromCart(item);
    },
    openModalEditProduct(item) {
      const modal = "modal-edit-product" + item.folio;
      const btnShowProduct = "btnShowProduct" + item.folio;
      this.$root.$emit("bv::show::modal", modal, btnShowProduct);
    },
    openModalEditBooking(item) {
      const modal = "modal-edit-booking" + item.folio;
      const btnShowBooking = "btnShowBooking" + item.folio;
      this.$root.$emit("bv::show::modal", modal, btnShowBooking);
    },
    onlyNumber($event) {
      //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    applyTypeDiscount(product) {
      this.setDataProductItem(product);
      if (
        product.typeDiscount == "Cortesía" ||
        product.typeDiscount == "Incluido en el paquete"
      ) {
        product.discountApplied = 100;
        this.handleDiscountProduct(product.discountApplied, product);
      }
      if (product.typeDiscount == "Sin descuento") {
        product.discountApplied = 0;
        this.handleDiscountProduct(product.discountApplied, product);
      }
      if (product.typeDiscount == "Descuento") {
        product.discountApplied = product.descmax;
        this.handleDiscountProduct(product.discountApplied, product);
      }
    },
    handleDiscountProduct(discount, product) {
      let { salepriceWithDiscount, originalSalePrice, typeDiscount, descmax } =
        product;

      if (discount > 0 && discount <= 100) {
        if (typeDiscount == "Descuento" && discount > descmax) {
          const text = `El producto tiene fijado un límite de descuento de: ${descmax}`;
          showAlertMessage(
            "Atención",
            "InfoIcon",
            `${text}`,
            "warning",
            3000,
            "bottom-right"
          );
        }
        const descuentoAplicar =
          discount > descmax && typeDiscount == "Descuento"
            ? descmax
            : discount;
        product.discountApplied = descuentoAplicar;
        const decimalDiscount = parseFloat(descuentoAplicar) / 100;
        const descuento = originalSalePrice * decimalDiscount;
        salepriceWithDiscount =
          (Math.round(originalSalePrice * 100) / 100).toFixed(2) - descuento;
        product.salepriceWithDiscount = (
          Math.round(salepriceWithDiscount * 100) / 100
        ).toFixed(2);
        product.saleprice = (
          Math.round(salepriceWithDiscount * 100) / 100
        ).toFixed(2);
      } else if (discount == 0 || discount.trim() == "" || discount > 100) {
        product.salepriceWithDiscount = originalSalePrice;
        product.discountApplied = 0;
        product.saleprice = originalSalePrice;
      }
      //recalculo el total
      this.calculateTotal();
      this.setIfRequiredFieldsFilled();
    },
    camposPorLLenar(product) {
      return camposRequeridosPorLLenar(product); //helper
    },
    showTabItinerary(categoryName) {
      const categorias = ["Transfer", "Flights"];
      return categorias.includes(categoryName);
    },
    setDataInBooking(data) {
      this.setDataBookingItem(data);
    },
   async setDataInNights(product) {
     await this.calculateTotalNights(product);
      this.nightsTotal = product.nightsTotal;
        product.nightsNum=product.nights;
      await  this.setPriceNights(product);

    },
    
  },
};
</script>

<style>
.text-small {
  font-size: 0.9em;
}

.card-img-checkout {    
  height: 16rem;
  object-fit: cover;
}

</style>