import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
export const baseUrl = process.env.VUE_APP_BASE_URL_API
const baseUrlImg = process.env.VUE_APP_IMG_SRC_API
import store from '@/store'

let token
if ( process.env.NODE_ENV == 'development' ){ 
    token = store.state.auth.token || 'token-invalido' 
    console.log(token, 'dev')
    console.log(store.state.auth.token, 'store')
    // http://192.168.43.231:8080/start?token=6f292a4810d10da360ab264cf6e6d36b7b4f8ded&apellido=juarez
}

if ( process.env.NODE_ENV == 'production' ){    
    token =  store.state.auth.token || 'token-invalido'    
    console.log(token, 'production')    
}
axios.defaults.headers.common['Authorization'] = 'Token '+ token
axios.defaults.baseURL = baseUrl
Vue.use(VueAxios, axios, baseUrlImg)