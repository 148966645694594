export default {
    dataOrderPay: null,
    cart: {
        cliente: { idUser: '', name: '', lastname: '', lastnameSearch: '', applyManualRoomCharge: false, email: '', customertype: '', language: 1, customeragent: '', phone: '', paymentmethod: '', paymentreference: '', paymentlink: '', credit:'0.00', date: ''},
        items: [],
        totalBreakdown: { total: 0.00, discountApplied: 0 },
        dataOrder: null,
        makeRoomCharge: false,
        numreserva: '',
        room: '',
        partial: 0,
        firstPay: 0.00,
        firstPayTotal: 0.00,
    },
    guests: [],
    canalventa: null,
    puntoventa: null,
    isLoadingProcess: false,
    bankUrl: null,
    statusPayment: 'Pending',
    showBtnCheckout: true,
    quotation: {
        idWelcome: null,
        idFarewellMessage: null,
        idAmenity: 2, // se cambia a futuro, esto es temporal
        isQuotation: false,
        idTerms: 5 // por defecto cotizacion es 5
    },
    showBtnRetryCheckout: false,
    errors: {
        emailCheck: { error: false, message: '' },
        roomCharge: { error: false, message: '' },
        sendInfoOrder: { error: false, message: '' },
        retryPayment: { error: false, message: '' },
        sendItemsOrderCart: { error: false, message: '' },
        cancelOrderCart: { error: false, message: '' },
    },
}