<template>
  <ValidationObserver ref="observerBooking">   
      <b-row>
        <b-col md="6">
          <ValidationProvider name="Prefix" rules="required">
            <b-form-group label="Prefix" slot-scope="{ valid, errors }">
              <b-form-select
                :state="errors[0] ? false : (valid ? true : null)"
                v-model="booking.NamePrefix" @input="setDataInBooking(booking)"
              >
                <option value="">Choose an option</option>
                <option v-for="(namesF, index) in namesPrefix" :key="index" :value="namesF.value">{{namesF.text}}</option>
              </b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>          
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="Name">
            <b-form-group label="Name" slot-scope="{ valid, errors }">
              <b-form-input
                  type="text"
                  v-model="booking.GivenName"   
                  :state="errors[0] ? false : (valid ? true : null)"
                  @input="setDataInBooking(booking)" 
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>          
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="Surname">
            <b-form-group label="Surname" slot-scope="{ valid, errors }">
              <b-form-input
                  type="text"
                  v-model="booking.Surname"   
                  :state="errors[0] ? false : (valid ? true : null)"
                  @input="setDataInBooking(booking)" 
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>          
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="phone">
            <b-form-group label="Phone" slot-scope="{ valid, errors }">
              <b-form-input
                    type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    v-model="booking.PhoneNumber" 
                    maxlength="10"
                    :state="errors[0] ? false : (valid ? true : null)"
                    @input="setDataInBooking(booking)" 
                >
                </b-form-input>
                <b-form-invalid-feedback>
                {{ errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>          
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required|email" name="email">
            <b-form-group label="Email" slot-scope="{ valid, errors }">
              <b-form-input
                type="email"                  
                v-model="booking.Email"   
                :state="errors[0] ? false : (valid ? true : null)"
                @input="setDataInBooking(booking)" 
              >
              </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>          
        </b-col>       
        <b-col md="6">
          <ValidationProvider rules="max:255" name="notes">
            <b-form-group slot-scope="{ valid, errors }" label="Notes( max: 255)">            
                <b-form-group>
                    <b-form-textarea                        
                      v-model="booking.clientnote"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="write note" :maxlength="max" rows="1" 
                      @input="setDataInBooking(booking)" 
                    >
                    </b-form-textarea>                    
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                <div class="text-center">
                  <b-badge variant="success" style="width: 60%; cursor: not-allowed;"> Has {{max - booking.clientnote.length}} characters left</b-badge>
                </div>                               
            </b-form-group>
          </ValidationProvider>          
        </b-col>
      </b-row>                                             
    </ValidationObserver> 
</template>

<script>
import { mapMutations} from 'vuex'
// import { currentDate } from '@/helpers/helpers' 
import { namesPrefix } from '@/data/data' 

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },       
  },
  data () {
    return {
      max: 255,                                    
      namesPrefix      
    }
  },        
  computed:{
    // ...mapState('cart',['booking']),
                                   
  },
  methods: {
    ...mapMutations('shop',['setDataBookingItem']),  
    setDataInBooking( data ){     
      this.setDataBookingItem ( data )    
    },                 
  }
}    
</script>