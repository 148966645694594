<template>
    <div>
        <b-row>            
            <b-col md="12">
                <ValidationObserver ref="formularioCliente" tag="form" v-slot="{ invalid }">    

                    <b-form  @submit.prevent="sendDataCliente">      
                        <b-card no-body>
                            <HeaderPayment :cart="cart" :nameHotel="nameHotel" :tipoCliente="tipoCliente" :formaPago="formaPago" :subTotal="subTotal" :tipoCambio="tipoCambio" :totalDivisa="totalDivisa" :total="total" :totalDivisaBooking="totalDivisaBooking"/>                    
                            <b-card-body>
                                <b-row>

                                    <b-col cols="12" md="6">                                        
                                        <ValidationProvider rules="required|email" name="email">
                                            <b-form-group slot-scope="{ valid, errors }" label="Email*" class="mb-2">
                                                <b-form-input
                                                    id="Email-del-cliente"
                                                    type="email"
                                                    v-model="cart.cliente.email"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    placeholder="Enter email"
                                                    @input="checkIfEmailClientExist(cart.cliente.email)"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback>
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    
                                    <b-col cols="12" md="6">
                                        <ValidationProvider rules="required" name="nombre">
                                            <b-form-group slot-scope="{ valid, errors }" label="Name*">
                                                <b-form-input
                                                    type="text" v-model="cart.cliente.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"     
                                                    placeholder="Enter name"
                                                    @blur="setNameClient(cart.cliente.name)"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback>
                                                    {{ errors[0] }} 
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>                                                
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <ValidationProvider rules="required" name="surname">
                                            <b-form-group slot-scope="{ valid, errors }" label="Surname*"
                                            >
                                                <b-form-input
                                                    type="text"
                                                    v-model="cart.cliente.lastname"
                                                    :state="errors[0] ? false : (valid ? true : null)"     
                                                    placeholder="Enter Surname"
                                                    @blur="setLastNameClient(cart.cliente.lastname)"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback>
                                                    {{ errors[0] }} 
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>                 
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <ValidationProvider rules="required|min:10"  name="phone" vid="phone">
                                            <b-form-group slot-scope="{ valid, errors }" label="Phone*">      
                                                <b-form-input
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                    maxlength="20"
                                                    type="text"
                                                    v-model="cart.cliente.phone"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    placeholder="Enter phone"
                                                    @blur="setPhoneCliente(cart.cliente.phone)"
                                                    @keypress="formatPhoneNumber" 
                                                >
                                                    <!-- @keypress="formatPhoneNumber"                                    -->
                                                </b-form-input>
                                                <b-form-invalid-feedback>
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="Hoteles" rules="required">
                                            <b-form-group slot-scope="{ valid, errors }" label="Choose hotel*">
                                            <b-form-select 
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @change="handleHotelClient(cart.cliente.hotel)"
                                                v-model="cart.cliente.hotel">
                                                <option value="">Choose hotel</option>                                            
                                                <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">{{hotel.name}}</option>
                                            </b-form-select>
                                                <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="Idioma" rules="required">
                                            <b-form-group slot-scope="{ valid, errors }" label="Choose language*">
                                            <b-form-select 
                                                @change="setLanguageCliente(cart.cliente.language)"
                                                :state="errors[0] ? false : (valid ? true : null)"                        
                                                v-model="cart.cliente.language">           
                                                <option v-for="lang in languages" :key="lang.id" :value="lang.id">{{lang.name}}</option>
                                            </b-form-select>
                                                <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>

                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="Idioma" rules="required">
                                            <b-form-group slot-scope="{ valid, errors }" label="Wedding date*">
                                            <b-form-input                                                
                                                type="date"
                                                :min="fechaActual"
                                                v-model="cart.cliente.date"   
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @input="setDatesOperationInAllProducts(cart.cliente.date)"
                                            ></b-form-input>
                                                <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>                                                                                                           
                
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <div class="checkout-options">    
                                            <b-card>
                                                <b-input-group>
                                                    <b-input-group-prepend>
                                                    <b-button variant="primary" disabled> 💸 Currency </b-button>          
                                                    </b-input-group-prepend>
                                                    <b-form-select id="divisaSelect" @change="calcularImportes" v-model="cart.cliente.currency">
                                                    <option v-for="cur in currencies" :key="cur.id" :value="cur">{{cur.code}}</option>
                                                    </b-form-select>        
                                                </b-input-group>


                                                <div class="price-details">        
                                                    <hr>
                                                    <ul class="list-unstyled">
                                                        <li class="price-detail">
                                                            <div class="detail-title detail-total">
                                                            Total:
                                                            </div>
                                                            <div class="detail-amt font-weight-bolder">
                                                            $ {{total}}
                                                            </div>
                                                        </li>          
                                                    </ul>
                                                
                                                    <div v-if="cart.cliente.currency.code == 'MXN' ">
                                                        <div>
                                                            <hr>
                                                            <ul class="list-unstyled">
                                                                <li class="price-detail">
                                                                    <div class="detail-title detail-total">
                                                                        Exchange rate MXN:
                                                                    </div>
                                                                    <div class="detail-amt font-weight-bolder">
                                                                        <b-badge pill>$ {{tc}}</b-badge>
                                                                    </div>
                                                                </li>

                                                                <li class="price-detail">
                                                                    <div class="detail-title detail-total">
                                                                        Total Currency MXN:
                                                                    </div>
                                                                    <div class="detail-amt font-weight-bolder">              
                                                                        <b-badge pill >${{totalDivisa}}</b-badge>              
                                                                    </div>
                                                                </li>                              
                                                            </ul>          
                                                        </div>
                                                    </div>        
                                                    
                                                    <hr>
                                                    <b-row>

                                                    <b-col>
                                                        <b-button
                                                        variant="primary"
                                                        block
                                                        :to="{ name: 'payment' }"
                                                        :disabled="cart.items.length == 0"          
                                                        > Checkout
                                                        </b-button>
                                                    </b-col>
                                                    </b-row>        
                                                </div>

                                            </b-card>
                                        </div>  

                                    </b-col>

                                </b-row>

                                <ButtonsPayment
                                    :invalid="invalid"
                                    @reset-customer-form ="resetCustomerForm"
                                />

                            </b-card-body>                
                        </b-card>                
                        
                    </b-form>
                </ValidationObserver>            
            </b-col>                         
        </b-row>
    </div>        
</template>

<script>
import Vue from 'vue'
//eslint-disable-line
import { mapState, mapActions,mapMutations, mapGetters } from 'vuex'
// import {  showAlertMessage } from './../../helpers/helpers' getUrlParamAsObject
import { validateDataInCart, checkItemsCartSihotCode, currentDate } from '@/helpers/helpers' 
import HeaderPayment from '@/components/shop/payment/HeaderPayment'
import ButtonsPayment from '@/components/shop/payment/ButtonsPayment'

// import vSelect from 'vue-select'
export default {
    async created() {
        await this.fetchTypeClients()
        await this.fetchHotels()
        await this.fetchLanguages()    
        //estado paymentMethods, si array viene vacio, les paso los fm del user logueado 
        if( this.paymentMethods.length == 0 ){
            await this.fetchPaymentMethods()            
        }
    },
    data(){
        return {            
            fechaActual: currentDate(),
        }
    },
    components: {                    
        HeaderPayment,
        ButtonsPayment
    },    
    computed:{
        ...mapState('auth',['user']),
        ...mapState('uiAppConfig',['userParam']),
        ...mapState('start',['currencies']),

        ...mapState('shop',['cart','errors','orderCreated','isLoadingProcess','bankUrl','statusPayment']),
        ...mapState('start',['customerTypes','hotels','paymentMethods','languages']),
        ...mapGetters('shop',['totalDiscountOnAllProducts','totalDiscountCuenta', 'onlyBookings']),        
        nameHotel(){
            const hotel = this.hotels.find( h => h.id === this.cart.cliente.hotel )
            return hotel ? hotel.name : ''                        
        },
        tipoCliente(){
            const tipoCliente = this.customerTypes.find( tc => tc.id === this.cart.cliente.customertype )
            return tipoCliente ? tipoCliente.name : ''                                              
        },
        formaPago(){
            const fPago = this.paymentMethods.find( fp => fp.id === this.cart.cliente.paymentmethod )
            return fPago ? fPago.name : ''                                    
        },
        datosMetodoPagoForSihot(){
            const idFormaPago =  this.cart.cliente.paymentmethod
            const formaDePago = this.paymentMethods.find( fp => fp.id === idFormaPago )
            return formaDePago ? (formaDePago.interfazsihot || false ) : false            
        },
        subTotal(){
            return ((Math.round( (parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.cart.totalBreakdown.total)) * 100) / 100).toFixed(2) )                
        },
        total(){
            return ( (Math.round( (this.cart.totalBreakdown.total ) * 100) / 100).toFixed(2) )
        },
        totalDivisa(){            
            return ( (Math.round( ( parseFloat(this.cart.totalBreakdown.total) *  parseFloat(this.cart.cliente.currency.value) ) * 100) / 100).toFixed(2) )
           
        },

        
        totalDivisaBooking(){
            return ( (Math.round( ( parseFloat(this.cart.firstPay) *  parseFloat(this.cart.cliente.currency.value) ) * 100) / 100).toFixed(2) )   
        },

        tc(){
        const currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1 
        return ( (Math.round( (currencieValor) * 100) / 100).toFixed(2) )
        },

        tipoCambio(){
            return ( (Math.round( (this.cart.cliente.currency.value) * 100) / 100).toFixed(2) )
        },
        computedForm(){
            return this.$refs.form
        },
        atributosBtnPay(){
            const textBtn = this.isLoadingProcess ? 'Processing checkout' : 'Checkout'
            const disabledBtn = this.isLoadingProcess ? true : false

            return {textBtn, disabledBtn}
        }
    },
    methods: {
        ...mapActions('start',['fetchTypeClients','fetchHotels','fetchLanguages','fetchPaymentMethods']),
        ...mapActions('shop',['checkIfClientExists','sendInfoClient','cancelOrder','fetchAmenitiesByHotel','fetchPaymentStatus']),   
        ...mapMutations('auth',['setUser']),        
        ...mapMutations('shop',['setHotelCliente','setSihotCodeHotel','setEmailCliente', 'setLastNameClient', 'setNameClient', 'setNameAgente','setTipoCliente','setPhoneCliente', 'setPaymentMethodClient', 'setReferenceClient','setLanguageCliente','unsetLastNameSearch','unsetDataRoom','setIdAmenity','setValueisQuotation','setEmptyCart','setDatesOperationInAllProducts']),  
        ...mapMutations('bookings',['resetToInitialState']),     
        ...mapMutations('start',['setPaymentMethods']),  

        calcularImportes(){ 
        const currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1               
        this.divisaTotal = ( (Math.round( ( parseFloat(this.cart.totalBreakdown.total) * parseFloat(currencieValor) ) * 100) / 100).toFixed(2) )             
        this.setCurrency(this.cart.cliente.currency)
        }, 

        async handleHotelClient( hotel ){                                              
            this.setHotelCliente( hotel )

        },        
        async checkIfEmailClientExist( email ){
            //eslint-disable-next-line
            const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if( reg.test(email ) ){                    
                await this.checkIfClientExists(this.cart.cliente)
            }
        },
        async sendDataCliente(){
            //valida que ningún item tenga campos obligatorios sin llenar
            const existsItem = validateDataInCart( this.cart.items ) 
            /*verifica si los items tienen sihotcode, si alguno no tiene,
            habilita booleano de cargo manual al tener room charge como método de pago*/
            checkItemsCartSihotCode( this.cart.items) 
            if( !existsItem ){
                //si en forma de pago interfazsihot es true, se aplica room charge                   
                const showInterfazSihot = this.datosMetodoPagoForSihot 
                if( showInterfazSihot ){                        
                    this.$root.$emit('bv::show::modal', 'modal-room-charge')
                } else {
                    this.setValueisQuotation( false )
                    await this.sendInfoClient( this.cart.cliente )
                    if( this.cart.items ){
                        this.resetCustomerForm()  //reseteo validacion veevalidate         
                    }
                }
            }                     
        },
        
        resetCustomerForm(){            
            this.$refs.formularioCliente.reset()  //reseteo validacion veevalidates
        },
        formatPhoneNumber(event){      
            let keyCode = (event.keyCode ? event.keyCode : event.which)            
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if ( !respuesta ) {                                       
				event.preventDefault()
			}
        },  
        async getSrc(){                                                                     
            await this.fetchPaymentStatus( this.orderCreated )              
            // Approved     statusPayment 
            setTimeout( () => {                
                if( this.statusPayment == 'Approved' ){
                    this.setEmptyCart()
                    this.unsetLastNameSearch()
                    this.unsetDataRoom()
                    this.resetToInitialState()                    
                    this.resetCustomerForm()
                    Vue.swal.fire(
                        'Operation completed',
                        `🎉🎉 The order ${this.orderCreated} has been completed. 🎉🎉`,
                        'success'
                    )
                }
            }, 2000)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';</style>

<style lang="scss" scoped>
iframe {
  overflow: hidden;
}

.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 619px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>