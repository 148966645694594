//App section
export function UPDATE_WINDOW_WIDTH(state, val){ 
    state.windowWidth = val        
}
export function TOGGLE_OVERLAY(state, val){
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
}

//App-config section
export function TOGGLE_RTL(state) {
    state.layout.isRTL = !state.layout.isRTL
    document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
}
export function UPDATE_SKIN(state, skin) {
    state.layout.skin = skin
    // Update value in localStorage
    localStorage.setItem('temaApp', skin)
    // Update DOM for dark-layout
    if (skin === 'dark') document.body.classList.add('dark-layout')
    else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
}
export function UPDATE_ROUTER_TRANSITION(state, val){
    state.layout.routerTransition = val    
}
export function UPDATE_LAYOUT_TYPE(state, val){
    state.layout.type = val    
}
export function UPDATE_CONTENT_WIDTH(state, val){
    state.layout.contentWidth = val    
}
export function UPDATE_NAV_MENU_HIDDEN(state, val){
    state.layout.menu.hidden = val
}
export function UPDATE_NAVBAR_CONFIG(state, obj) {
    Object.assign(state.layout.navbar, obj)    
}
export function UPDATE_FOOTER_CONFIG(state, obj) {
    Object.assign(state.layout.footer, obj)
}

//Vertical section
export function UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
    state.isVerticalMenuCollapsed = val
}

export function setTokenApp(state, token) {
    state.tokenApp = token
}

export function setUserParam(state, userParam) {
    state.userParam = userParam
}