export const appVersion = { version: '2.1.7', date:'2021-08-30' }

export const fields =  [
  { key: 'invoice', label: 'Invoice', class: 'text-center', sortable: true, sortDirection: 'desc' },
  { key: 'customername', label: 'Información' },
  { key: 'producto', label: 'Producto' },
  { key: 'paymentmethod_name', label: 'Método de pago', class: 'text-center' },
  { key: 'paymentreference', label: 'Referencia', class: 'text-center' },
  { key: 'totalSale', label: 'Total venta', sortable: true, class: 'text-center' },
  { key: 'orderstatusname', label: 'Estado', class: 'text-center' },
  { key: 'actions', label: 'Acciones' }
]

export const fieldsOperations =  [
  { key: 'order', label: 'No. Orden', sortable: true, sortDirection: 'desc' },
  { key: 'information', label: 'info' },          
  { key: 'categoryname', label: 'Categoría', sortable: true,},
  { key: 'itemsold', label: 'Producto' },  
  // { key: 'cliente', label: 'Cliente' },          
  // { key: 'paymentForm', label: 'Payment' },
  // { key: 'reference', label: 'Reference' },
  { key: 'qty', label: 'Qty', sortable: true, class: 'text-center' },
  // { key: 'unitPrice', label: 'Precio', sortable: true, class: 'text-center' },
  // { key: 'discount', label: 'Discount', sortable: true, class: 'text-center' },
  { key: 'total', label: 'Total', class: 'text-center' },
  { key: 'orderdetailstatusname', label: 'Estado', class: 'text-center' },
  { key: 'actions', label: 'Acciones' },          
  { key: 'couponDoc', label: 'Cupón' },
]

export const optionsTrip= [                    
  { value: 'Arrival', text: 'Arrival' },          
  { value: 'Departure', text: 'Departure' },          
  { value: 'Inter Hotel', text: 'Inter Hotel' },          
  { value: 'Open Service', text: 'Open Service' },          
]

export const filtroVentasList = [         
  { value: 1, text: 'Fecha de venta' },          
  { value: 2, text: 'Fecha de pago' },                  
]

export const OneWayOption= [          
  { value: 'One-way', text: 'One-way' }          
]

export const typeDiscounts = [          
  { value: 'Sin descuento', text: 'Sin descuento' },          
  { value: 'Descuento', text: 'Descuento' },          
  { value: 'Beneficio', text: 'Beneficio' },          
  { value: 'Cortesía', text: 'Cortesía' },          
  { value: 'Incluido en el paquete', text: 'Incluido en el paquete' },          
]

export const codeHotels = [          
  { idHotel:1, key: 'TFB', code: 1105 },          
  { idHotel:2, key: 'TFO', code: 1100 },          
  { idHotel:3, key: 'TFD', code: null }          
]

export const itemViewOptions = [          
  { icon: 'GridIcon', value: 'grid-view' },
  { icon: 'ListIcon', value: 'list-view' },          
]
export const sortByOptions = [          
  { text: 'Alphabetical order', value: 'alfabetico' },
  { text: 'Ascending Price', value: 'price-asc' },
  { text: 'Price descending', value: 'price-desc' },          
]
export const filterOptions = {
  priceRangeDefined: [
    { text: 'All', value: 'all' },
    { text: '<= $10', value: '<=10' },
    { text: '$10 - $100', value: '10-100' },
    { text: '$100 - $500', value: '100-500' },
    { text: '>= $500', value: '>=500' },
  ]       
}    

export const filters = {
  q: '',
  priceRangeDefined: 'all',
  priceRange: [0, 100],
  categories: [],
  hotel: '',
  category: '',
  service: '',
  ratings: null,               
  currentPage: 1,
  perPage: 16,
}

export const categoryHotels = [    
  { text: 'The Fives Downtown', value: 9 },
  { text: 'The Fives Oceanfront', value: 10 },
  { text: 'The Fives Beach', value: 11 }
]

export const services = [    
  { value: 1, text: 'Eventos', action: 'get-events' },
  { value: 2, text: 'Paquetes', action: 'get-packages'},
] 

export const vendorOptions = [    
  { value: 'CONTACTCENTER', title: 'Contact center' },
]

export const rateOptions = [    
  { value: '', title: 'Todos' },
	{ value: 'ASIRACK', title: 'ASI' }
]
export const languageOptions = [    
  { value: 'es', title: 'Español' },
	{ value: 'en', title: 'Ingles' },
]

export const adultOptions = [
  { value: 1, title:1 },
  { value: 2, title:2 },
  { value: 3, title:3 },
  { value: 4, title:4 },
  { value: 5, title:5 },
  { value: 6, title:6 },
  { value: 7, title:7 },
  { value: 8, title:8 },
]

export const childrenOptions = [
  { value: 0, text: 0 },
  { value: 1, text:1 },
  { value: 2, text:2 },
  { value: 3, text:3 },
  { value: 4, text:4 },
  { value: 5, text:5 },
  { value: 6, text:6 },
  { value: 7, text:7 },
  { value: 8, text:8 },
  { value: 9, text:9 },
]
export const ageChildrenOptions = [
  { value: 1, text:1 },
  { value: 2, text:2 },
  { value: 3, text:3 },
  { value: 4, text:4 },
  { value: 5, text:5 },
  { value: 6, text:6 },
  { value: 7, text:7 },
  { value: 8, text:8 },
  { value: 9, text:9 },
  { value: 10, text:10 },
  { value: 11, text:11 },
  { value: 12, text:12 },
  { value: 13, text:13 },
  { value: 14, text:14 },
  { value: 15, text:15 },
  { value: 16, text:16 },
  { value: 17, text:17 },
]

export const searchBookingData = {
  vendor: '',
  language: null,
  name: '',
  rangeDate: 0,
  dateIn: '',
  dateOut: '',
  nights: 0,
  hotel: '',
  room: null,
  adults: 1,
  numberChildren: 0, 
  ninos:[],  
  promoCode:null,
  promoCodeManual:'',
  userAuthorizeRate: { isInvalid: true, idUser: null, username: '', code:''}
}

export const namesPrefix = [
  { value: 'Mr', text:'Mr' },
  { value: 'Ms', text:'Ms' },
  { value: 'Mrs', text:'Mrs' },
  { value: 'Miss', text:'Miss' }, 
]

export const verticalMenuItems = [      
  {
    title: 'Experiencias',
    route: 'sales',
    icon: 'ShoppingCartIcon',
  },   
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
  }, 
]