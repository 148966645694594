require('./acl')
require('./bootstrap-vue')
require('./clipboard')
require('./feather-icon')
require('./portal-vue')
require('./sweet-alerts')
require('./toastification')
require('./tour')
require('./vue-axios')
require('./vue-json-excel')
require('./vue-select')
require('./vee-validate')

