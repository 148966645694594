<template>
    <b-row>
        <b-col v-if="user.canalventa.includequote">
            <div class="float-right">                
                <b-button                        
                    :disabled="invalid || cart.items == 0 || atributosBtnQuote.disabledBtn"
                    variant="primary"
                    @click="quotation"
                >
                    <b-icon icon="envelope" v-if="!isLoadingProcess"></b-icon>
                    <b-spinner small v-if="isLoadingProcess"/> {{ atributosBtnQuote.textBtn}}  
                </b-button>
            </div>
        </b-col>
        <b-col v-if="user.canalventa.includefinalizesale">
            <div class="float-right">                    
                <div v-if="showBtnRetryCheckout && orderCreated && bankUrl">
                    <b-button                        
                        :disabled="invalid || cart.items == 0"
                        variant="danger"
                        class="mr-1"
                        @click="cancelarOrden"
                    >
                        <b-icon icon="x-circle-fill"></b-icon> Cancel
                    </b-button>
                    <b-button                        
                        :disabled="invalid || cart.items == 0 || atributosBtnPay.disabledBtn"
                        variant="primary"
                        type="submit"
                    >
                        <b-icon icon="cash" v-if="!isLoadingProcess"></b-icon>
                        <b-spinner small v-if="isLoadingProcess"/> Retry payment
                    </b-button>
                </div>
                <div v-if="showBtnCheckout">
                    <b-button                        
                        :disabled="invalid || cart.items == 0 || atributosBtnPay.disabledBtn"
                        variant="primary"
                        type="submit"
                    >
                        <b-icon icon="cash" v-if="!isLoadingProcess"></b-icon>
                        <b-spinner small v-if="isLoadingProcess"/> {{atributosBtnPay.textBtn}}  
                    </b-button>                                
                </div>
            </div>
        </b-col>        
    </b-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    name: 'ButtonsPay',
    props:{
        invalid: {
            type: Boolean,
            required: true
        }
    },
    computed:{
        ...mapState('shop',['cart','orderCreated','isLoadingProcess','bankUrl','showBtnCheckout','showBtnRetryCheckout']),
         ...mapState('auth',['user']),
        atributosBtnPay(){
            const textBtn = this.isLoadingProcess ? 'Processing checkout' : 'Checkout'
            const disabledBtn = this.isLoadingProcess ? true : false

            return { textBtn, disabledBtn }
        },
        atributosBtnQuote(){
            const textBtn = this.isLoadingProcess ? 'Processing quotation' : 'Send my quote'
            const disabledBtn = this.isLoadingProcess ? true : false

            return { textBtn, disabledBtn }
        }
    },
    methods:{
        ...mapActions('shop',['cancelOrder','sendInfoClient']),   
        ...mapMutations('shop',['setValueisQuotation','setPaymentMethodClient']),
        cancelarOrden(){
            this.cancelOrder( this.orderCreated )
            this.$emit('reset-customer-form')        
        },
        async quotation(){            
            await this.setValueisQuotation( true )
            await this.setPaymentMethodClient( 28 )            
            await this.sendInfoClient( this.cart.cliente )
            this.$emit('reset-customer-form')        
        }
    }
}
</script>