export function setUser(state, user){ //se ejecuta una vez el usaurio haga login
    state.user = user
    state.isLogged = true       
    state.error = false
    state.errorMessage = ''    
}

export function setPhrase(state, phrase){ //se ejecuta una vez el usaurio haga login
    state.quote = phrase   
}

export function logout(state){
    state.user = null
    state.quote = null    
    state.isLogged = false     
}

export function authError(state, error){
    state.error = true
    state.errorMessage = error
    state.user = null
    state.isLogged = false  
}

export function unsetError(state){
    state.error = false
    state.errorMessage = ''
    state.sessionExpired = false
}
export function setLoadingAuth(state, bool) {
    state.isLoadingAuth = bool
}

export function setQueryParams( state, queryParams) {
    const { token, ...rest } = queryParams
    state.token = token
    state.queryParams = rest
}

export function removeQueryParams( state ) {
    state.token = null
    state.queryParams = null
}