<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
      <h2 class="brand-text text-primary ml-1">
          Salescore
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div>
            <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
            type="grow"
        />
        <h2 class="mb-1">
          Ingresando 🔐
        </h2>
        </div>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios' 
export const baseUrl = process.env.VUE_APP_BASE_URL_API
const baseUrlImg = process.env.VUE_APP_IMG_SRC_API

import Logo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  components: {
    Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      myToken: this.token,
    }    
  },
  created(){
    this.setQueryParams( this.$route.query )       
  },
  async mounted(){    
    this.myToken = this.token    
    if( this.myToken ){
      axios.defaults.headers.common['Authorization'] = 'Token '+ this.token
      axios.defaults.baseURL = baseUrl
      Vue.use(VueAxios, axios, baseUrlImg)
      await this.logInWidgetApp()
      await this.getDescriptionsQuotations()
    }

  },
  computed: {    
    ...mapState('auth',['user','isLogged', 'token']),    
    imgUrl() {      
      if (store.state.uiAppConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods:{
      ...mapActions('auth',['signInWidgetApp']),
      ...mapActions('shop',['fetchDescripcionCotizacion']),      
      ...mapMutations('auth',['setQueryParams']),
      async logInWidgetApp(){
        const user = {}
        await this.signInWidgetApp(user)
        if( this.isLogged ){
          this.$router.push({ name: 'sales' })
        }        
      },
      //
      async getDescriptionsQuotations(){
        await this.fetchDescripcionCotizacion( { typeTitle: 1, idLanguage: 1, type: 'bienvenida' } )
        await this.fetchDescripcionCotizacion( { typeTitle: 2, idLanguage: 1, type: 'despedida' } )
      }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
