export default [{
        path: '/login',
        name: 'auth-login',
        component: () =>
            import ('@/views/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/sales',
        name: 'start',
        component: () =>
            import ('@/views/Start.vue'),
        meta: {            
            layout: 'full',            
        },
    },    
    {
        path: '/sales/products',
        name: 'sales',
        component: () =>
            import ('@/views/shop/Sales.vue'),
        meta: {
            
            requiresAuth: true,
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
            
        },
    },
    {
        path: '/sales/booking',
        name: 'booking',
        component: () =>
            import ('@/views/shop/Booking.vue'),
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/sales/products/:slug',
        name: 'sales-product-details',
        component: () =>
            import ('@/views/shop/ProductDetail.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/sales/order',
        name: 'checkout',
        component: () =>
            import ('@/views/shop/Checkout.vue'),
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/sales/payment',
        name: 'payment',
        component: () => import ('@/views/shop/Payment.vue'),
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
        },
    },    
    {
        path: '/sales/bankresponse',
        name: 'bank-response',
        component: () =>
            import ('@/views/shop/BankResponse.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/paymentlanding',
        name: 'payment-response',
        component: () =>
            import ('@/views/shop/paymentLanding.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/itinerario',
        name: 'itinerario',
        component: () =>
            import ('@/views/shop/Itinerario.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/termsConditions',
        name: 'termsConditions',
        component: () =>
            import ('@/views/shop/TermsConditions.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () =>
            import ('@/views/faq/Faq.vue'),
    },    
    {
        path: '/payment/response',
        name: 'paymentresponse',
        component: () =>
            import ('@/views/payment/Response.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () =>
            import ('@/views/NotAuthorized.vue'),
        meta: {            
            layout: 'full',            
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import ('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
]